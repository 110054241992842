/**
 * @file general-metadata-ervice.js
 * @copyright (c) 2016 4D vision
 * @author Thomas Geboers
 * @license Proprietary
 */

generalMetadataService.$inject = ['$resource', 'appConfiguration',
'$location', '$q', 'log4d'];

/**
 * An Angular service with which general metadata can be obtained from the server,
 * i.e. metadata for the entire application.
 * @namespace
 */
function generalMetadataService($resource, appConfiguration,
$location, $q, log4d) {

    var logger = log4d.logger('generalMetadataService');

    var service = {
        get: get
    };
    return service;

    // implementation details
    function resource() {
        return $resource(appConfiguration.apiUrl +'general/metadata');
    }

    /**
     * Gets the general metadata from the server.
     * @returns {Promise} - The resulting object contains two properties:
     * * MaxResultSize: the maximum number of resulting records 
     *   returned by the API in response to a GET request
     * * ExportFileTypes: an array containing the supported file types 
     *   for export (CSV, JSON, XLS..)
     */
    function get() { //GET
        return resource().get().$promise;
    }
}

(module || {}).exports = generalMetadataService;