'use strict';

var mod = angular.module('ivd.ui.validation', [
   
    ]); 

// client-side validation
mod.directive('ivdValidate', require('./validate-directive.js'));
mod.directive('ivdName', require('./name-directive.js'));
mod.directive('ivdValidateAsDecimal', require('./validate-as-decimal-directive.js'));
mod.directive('ivdValidateAsInteger', require('./validate-as-integer-directive.js'));
mod.directive('ivdValidateAsDate', require('./validate-as-date-directive.js'))
mod.directive('ivdValidateAsFile', require('./validate-as-file-directive.js'))
mod.directive('ivdValidateAsFile', require('./validate-multi-lingual-text-directive.js'))

mod.factory('ivdDateTimeValidator', require('./date-time-validator-factory.js'));
 
(module || {}).exports = 'ivd.ui.validation';