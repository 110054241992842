ivdFooter.$inject = ['$window'];

function ivdFooter($window) {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/footer/footer.html',
        scope: {
            isVisible: '=',
            showSearchBox: '<',
            showSortBox: '<',
            showAddButton: '<',
            showExportButton: '<',
            exportData: '&',
            toggleSearchBox: '&',
            toggleSortBox: '&',
            openCreate: '&'
        },
        controllerAs: 'footer',
        controller: FooterController,
        bindToController: true
    };
    return directive;
}

function FooterController() {
    var vm = this;

}

(module || {}).exports = ivdFooter;
