/**
 * @file rest-query-builder.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

var RestQuery = require('./rest-query.js');

restQueryBuilder.$inject = ['log4d'];

/**
 * An Angular service for creating a RestQuery object.
 * 
 * @namespace
 */
function restQueryBuilder(log4d) {

    // hidden variables:
    var logger = log4d.logger('restQueryBuilder');
    var singletons = new ivd.CircularArray(10, logger);

    // public API:
    var service = {
        getScoped : getScoped,
        getSingleton: getSingleton 
    };
    return service;

    /**
     * Gets a new instance of a RestQuery object:
     * its usage is scoped, meaning you need to hold on
     * to a reference to this object.
     * @returns {RestQuery} 
     */
    function getScoped() {
        return new RestQuery(logger);
    }

    /**
     * Gets an instance of RestQuery corresponding to the
     * given endpoint.
     * 
     * Each time this function is called with a new endpoint/name,
     * a singleton is created and added to an internal circular array (of length 10).
     * 
     * Each time this function is called with an endpoint/name that
     * is contained in the internal circular array, that instance in the 
     * array is returned (singleton).
     * 
     * @param {string} name - the endpoint, or a custom name
     * @returns {RestQuery} 
     */
    function getSingleton(name) {
        if (!_.some(singletons, { name: name })) {
            singletons.push({
                name: name,
                query: new RestQuery(logger)
            });
        }
        return _.head(_.filter(singletons, { name: name })).query;
    }
}

(module || {}).exports = restQueryBuilder;
