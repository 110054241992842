passwordRecoveryController.$inject = ['$scope', '$location', 'authenticationService'];

function passwordRecoveryController($scope, $location, authenticationService) {
    var vm = this;

    vm.submit = submit;

    var zxcvbn = require('zxcvbn');

    init();


    function init() {
        vm.submitting = false;
        vm.submitted = false;

        vm.activationCode = "";
        vm.newpassword = "";
        vm.newpassword2 = "";
        vm.alert = "";
        vm.alertType = "danger";
        vm.passwordScoreStyle = 'danger';
        vm.passwordFeedback = {};

        var queryParams = $location.search()
        vm.activationCode = queryParams.ActivationCode;

        if (!vm.submitted && !vm.activationCode) {
            vm.alert = "PASSWORD_RECOVERY_NO_ACTIVATION_CODE";
            vm.alertType = "danger";
            // TODO Handle this better. Redirect to another page? Hide form fields?
        }
    }

    function submit() {
        vm.submitting = true;
        vm.alert = "";

        if (!vm.newpassword || !vm.newpassword2) {
            vm.alert = "PASSWORD_RECOVERY_BOTH_FIELDS";
            vm.alertType = "danger";
            vm.submitting = false;
            return;
        }

        if (vm.newpassword !== vm.newpassword2) {
            vm.alert = "PASSWORD_RECOVERY_MISMATCH";
            vm.alertType = "danger";
            vm.submitting = false;
            return;
        }

        authenticationService.changePassword(vm.newpassword, vm.activationCode)
            .then(function () {
                vm.alert = "PASSWORD_RECOVERY_SUCCES";
                vm.alertType = "success";
                vm.submitting = false;
                vm.submitted = true;
            })
            .catch(function (err) {
                vm.alert = "PASSWORD_RECOVERY_ERROR"
                vm.alertType = "danger";
                vm.submitting = false;
                vm.submitted = false; // Reset form so the user can try again
            });
    }

    $scope.$watch(function () {
        return vm.newpassword;
    }, function (newValue) {
        if (!newValue) {
            vm.passwordFeedback = null;
            vm.passwordScoreStyle = 'danger';

            return;
        }

        var result = zxcvbn(newValue);
        var score = result.score;
        vm.passwordFeedback = result;
        vm.passwordScoreStyle = score >= 3 ? 'success'
            : score >= 2 ? 'warning'
            : 'danger';
        vm.passwordFormGroupStyle = vm.passwordScoreStyle === 'danger' ? 'error'
            : vm.passwordScoreStyle;
    });

}

(module || {}).exports = passwordRecoveryController;

