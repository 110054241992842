ivdCurrencyField.$inject = [];

function ivdCurrencyField() {
    /*
       Usage:
       <ivd-currency-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
   */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/currency-field/currency-field.html',
        controller: CurrencyFieldController,
        controllerAs: 'field',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

CurrencyFieldController.$inject = ['$parse', 'appConfiguration'];

function CurrencyFieldController($parse, appConfiguration) {
    var vm = this;

    //Bindable members
    vm.placeholder = (!!appConfiguration.fields_show_placeholder) ? vm.metadata.label : "";

    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    
}

(module || {}).exports = ivdCurrencyField; 