ivdMasterSettings.$inject = [];

function ivdMasterSettings() {
    var directive = {
        restrict: 'E',
        scope: {
            toggleSearchBox : '&',
            toggleSortBox: '&',
            openCreate: '&',
            exportData: '&'
        },
        require: '?^ivdMasterPage',
        // '?' means it is optional, meaning null will be passed to the link function
        // see https://docs.angularjs.org/api/ng/service/$compile#-require-
        link: link
    };
    return directive;
}

function link(scope, element, attrs, controller) {
    if (controller) {
        if (scope.toggleSearchBox !== undefined) {
            controller.showSearchBox = true;
            controller.toggleSearchBox = function () {
                if (scope.toggleSearchBox) scope.toggleSearchBox();
            };
        }
        if (scope.toggleSortBox !== undefined) {
            controller.showSortBox = true;
            controller.toggleSortBox = function () {
                if (scope.toggleSortBox) scope.toggleSortBox();
            };
        }
        if (scope.openCreate !== undefined) {
            controller.showAddButton = true;
            controller.openCreate = function () {
                if (scope.openCreate) scope.openCreate();
            };
        }
        if (scope.exportData !== undefined) {
            controller.showExportButton = true;
            controller.exportData = function (filetype) {
                if (scope.exportData) scope.exportData(filetype);
            }
        }
    }
}

(module || {}).exports = ivdMasterSettings;