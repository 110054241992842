ivdSelectBoxField.$inject = [];

function ivdSelectBoxField() {
    // Usage:
    //      <ivd-selext-box-field></ivd-select-box-field>
    // Attributes:
    //      data                - Rest API endpoint to call (endpoint should be encapsulated in extra single quotes)
    //                            This is the data that needs to be manipulated. The detailview-modal will pick up the changes and update the record accordingly.
    //                            Usually it is sufficient to change the ID field in order for the detailview-modal to update accordingly.
    //                            The metadata usually contains the parameters to indicate which of the fields in the data variable to use. (depends on implementation of backend)
    //                            Example:  Active: true
    //                                      FirstName: "Bill"
    //                                      FullName: "Bill Compton"
    //                                      Id: 806
    //                                      Name: "Compton"
    //      metadata            - Parameter for the Rest API call (parameter should be encapsulated in extra single quotes)
    //                            Metadata is REST-API specific: in case of TimeBox this metadata needs to have identifications for what the id field is of the data object,
    //                            and what the description (text) field is of the data object.
    //                            Example:  data: "Employee"
    //                                      endpoint: "Employees"
    //                                      id: "Id"
    //                                      label: "Employee"
    //                                      order: 2
    //                                      required: true
    //                                      text: "FullName"
    //                                      type: "selectbox"
    //      service             - Refers to the service that will extract the needed fields from the data object(s). This service needs to be injected.
    //      edit                - indication whether the field is in edit mode or not (not: static text appears; edit-mode: selectbox appears)
    //      staticData          - List of data from ex. metadata
    //                              If this is undefined the selectbox will depend on metadata.endpoint & metadata.data/text for REST calls
    //                              If this parameter is set, it should be an array containing JavaScript object with properties 'id' and 'text'
    //      selection           - multiple => multiselect selectbox
    //                            single/other => single select selectbox
    //      theme               - Selectbox theme: 'select2' or 'bootstrap' currently available
    //      notify              - The controller will notify whenever the selection changes. Parent scopes can bind to this to get this notification.
    //      context             - The context for which this select-box-field changes information. The context will be passed in a custom header when retrieving the list of possible values
    //                            For example: context will be: { "id": 1, "name": "X", "enterpriseId": 0 } where this field edits the enterpriseId
    // Creates:
    //
    var directive = {
        restrict: 'E',
        scope: {
            data: '=', // This is an object of an entity (e.g. an Employee object)
            idData: '=?', // This the Id location of the object that has to be set when a (single) selection is performed
            staticData: '=',
            metadata: '=',
            service: '=',
            create: '=?',
            edit: '=',
            notify: '&',
            selection: '@',
            theme: '@',
            linkProperty: '=',
            linkValue: '=',
            getDetailsOnSelect: '@',
            context: '=',
            beforeNavigation: '&',
            additionalValidationPadding: '=',
            tabIndex: '=?',
            form: '=?',
            name: '=?',
            processing: '=?'
        },

        controller: SelectBoxFieldController,
        controllerAs: 'selectBox',
        templateUrl: 'ivd/ui/fields/select-box-field/select-box-field.html',
        bindToController: true,

        //link: function(scope, element, attrs, ngModel) {
        //    scope.vm.ngModelController = ngModel || {}; // https://docs.angularjs.org/api/ng/type/ngModel.NgModelController
        //},

        compile: function (element, attrs) {
            // Attribute defaults
            if (!attrs.data) { attrs.data = []; }
            if (!attrs.staticData) { attrs.staticData = []; }
            if (!attrs.metadata) { attrs.metadata = []; }
            if (!attrs.service) { attrs.service = ''; }

            if (!attrs.create) { attrs.create = false; }
            if (!attrs.edit) { attrs.edit = false; }
            if (!attrs.theme) { attrs.theme = 'bootstrap'; }

            if (attrs.theme) {
                $(angular.element(element)).find('ui-select').attr('theme', attrs.theme);
            }
        }
    };
    return directive;
};

SelectBoxFieldController.$inject = [
    'restQueryBuilder', 'timeboxSelectBoxService', 'appConfiguration',
    '$scope', 'log4d', '$parse', '$translate', '$location', '$window']

function SelectBoxFieldController(
    restQueryBuilder, selectBoxService, appConfiguration,
    $scope, log4d, $parse, $translate, $location, $window) {
    var vm = this;
    var logger = log4d.logger('selectBox');


    vm.selection = ((vm.selection === 'multiple' || vm.selection === true) ? 'multiple' : 'single'); // Default to single
    vm.refreshData = refreshData;

    vm.tabIndex = vm.tabIndex || 0;
    //Determine REST service
    var svc;
    if (!!vm.service) {
        switch (vm.service) {
            default:
                svc = selectBoxService;
        }
    }
    else {
        svc = selectBoxService;
    }

    //Bindable members
    vm.get = get;
    vm.create = !!vm.create;
    vm.edit = !!vm.edit;
    vm.editable = editable;

    vm.showHyperlink = vm.metadata.showHyperlink;
    if (vm.showHyperlink === null)
        vm.showHyperlink = appConfiguration.selectbox.hyperlink; // Fall back to global default
    if (vm.getDetailsOnSelect === 'undefined')
        vm.getDetailsOnSelect = true;

    vm.internalData = vm.staticData || [];
    //vm.selectedData = []; // do not initialize, because validation will fail otherwise
    vm.allowClear = false;

    vm.placeholder = (appConfiguration.selectbox.helpText) ? 'SELECT_BOX.HELP_TEXT' :
        (appConfiguration.fields_show_placeholder ? vm.metadata.label : '');

    vm.notifyAll = notifyAll;

    //Initialisation
    activate();

    function activate() {
        logger.debug('selectbox activated');

        vm.processing = true;

        //Bind service dependent metadata
        vm.readOnly = svc.parameters.getReadOnly(vm.metadata) || false;
        vm.endpoint = svc.parameters.getEndpoint(vm.metadata);
        vm.descriptionField = svc.parameters.getFieldDescription(vm.metadata);
        vm.idField = svc.parameters.getFieldId(vm.metadata);

        //Set "vm.data" defaults
        if (vm.selection === 'multiple') {
            vm.data = vm.data || [];
            if (vm.data.constructor !== Array)
                vm.data = [];
        }

        enableAddButtonIfNeeded();

        if (vm.idData && !vm.data) {
            // Download data of selected ID
            refreshData('');
        }

        //Set initial data (from searchbox storage for example) (> inserted via vm.data)
        setInitialValue();

    }

    function notifyAll() {
        if (vm.metadata.selectBoxChanged) {
            vm.metadata.selectBoxChanged(vm);
        }
        vm.notify();
    }

    function enableAddButtonIfNeeded() {
        if (vm.editable() && vm.metadata.addable) {
            vm.additionalValidationPadding = 10 + 15.7 + 10;
            vm.addTooltip = $translate.instant('ADD');
        }
    }

    vm.add = function (target) {
        if (vm.beforeNavigation)
            vm.beforeNavigation();
        var path = vm.metadata.selectbox_endpoint + '/new';
        if (target) {
            $window.open($location.$$absUrl.replace($location.$$path, path), target);
        } else {
            $location.url(path);
        }
    };

    var first$SelectSearchPassed = false;
    var initalDataLoaded = false;

    //Implementation
    //-- Refresh data when typing
    function refreshData(query, forceRefresh, callFromHtml) {

        // After initialization, there is a watch in select.js on $select.search that will cause
        // call this function with query = '', because at first $select.search will be empty.
        // Refreshing the data for an empty query will only fetch the first 100 records.
        // If vm.selectedData is prefilled with a value that is not present in these first 100 records,
        // vm.selectedData will be set to undefined again by the internalDataUnbinder.
        // So, we need to ignore the first call from select.js, if query = ''.
        if (!first$SelectSearchPassed && callFromHtml) {
            first$SelectSearchPassed = true;
            if (query === '') {
                return;
            }
        }

        if (vm.create || vm.edit) {
            if (!!vm.staticData) {
                vm.internalData = vm.staticData; // Ex.: attrs -> vm.metadata.datasource[#]
            }
            else if (vm.endpoint) {
                vm.lastQuery = vm.lastQuery || '';
                //if (!forceRefresh && vm.internalData.length < svc.maxQuerySize() &&
                //    query.indexOf(vm.lastQuery) > -1 && vm.lastQuery != '') { // TODO: 100 => from config?
                //}
                if (!forceRefresh && vm.lastQuery === query && query !== '') {
                    return;
                }
                else {
                    vm.lastQuery = query;
                    var restQuery = restQueryBuilder.getScoped();
                    restQuery.addFilter(vm.descriptionField, query);
                    restQuery.addOrderby(vm.descriptionField);

                    if (vm.metadata.selectbox_text === 'Description') {
                        restQuery.addExpand('description');
                    }
                    if (vm.metadata.selectbox_text === 'DescriptiveFields') {
                        restQuery.addExpand('DescriptiveFields');
                    }

                    if (vm.linkProperty) {
                        restQuery.addFilter(vm.linkProperty, (vm.linkValue == undefined) ? '' : vm.linkValue);
                    }
                    if (vm.metadata.defaultQuery) {
                        for (var q in vm.metadata.defaultQuery) {
                            restQuery.addFilter(q, vm.metadata.defaultQuery[q]);
                        }
                    }
                    var serviceCall = svc.query(vm.endpoint, restQuery.getQueryParameters(), vm.context);
                    serviceCall.then(function (response) {
                        vm.internalData = _.map(response, function (entry, key) {
                            var res = {
                                id: entry.Id || entry.ID,
                                Id: entry.Id || entry.ID,
                                text: entry[vm.descriptionField],
                            };
                            return res;
                        });
                        if (!initalDataLoaded && vm.internalData.length === 1) {
                            setSelectedData(vm.internalData[0].id);
                            vm.data = vm.selectedData;
                        }
                        initalDataLoaded = true;
                    });
                }
            }
        }
    };

    //-- Fires when new value is selected
    vm.onSelect = function ($item, $model) {

        vm.processing = true;

        if (vm.selection === 'multiple') { //MULTISELECT
            vm.data = vm.data || [];
            vm.data.push({ id: $item.id, Id: $item.id, text: $item.text });

            validateSelectedAmount();
        }
        else { //SINGLESELECT
            // DetailView data adjustment (object expected)
            if (angular.isUndefined($item) || $item === null) {
                vm.data = {}; // or undefined?
                vm.selectedData = undefined;
                vm.idData = null;
            }
            else if (vm.getDetailsOnSelect) {
                setSelectedData($item.id);
            }
            // SearchBox data adjustment (value [id] expected)
            else {
                vm.selectedData = { id: $item.id, Id: $item.id, text: $item.text };
                vm.data = vm.selectedData;
                vm.idData = vm.data.id;
            }
        }
        // Notify parent scope
        vm.notifyAll();
    };

    function setSelectedData(id) {
        vm.data = vm.data || {};
        // Pass data to data entity for update/insert purposes (id is sufficient [in timebox!])
        svc.get(vm.endpoint, id).then(function (response) {
            var newData = {};
            angular.forEach(response, function (value, index) {
                newData[index] = value;
            });
            vm.data = newData;
            vm.selectedData = {
                id: vm.data[vm.idField],
                Id: vm.data[vm.idField],
                text: vm.data[vm.descriptionField]
            };
        });
    }

    //-- Fires when value is removed
    vm.onRemove = function ($item, $model) {

        vm.processing = true;

        if (vm.selection === 'multiple') { //MULTISELECT
            vm.data.forEach(function (value, index) {
                if (value.id === $item.id) {
                    vm.data.splice(index, 1);
                    return;
                }
            });
            validateSelectedAmount();
        }
        else { //SINGLESELECT
            vm.data = [];
            vm.selectedData = undefined;
        }

        // Notify parent scope
        vm.notifyAll();
    };

    //-- Initialize selection list when edit mode is enabled
    $scope.$watch('selectBox.edit', function (newValue, oldValue) {
        if (newValue && oldValue !== newValue) {
            setInitialValue();
            vm.refreshData('');
        }
    });

    //-- Notify parent scope whenever data object is changed
    //Needed because:
    // 1. In single selection mode, the data isn't passed correctly after change in the onSelect handler.
    // 2. When Searchbox is cleared, vm.data becomes undefined, and the internal data needs to update accordingly.
    $scope.$watch('selectBox.data', function (newValue, oldValue) {
        logger.debug('SELECT-BOX data changed', newValue, oldValue);
        if (newValue
            && newValue !== oldValue
            &&
            ((vm.selection === 'single' && (!oldValue || newValue.id !== oldValue.id || newValue.text !== oldValue.text))
                ||
                (vm.selection === 'multiple' && !checkArraysSame(newValue, oldValue))
            )) {
            setInitialValue();
            vm.refreshData(newValue.text);
            vm.notifyAll();
        }
        if (newValue==null) {
            vm.selectedData = undefined;
        }
        if (angular.isUndefined(newValue)) {
            vm.selectedData = undefined;
        }
    });

    function checkArraysSame(arrA, arrB) {

        //check if lengths are different
        if (arrA.length !== arrB.length) return false;


        for (var i = 0; i < arrA.length; i++) {
            if (arrA[i].Id !== arrB[i].Id) return false;
        }

        return true;

    }

    //-- Testwatch allowClear
    $scope.$watch('selectBox.metadata', function (newValue, oldValue) {
        if (newValue) {
            vm.allowClear = !newValue.required && !newValue.readOnly;
        }
    });

    $scope.$watch('selectBox.linkValue', function (newValue, oldValue) {
        if (newValue && oldValue !== newValue) {
            vm.refreshData('', true); // If updated, the internal data watcher will check if the current item is still present in the data
        }
    });

    //-- Watch internalData to generate showData
    var internalDataUnbinder = $scope.$watchCollection('selectBox.internalData', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (vm.selection === 'multiple') {
                vm.showData = newValue.filter(function (e) {
                    return !vm.data.some(function (d) {
                        return (d.id || d.Id) === e.id;
                    });
                });
            } else {
                if (vm.idData && !vm.data) {
                    // Data is set, but only id
                    vm.data = newValue.filter(function (e) {
                        return vm.idData === e.id;
                    });

                    if (vm.data.length > 0)
                        vm.data = vm.data[0];
                    else
                        vm.data = null;

                    if (typeof initializeSearch !== 'undefined')
                        initializeSearch();
                }
            }
        }

        vm.processing = false;
    });

    //-- Set initial value
    function setInitialValue() {
        if (vm.selection === 'multiple') {
            if (vm.data) {
                var selection = [];
                $.each(vm.data, function (index, data) {
                    var initialObj = {};
                    initialObj['id'] = data.id || data.Id || vm.get(data, vm.idField || 'Id');
                    initialObj['Id'] = initialObj['id'];
                    initialObj['text'] = data.text || data.Text || vm.get(data, vm.descriptionField || 'DescriptiveFields');
                    selection.push(initialObj);

                    if (!data.id)
                        vm.data[index] = initialObj;
                });
                vm.selectedData = selection;
            }
        }
        else {
            if (vm.data) {
                var initialObj = {};
                initialObj['id'] = vm.data.id || vm.data.Id || vm.get(vm.data, vm.idField || 'Id');
                initialObj['Id'] = initialObj['id'];
                initialObj['text'] = vm.data.text || vm.data.Text || vm.get(vm.data, vm.descriptionField || 'DescriptiveFields');
                if (initialObj.id || initialObj.text) {
                    vm.selectedData = initialObj;
                }
                if (!vm.data.id)
                    vm.data = initialObj;
            } else {
                vm.selectedData = undefined;
                refreshData('');
            }
        }
        enableAddButtonIfNeeded();
    }

    //Helper functions
    //-- Object access
    function get(obj, str) {
        if (angular.isUndefined(obj)) {
            return undefined;
        } else {
            return $parse(str)(obj);
        }
    }

    function editable() {
        return (vm.create || vm.edit) && !vm.metadata.readOnly;
    }

    function validateSelectedAmount() {
        // Do validation, only when part of a form
        if (vm.form && vm.name) {
            if (vm.metadata.requiredAmount
                && vm.metadata.requiredAmount > 0
                && vm.selectedData.length < vm.metadata.requiredAmount) {
                // Error, not enough items selected
                (vm.form[vm.name].$error = vm.form[vm.name].$error || {}).requiredAmount = true;
                vm.form[vm.name].$dirty = true;
                vm.form[vm.name].$invalid = true;
                vm.form[vm.name].$valid = false;
            } else {
                if (vm.form[vm.name].$error)
                    delete vm.form[vm.name].$error.requiredAmount;
                vm.form[vm.name].$valid = true;
                vm.form[vm.name].$invalid = false;
            }
        }
    }
}

(module || {}).exports = ivdSelectBoxField;
