ivdExportButton.$inject = [];

function ivdExportButton() {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/export-button/export-button.html',
        controller: ExportButtonController,
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            exporter: '&',
            shorten: '='
        },
        replace: true
    };
    return directive;
}

ExportButtonController.$inject = ['$scope', 'generalMetadataService'];

function ExportButtonController($scope, generalMetadataService) {

    var vm = this;
    generalMetadataService.get().then(function(data){
        vm.filetypes = data.ExportFileTypes.sort();
    });
}

(module || {}).exports = ivdExportButton