/**
 * @file jwttoken-local-read-service.js
 * @copyright (c) 2016 4D vision
 * @author Sofie Beke
 * @license Proprietary
 */

jwtTokenLocalReadService.$inject = ['$sessionStorage'];

/**
 * An Angular service that facilitates reading properties from the 
 * locally stored JWT a.k.a. Bearer Token.
 * @namespace
 */
function jwtTokenLocalReadService($sessionStorage) {
  var service = {
    getUserLanguage: getUserLanguage,
    getUserId: getUserId,
    getOrgId: getOrgId,
    isPrimaryLogin: isPrimaryLogin
  };
  return service;

  /**
   * Returns the user's language.
   * @returns {string} - ISO code of the user's language
   */
  function getUserLanguage() {

    if (!$sessionStorage.authorizationData) return;
    if (!$sessionStorage.authorizationData.user) return;
   
    var userinfo = $sessionStorage.authorizationData.user;
    var userlang = userinfo.languageIsoCode;

    return userlang;
  }

  /**
   * Returns the user's ID.
   * @returns {string} - userId (number as a string)
   */
  function getUserId() {

    if (!$sessionStorage.authorizationData) return;
    if (!$sessionStorage.authorizationData.user) return;
   
    var userinfo = $sessionStorage.authorizationData.user;
    var userId = userinfo.userId;

    return userId;
  }
  
  // TODO move out, this is a BESC feature
  /**
   * Returns the user's organisation ID.
   * @returns {string} - orgId (number as a string)
   */
  function getOrgId() {
    
    if (!$sessionStorage.authorizationData) return;
    if (!$sessionStorage.authorizationData.user) return;
   
    var userinfo = $sessionStorage.authorizationData.user;
    var orgId = userinfo.organisationId;

    return orgId;
  }
  
  // TODO move out, this is a BESC feature
  /**
   * Returns a boolean stating whether this is a primary login.
   * @returns {Boolean} - is primary login
   */
  function isPrimaryLogin() {

      if (!$sessionStorage.authorizationData) return;
      if (!$sessionStorage.authorizationData.user) return;

      var userinfo = $sessionStorage.authorizationData.user;
      var isPrimary = userinfo.isPrimary;

      return isPrimary;
  }

};

(module || {}).exports = jwtTokenLocalReadService;