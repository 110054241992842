globalExceptionHandler.$inject = ['$injector', 'log4d', 'appConfiguration'];

function globalExceptionHandler($injector, log4d, appConfiguration) {
    var logger = log4d.logger('global');

    return function handler(exception, cause) {
        logger.error(exception, cause);
    };
}

(module || {}).exports = globalExceptionHandler;