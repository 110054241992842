ivdValidateAsFile.$inject = [];

function ivdValidateAsFile() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, el, attr, ngModel) {
            var path2metadata = el.attr('ivd-validate-as-file');
            if (path2metadata == undefined) return;
            var metadata = scope;
            _.forEach(path2metadata.split('.'), function (prop) {
                if (metadata != undefined) metadata = metadata[prop];
            })

            var required = !!metadata.required;

            ngModel.$validators.file = function (modelValue, viewValue) {
                var value = modelValue || viewValue;
                if (required)
                    return (value !== undefined && value.trim() !== "");
                return true;
            };

            function setDirty(value, old) {
                if (value != old) {
                    ngModel.$setDirty();
                }
            }

            scope.$watch(function () {
                return ngModel.$viewValue;
            }, setDirty);
        }
    }
}

(module || {}).exports = ivdValidateAsFile;