

function appLocalization() {
    var provider = {
        $get: $get,
        defaultLocale: defaultLocale
    };

    var _defaultLocale = undefined;

    function defaultLocale(isoCode) {
        if (isoCode != undefined) {
            _defaultLocale = isoCode;
            return this; // for fluent configuration
        } else {
            return _defaultLocale;
        }
    }

    $get.$inject =['$translate', 'tmhDynamicLocale'];

    function $get($translate, tmhDynamicLocale) {
        return appLocalizationService($translate, tmhDynamicLocale, _defaultLocale);
    }

    return provider;
}

function appLocalizationService($translate, tmhDynamicLocale, default_locale) {

    var _currentLocale = default_locale;
    var _defaultLocale = default_locale;

    var svc = {
        currentLocale: currentLocale,
        defaultLocale: defaultLocale,
        resetLocale: resetLocale
    }
    return svc;

    function currentLocale(isoCode) {
        if (isoCode != undefined) {
            $translate.use(isoCode || $translate.preferredLanguage());
            tmhDynamicLocale.set(isoCode.toLowerCase().replace(/_/g, '-')); // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
            _currentLocale = isoCode;
        } else {
            return _currentLocale || _defaultLocale;
        }
    }

    function defaultLocale() {
        return _defaultLocale;
    }

    function resetLocale() {
        $translate.use($translate.preferredLanguage());
        tmhDynamicLocale.set(_defaultLocale.toLowerCase().replace(/_/g, '-')); // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
        _currentLocale = _defaultLocale;
    }
}

(module || {}).exports = appLocalization;