ivdFileUploadBoxField.$inject = [];

/**
 * Usage:
 * <ivd-file-upload-box-field
 *      data="data"
 *      metadata="metadata"
 *      edit="edit"
 *      processing=""> [NYI]
 * </ivd-file-upload-box-field>
 */
function ivdFileUploadBoxField() {
  
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/file-upload-box-field/file-upload-box-field.html',
        controller: FileUploadBoxFieldController,
        controllerAs: 'vm',
        scope: {
            data: '=',
            metadata: '=',
            edit: '=',
            processing: '=',
        },
        bindToController: true
    };

    return directive;
}

FileUploadBoxFieldController.$inject = ['$scope', '$window', '$q', '$resource', 'appConfiguration', 'log4d'];

function FileUploadBoxFieldController($scope, $window, $q, $resource, appConfiguration, log4d) {

    var logger = log4d.logger('fileUpload');

    var vm = this;

    //Bindable members
    vm.retrieveFile = retrieveFile;
    vm.remove = remove;

    //Init
    activate();

    /**
     * Initialises the file upload box field by performing the following actions:
     * - Setting initial view-state, based on the data & edit attribute
     * - Setting multi-file upload property, based on the metadata
     */
    function activate() {
        vm.edit = !!vm.edit;
        vm.multiple = (vm.metadata.limit || 1) > 1;
        vm.disableRemove = false;

        if (typeof (vm.data) === 'string') // Guid
            retrieveFileInfo('fileupload', vm.data);
        else if (typeof (vm.data) === 'object') // Attachment Object
            vm.data = vm.data;
    }


    //Implementation
    /**
     * Opens the file in a new windows
     * @param {string} endpoint The endpoint of the fileuploadcontroller
     * @param {guid} guid The GUID of the requested attachment
     */
    function retrieveFile(endpoint, guid) {
        if (vm.data)
            $window.open(appConfiguration.apiUrl + endpoint + '/' + guid + '?download=1', '_blank');
    }

    /**
     * Sets data entry to 'null', the server will remove the attachment accordingly
     */
    function remove()
    {
        vm.data = null;
    }

    //Internals
    /**
     * Retrieves the necessary data for the list-view, based on the guid in the data property
     * This data is used to allow downloading of the attachment from the list-view
     */
    function retrieveFileInfo(endpoint, guid) {
        var query = {};
        query.guid = guid;
        $resource(appConfiguration.apiUrl + endpoint + '/:guid', { guid: "@Guid" })
            .get(query).$promise
            .then(function (result) {
                logger.log('Retrieved file info for "'+ guid +'"');
                vm.data = result;
                vm.data.Link = appConfiguration.apiUrl + endpoint + '/' + guid + '?download=1';
            }, function (error){
                logger.log('Error retrieving file info for "' + guid + '"' + error);
            });
    }

    $scope.$watch('vm.data', function (newValue, oldValue) {
        if (newValue)
            activate();
    });
}

module.exports = ivdFileUploadBoxField;