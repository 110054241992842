ivdTimeField.$inject = [];

function ivdTimeField() {
    /*
        Usage:
        <ivd-text-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
    */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/time-field/time-field.html',
        controller: TimeFieldController,
        controllerAs: 'timeField',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

TimeFieldController.$inject = ['objectAccess'];

function TimeFieldController(objectAccess) {
    var vm = this;

    //Bindable members
    vm.get = get;
    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    function get(obj, str) {
        if (obj == undefined) {
            return '';
        }
        return objectAccess.getValueByString(obj, str);
    }
}

(module || {}).exports = ivdTimeField;