timeboxSelectBoxService.$inject = ['$resource', 'appConfiguration', 'restService'];

function timeboxSelectBoxService($resource, appConfiguration, restService) {

    // implementation details
    function query(endpoint, parameters, context) { //QUERY
        return restService.query(endpoint, parameters, context);
    }

    function get(endpoint, id) { //GET
        return restService.get(endpoint, id);
    }
    function maxQuerySize() {
        return restService.maxQuerySize;
    }

    // metadata retrieval
    var parameters = {
        getFieldDescription : function (metadata) {
            return metadata.selectbox_text;
        },
        getFieldId : function (metadata) {
            return metadata.selectbox_id;
        },
        getEndpoint : function (metadata) {
            return metadata.selectbox_endpoint;
        },
        getReadOnly: function (metadata) {
            return !!metadata.readOnly;
        }
    };

    // Return service
     var service = {
        query: query,
        get: get,
        parameters: parameters,
        maxQuerySize: maxQuerySize
    };
    return service;
}

(module || {}).exports = timeboxSelectBoxService;