ivdValidateAsDate.$inject = ['ivdDateTimeValidator'];

function ivdValidateAsDate(ivdDateTimeValidator) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ngModel) {

            ngModel.$validators.dateFormat = function (modelValue, viewValue) {
                var value = modelValue || viewValue;
                if (!value) return true;
                return ivdDateTimeValidator.validateDateFormat(value, attr["ivdValidateAsDate"]).isValid;
            };

            function setDirty(value, old) {
                if (value != old) {
                    if ((value == undefined || value.trim() == "")) {
                        ngModel.$setPristine();
                        return;
                    }
                    ngModel.$setDirty();
                }
            }

            scope.$watch(function () {
                return ngModel.$viewValue;
            }, setDirty);
        }
    }
}

(module||{}).exports = ivdValidateAsDate;