'use strict';

var mod = angular.module('ivd.logging', ['ivd.configuration']);

mod.config(['appConfigurationProvider', function (appConfigurationProvider) {
    appConfigurationProvider
        .ensureNamespace('log4d.global')
        .ensureNamespace('log4d.loggers')
        ;
}]);

mod.factory('log4d', require('./log4d.js'));

mod.factory('$exceptionHandler', require('./global-exception-handler.js'));

(module || {}).exports = 'ivd.logging';