'use strict';

function appConfiguration() {
    var configData = undefined;
    var defaultValues = {};
    function addValueForKey(o, key, value) { // 'key' can be namespaced with dots ('.')
        var array = key.split('.');
        for (var index = 0; index < array.length; index++) {
            var element = array[index];
            if (index === array.length - 1) o[element] = value;
            else if (!o[element]) o[element] = {};
            o = o[element];
        }
    }
    function getValueForKey(o, key) { // 'key' can be namespaced with dots ('.')
        var result = o;
        var array = key.split('.');
        for (var index = 0; index < array.length; index++) {
            var element = array[index];
            if (result[element] === undefined) return undefined;
            else result = result[element]; // drill down
        };
        return result;
    }
    get.$inject = ['appSettings', '$log']; // cannot inject log4d service here because of a circular reference
    function get(appSettings, $log) 
    {
        try {
            configData = appSettings;
        } catch (err) {
            $log.error(err);
        }
        if (!configData)
            configData = {};
        for (var key in defaultValues) {
            if (configData[key] === undefined)
                addValueForKey(configData, key, getValueForKey(defaultValues, key));
        }
        return configData;
    }

    return {
        defaultValue: function (key, value) { // getter/setter function
            if (value!==undefined) {
                // setter
                addValueForKey(defaultValues, key, value);
                return this; // for fluent configuration
            } else {
                // getter
                return defaultValues[key];
            }
        },
        ensureNamespace: function (key) {
            addValueForKey(defaultValues, key, {});
            return this; // for fluent configuration
        },
        $get: get
    }
};

(module || {}).exports = appConfiguration;