ActivationController.$inject = ['$location', 'authenticationService'];

function ActivationController($location, authenticationService) {
    var vm = this;

    vm.activationCode = undefined;
    vm.loading = false;

    activate();

    function activate() {
        var queryParams = $location.search();
        vm.activationCode = queryParams.ActivationCode;

        if (vm.activationCode) {
            vm.loading = true;

            authenticationService.activate(vm.activationCode)
                .then(function (result) {
                    vm.activated = true;
                })
                .catch(function (err) {
                    vm.activated = false;
                })
                .finally(function () {
                    vm.loading = false;
                });
        }
    }
}

(module || {}).exports = ActivationController;
