insightService.$inject = [];

/// Wrapper service for the Application Insights JavaScript from Microsoft.
/// If Application Insights is not available (the correct script is not loaded in the head section), this service will do nothing.
/// https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md
function insightService() {
    var service = {
        isAvailable: isAvailable,
        trackPageView: trackPageView,
        trackEvent: trackEvent,
        trackMetric: trackMetric,
        trackException: trackException,

        //trackDependency: trackDependency,
        //trackTrace: trackTrace,

        startTrackPage: startTrackPage,
        stopTrackPage: stopTrackPage
        //startTrackEvent: startTrackEvent,
        //stopTrackEvent: stopTrackEvent,

        //flush: flush,
        //setAuthenticatedUserContext: setAuthenticatedUserContext,
        //clearAuthenticatedUserContext: clearAuthenticatedUserContext
    };
    return service;
    
    /// returns true if application Insights is configured in this application;
    function isAvailable() {
        var appInsightsReferenced = !!window.appInsights;
        var appInsightsActive = !!(appSettings.applicationInsights && appSettings.applicationInsights.instrumentationKey)
        if (appInsightsReferenced && appInsightsActive)
            return true;
        return false;
    }

    /// track Page loading performance.
    /// pageName: string
    ///     The name used to identify the page in the Application Insights portal.
    /// url: string (optional)
    ///     the url associated with this page.
    /// properties : {string: string} (optional)
    ///     additional data to filter on in the Application Insights portal.
    /// measurements: {string: number} (optional)
    ///     additional metrics associated with this page.
    /// duration: number (optional)
    ///     number of milliseconds to render the page.
    function trackPageView(pageName, url, properties, measurements, duration) {
        if(isAvailable()) window.appInsights.trackPageView(pageName, url, properties, measurements, duration);
    }

    /// track a costum event.
    /// eventName: string
    ///     name to identify this event in the Application Insights portal.
    /// properties : {string: string} (optional)
    ///     additional data to filter on in the Application Insights portal.
    /// measurements: {string: number} (optional)
    ///     additional metrics associated with this event.
    function trackEvent(eventName, properties, measurements) {
        if (isAvailable()) window.appInsights.trackEvent(eventName, properties, measurements);
    }

    /// track a costum metric.
    /// metricName: string
    ///     string to identiy the metric in the Application Insights portal.
    /// average: number
    ///     either a single measurement or the average of several measurements.
    /// sampleCount: number (optional)
    ///     Count of measurements represented by the average.
    /// min: number (optional)
    ///     smallest meausement in sample
    /// max: number (optional)
    ///     largest meusurement in sample
    /// properties: {string: string} (optional)
    ///     additional data associated with this metric, used to filter in the Application Insights portal.
    function trackMetric(metricName, average, sampleCount, min, max, properties) {
        if (isAvailable()) window.appInsights.trackMetric(metricName, average, sampleCount, min, max, properties);
    }

    /// Log a caught exception. Exceptions caught by the browers are automatically logged.
    /// exception: Error
    ///     error caught in a catch clause.
    /// handledAt: string (optional)
    ///     Defaults to "unhandled"
    /// properties: {string: string} (optional)
    ///     additional data associated with this exception, used to filter in the Application Insights portal.
    /// meausements {string: number} (optional)
    ///     additional metrics associated with this exception.
    /// severityLevel : AI.SeverityLevel (Verbose | Information | Warning | Error | Critical) (optional)
    ///     severityLevel of this exception.
    function trackException(exception, handledAt, properties, measurements, severityLevel) {
        if (isAvailable()) window.appInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
    }

    /// Starts the timer for tracking a page view.
    /// name: string
    ///     The name used to identify the page in the portal. Defaults to the document title.
    function startTrackPage(name) {
        if (isAvailable()) window.appInsights.startTrackPage(name);
    }

    /// Starts the timer for tracking a page view.
    /// name: string
    ///     The name used to identify the page in the portal. Defaults to the document title.
    /// url: string
    ///     A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
    /// properties: Object
    ///     Map of string to string: Additional data used to filter pages in the portal. Defaults to empty.
    /// measurements: Object
    ///     Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
    function stopTrackPage(name, url, properties, measurements) {
        if (isAvailable()) window.appInsights.stopTrackPage(name, url, properties, measurements);
    }
}

(module || {}).exports = insightService;