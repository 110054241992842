menuService.$inject = ['appConfiguration', '$translate', '$http', '$q', 'log4d', 'authenticationService'];

function menuService(appConfiguration, $translate, $http, $q, log4d, authenticationService) {
    // private variables
    var menuNodes = undefined;
    var logger = log4d.logger('menu');

    // public API
    var service = {
        getMenu: getMenu
    };
    return service;

    // internals
    function getMenu() {
        return loadMenuNodes();
    }

    function loadMenuNodes() {
        menuNodes = [];

        return $http.get(getMenuLocation()).then(function (result) {
            if (!!result && !!result.data) {
                logger.debug('loading menu for: ', result.data.menuName);
                angular.copy(result.data.nodes || [], menuNodes);
                return result.data.nodes;
            } else {
                return null;
            }
        }).catch(function (msg) {
            logger.error('menulocation not found for current userrole.', msg);
            logger.debug('loading menu for: ', getMenuLocation());
            return menuNodes;
        });
    }

    function getMenuLocation() {
        var role = "";

        var menuFolder = appConfiguration.menuFolder || 'data/';

        var user = authenticationService.getUser();

        if (user) {
            role = user.roleCode;
        }

        role = role || "app";

        logger.debug('loading menu for role: ', role);

        return menuFolder + role.toLowerCase() + '-menu.json';
    }

    function translateMenuNodes() {
        // translate name for the menu search function
        var asyncCalls = [];
        _.each(menuNodes, function(node){
            asyncCalls.push(asyncTranslate(node));
        });
        return $q.all(asyncCalls)
            .then (function() {
                return menuNodes;
            });

        function asyncTranslate(node){
            logger.log(node.textId, '-->')

            return $translate(node.textId).then(function(translation){
                logger.log(node.textId, '-->', translation);
                node.name = translation;
            });
        }
    }
}

(module || {}).exports = menuService;
