ivdFileUploadBox.$inject = [];

/**
 * Usage:
 * <ivd-file-upload-box 
 *     data="data" 
 *     metadata="meta" 
 *     edit="bool" multiple="multiple">
 * </ivd-file-upload-box>
 */
function ivdFileUploadBox() {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/file-upload-box/file-upload-box.html',
        controller: FileUploadBoxController,
        controllerAs: 'vm',
        scope: {
            data: '=',
            metadata: '=',
            processing: '='
        },
        bindToController: true
    };

    return directive;
}

FileUploadBoxController.$inject = ['$element', '$scope', '$q', '$resource', 'FileUploader', 'appConfiguration', 'log4d'];

function FileUploadBoxController($element, $scope, $q, $resource, FileUploader, appConfiguration, log4d) {
    
    var logger = log4d.logger('fileUpload')    
    
    var vm = this;

    //--Bindable members
    vm.fileClick = fileClick;
    vm.remove = remove;


    //--Init
    activate();

    /** 
     * Initialises the file upload box by performing the following actions:
     * - Setting the file upload limit
     * - Setting whether multi-file upload is supported or not
     * - Setting the allowed file extensions
     */
    function activate() {
        vm.fileLimit = vm.metadata.limit || 1;
        vm.multiple = vm.fileLimit > 1;
        vm.endpoint = vm.metadata.endpoint;
        vm.allowedExtensions = vm.metadata.allowedExtensions || [];
        vm.maxSizeKB = vm.metadata.maxSize || 10 * 1024;

        vm.uploader = new FileUploader({
            url: appConfiguration.apiUrl + vm.endpoint,
            queueLimit: vm.fileLimit,
            autoUpload: true
        });
        vm.uploader.filters.push({
            name: 'extensionFilter',
            fn: function(item) {
                var extension = item.name.split('.').pop().toLowerCase() // Get extension
                if (vm.allowedExtensions.length == 0) {
                    return true;
                }
                else if (vm.allowedExtensions.indexOf(extension) > -1) {
                    return true;
                }
                else {
                    logger.log('Fileuploader > file "' + item.name + '"has an invalid extension and will not be uploaded.');
                    return false;
                }
            }
        });

        vm.uploader.filters.push({
            name: 'sizeFilter',
            fn: function (item) {
                var kBytesize = item.size/1024;
                if (kBytesize <= vm.maxSizeKB) {
                    return true;
                }
                else {
                    logger.log('Fileuploader > file "' + item.name + '" is too big, max filesize is ' + vm.maxSizeKB/1024 + 'MB.');
                    return false;
                }
            }
        });

        vm.options = { url: appConfiguration.apiUrl + vm.endpoint };
    }


    // Implementation
    /** 
     * Cancels the item's upload process to the server, and remoces it from the list. 
     * If the item was already uploaded to the server, it will be removed from the server.
     */
    function remove(item) {
        if (item.isUploading && item.progress != 100){
            item.cancel();
            item.remove();
        }
        else if (item.isSuccess && item.Guid) {
            removeByGuid(vm.endpoint, item).then(
                function () {
                    logger.log("FileUpload > item deleted: " + item.file.name);
                    if (vm.multiple) {
                        angular.forEach(vm.data, function (value, key) {
                            if (value.Guid == item.Guid)
                                vm.data.splice(key, 1);
                        });
                    }
                    else
                        vm.data = null;
                    item.remove();
                },
                function () {
                    logger.warn("FileUpload > item couldn't be deleted: " + item.file.name);
                });
        } 
        else {
            item.remove();
        }
    }


    //--Internals
    /**
     * Passthrough to the real click event
     */
    function fileClick() {
        angular.element($element).find('.input-file').click();
    }

    /**
     * Removes attachment from server (by guid)
     * @param {string} endpoint The endpoint of the fileuploadcontroller
     * @param {attachment} entity The item (with GUID property) that was uploaded
     * @returns {Promise} Promise that returns valid if the attachment was deleted on the server
     */
    function removeByGuid(endpoint, entity) { 
        return $resource(appConfiguration.apiUrl + endpoint + '/:guid', { guid: "@Guid" }, {})
            .remove({ guid: (entity.guid || entity.Guid) }).$promise; //DELETE
    }


    //--Upload Eventhandlers
    vm.uploader.onAfterAddingFile = function (item) {
        logger.log("FileUpload > item added to queue: " + item.file.name);
        vm.processing = true;
    };
    vm.uploader.onWhenAddingFileFailed = function (item, filter, options) {
        logger.log("FileUpload > item couldn't be added to queue: " + item.name + "(filter: " + filter + " , options: " + options + ")");
        if (filter.name == "queueLimit") {
            vm.alert = 'Maximum amount of files reached';
            vm.showAlert = true;
        }
        else if (filter.name == "extensionFilter") {
            vm.alert = 'Invalid file extension for file "' + item.name + '"';
            vm.showAlert = true;
        }
        else if (filter.name == "sizeFilter") {
            vm.alert = 'Filesize too big for file "' + item.name + '"' + ', max filesize is ' + vm.maxSizeKB/1024 + 'MB.';
            vm.showAlert = true;
        }
        else {
            vm.alert = 'File "' + item.name + '" could not be added';
            vm.showAlert = true;
        }
    };
    vm.uploader.onProgressItem = function (item, progress) {
        logger.log("FileUpload > item progress: " + item.file.name + " " + progress + "%");
    };
    vm.uploader.onCompleteItem = function (item, response, status, headers) {
        logger.log("FileUpload > item completed: " + item.file.name + " " + status);
    };
    vm.uploader.onCancelItem = function (item, response, status, headers) {
        logger.log("FileUpload > item cancelled: " + item.file.name + " " + status);
        vm.processing = false;
    };
    vm.uploader.onSuccessItem = function (item, response, status, headers) {
        logger.log("FileUpload > item success: " + item.file.name + " " + status);
        item.Guid = response.Guid;

        var query = {};
        query.guid = response.Guid;
        $resource(appConfiguration.apiUrl + 'fileupload' + '/:guid', { guid: "@Guid" })
            .get(query).$promise.then(function (result) {
                vm.data = vm.multiple ? vm.data || [] : vm.data || {};
                if (vm.multiple)
                    vm.data.push(result);
                else
                    vm.data = result;
                vm.processing = false;
            });
    };
    vm.uploader.onErrorItem = function (item, response, status, headers) {
        logger.log("FileUpload > item error: " + item.file.name + " " + status);
        vm.processing = false;
    };


    $scope.$watch('vm.data', function (newValue, oldValue) {
        if (vm.uploader && (newValue == null || newValue == []))
            vm.uploader.clearQueue();
    });

    //-- TODO:
    //endpoint url from metadata => configureerbaar in te stellen volgens type attachment
    //HTML4 Support
    // multiple/single functionaliteit
}

(module || {}).exports = ivdFileUploadBox;