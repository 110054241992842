ivdTextField.$inject = [];

function ivdTextField() {
    /*
        Usage:
        <ivd-text-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
    */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/text-field/text-field.html',
        controller: TextFieldController,
        controllerAs: 'textField',
        scope: {
            data: '=',
            metadata: '<',
            edit: '<',
            align: '<'
        },
        bindToController: true
    };

    return directive;
}

TextFieldController.$inject = ['$scope', 'objectAccess', 'appConfiguration'];

function TextFieldController($scope, objectAccess, appConfiguration) {
    var vm = this;
    var viewAllLines = false;

    //Bindable members
    vm.get = _.get;
    vm.placeholder = appConfiguration.fields_show_placeholder ? vm.metadata.label : '';
    vm.toggleViewAllLines = toggleViewAllLines;

    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    function toggleViewAllLines() {
        if (vm.metadata.multiline && vm.metadata.multiline.rows > 1) {
            vm.displayData = viewAllLines ? vm.linesData : vm.data;
            viewAllLines = !viewAllLines;
        }
    }

    function formatForView() {
        if (!vm.data) {
            return;
        }
        
        vm.displayData = vm.data;

        if (vm.metadata.multiline && vm.metadata.multiline.rows > 1) {
            var newline = '\n';
            var lines = vm.data.split(newline);
            if (lines.length > vm.metadata.multiline.rows) {
                vm.linesData = lines.splice(0, vm.metadata.multiline.rows).join(newline) + '...';
            } else {
                vm.linesData = vm.data;
            }
            vm.displayData = vm.linesData;
        }
    }

    $scope.$watch(function () {
        return vm.data;
    }, formatForView);
}

(module || {}).exports = ivdTextField;
