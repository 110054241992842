ivdValidate.$inject = ['$compile'];

function ivdValidate ($compile) {
    return {
        restrict: 'A',
        replace: false,
        terminal: true, // prevent lower priority directives to compile after it
        priority: 1000, // compiles first
        link: function link(scope, el, attrs, ngModelCtrl) {
            var path2metadata = el.attr('ivd-validate');
            if (path2metadata == undefined) return;
            var metadata = scope;
            _.forEach(path2metadata.split('.'), function (prop) {
                if (metadata != undefined) metadata = metadata[prop];
            })
            el.removeAttr('ivd-validate'); // necessary to avoid infinite compile loop
            el.removeAttr('data-ivd-validate')

            metadata = metadata || {};

            if (metadata.required) el.attr('ng-required', true);
            if (metadata.minlength) el.attr('ng-minlength', metadata.minlength);
            if (metadata.maxlength) el.attr('ng-maxlength', metadata.maxlength);
            if (metadata.regex) el.attr('ng-pattern', metadata.regex);

            $compile(el)(scope);
        }
    };
}

(module || {}).exports = ivdValidate;