/// <reference path="../../../../bower_components/moment/moment.js" />

ivdPeriodPicker.$inject=['$window'];

function ivdPeriodPicker($window) {
    //  Usage: 
    //      <ivd-period-picker></ivd-period-picker>
    //  Creates:
    //
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/period-picker/period-picker.html',
        scope: {
            data: '=',
            start: "=?",
            end: "=?",
            format: '=?',
            exchange: '&'
        },
        controllerAs: 'periodPicker',
        controller: PeriodPickerController,
        bindToController: true
    };

    return directive;
}

PeriodPickerController.$inject = ['ivdDateTimeValidator', '$filter', '$scope', '$document', '$element'];

function PeriodPickerController(ivdDateTimeValidator, $filter, $scope, $document, $element) {
    var vm = this;

    //Public API
    vm.select = select;
    vm.handleInput = handleInput;
    vm.toggleDropdown = toggleDropdown;
    vm.changeInput = changeInput;
    vm.disableWatch = disableWatch;
    vm.watchEnabled = true;
    vm.reset = resetPeriodPicker;
    //init:
    activate();

    function activate() {
        vm.format = 'dd/MM/yyyy';
        disableWatch();
        if (vm.data && vm.data !== '') {
            var dates = vm.data.split('..');
            if (dates[0] !== '') {
                vm.start = new Date(dates[0] + 'T12:00:00.000Z');
                vm.minEnd = vm.start;
            } else vm.start = undefined;
            if (dates[1] !== '') {
                vm.end = new Date(dates[1] + 'T12:00:00.000Z');
                vm.maxStart = vm.end;
            } else vm.end = undefined;
            vm.input = (vm.start ? $filter('date')(vm.start, vm.format) : '')
                + (vm.start || vm.end ? '..' : '')
                + (vm.end ? $filter('date')(vm.end, vm.format) : '');
        } else {
            vm.input = vm.data;
            vm.start = vm.start;
            vm.end = vm.end;
        }
        
        vm.options = [ //Options for the dropdown menu
            {name: "Last Year",     range: "y", time: "p"},     //p for past            
            {name: "Last Month",    range: "m", time: "p"},
            {name: "Last Week",     range: "w", time: "p"},
            {name: "Today",         range: "d", time: "f"},     //f for future            
            {name: "This Week",     range: "w", time: "t"},     //t for this            
            {name: "This Month",    range: "m", time: "t"},
            {name: "This Year",     range: "y", time: "t"},
            {name: "Next Week",     range: "w", time: "f"},
            {name: "Next Month",    range: "m", time: "f"},
            {name: "Next Year",     range: "y", time: "f"}
        ];
        vm.open = false;
    }

    //implementation
    /*
        Select a given time range. Call comes from the dropdown menu
        Range: Either d (day), w (week), m (month) or y (year)
        Time: Either p (past), t (this) or f (future)
    */
    function select(range, time) {
        var start, end;
        switch (range) { 
            case "d": // Case day
                start = moment().toDate();
                end = moment().toDate();
                break;
            case "w": // Case week
                if (time === "f") { // Next week                    
                    start = moment().isoWeekday(1).add(7, 'd').toDate();
                    end = moment().isoWeekday(7).add(7, 'd').toDate();
                } else if (time === "p") { // Last week
                    start = moment().isoWeekday(1).add(-7, 'd').toDate();
                    end = moment().isoWeekday(7).add(-7, 'd').toDate();
                } else {
                    start = moment().isoWeekday(1).toDate();
                    end = moment().isoWeekday(7).toDate();
                }
                break;
            case "m": // Case month
                if (time === "f") { // Next month
                    start = moment().startOf('month').add(1, 'M').toDate();
                    end = moment().add(1, 'M').endOf('month').toDate();
                } else if (time === "p") { // Last month
                    start = moment().startOf('month').add(-1, 'M').toDate();
                    end = moment().add(-1, 'M').endOf('month').toDate();
                } else {
                    start = moment().startOf('month').toDate();
                    end = moment().endOf('month').toDate();
                }
                break;
            case "y": // Case year
                if (time === "f") { // Next year
                    start = moment().startOf('year').add(1, 'y').toDate();
                    end = moment().endOf('year').add(1, 'y').toDate();
                } else if (time === "p") { // Last year
                    start = moment().startOf('year').add(-1, 'y').toDate();
                    end = moment().endOf('year').add(-1, 'y').toDate();
                } else {
                    start = moment().startOf('year').toDate();
                    end = moment().endOf('year').toDate();
                }
                break;
        } 
        vm.start = start;
        vm.end = end;
        vm.changeInput();
    }

    //If manual input, validate input
    function handleInput() {
        if (vm.input) {
            switch (vm.input) {
                case '':
                case '..':
                    vm.start = undefined;
                    vm.end = undefined;
                    vm.input = '';
                    vm.data = '';
                    break;
                case ' ':
                    vm.start = new Date();
                    vm.end = new Date();
                    break;
                case ' ..':
                    vm.start = new Date();
                    vm.end = undefined;
                    break;
                case '-..':
                    vm.start = moment().add(1, 'd').toDate();
                    vm.end = undefined;
                    break;
                case '.. ':
                    vm.start = undefined;
                    vm.end = new Date();
                    break;
                case '..-':
                    vm.start = undefined;
                    vm.end = moment().add(-1, 'd').toDate();
                    break;
                default:
                    var dates = vm.input.split('..');
                    vm.start = ivdDateTimeValidator.validateDateFormat(dates[0], vm.format).newDate;
                    vm.end = ivdDateTimeValidator.validateDateFormat(dates[1], vm.format).newDate;
            }
        } else {
            vm.start = undefined;
            vm.end = undefined;
            vm.input = '';
            vm.data = '';
        }
        vm.changeInput();
    }

    function toggleDropdown() {
        vm.open = !vm.open;
    }

    function disableWatch() {
        vm.watchEnabled = false;
    }

    function resetPeriodPicker() {
        vm.start = undefined;
        vm.end = undefined;
        vm.input = '';
        vm.minEnd = null;
        vm.maxStart = null;
    }

    function changeInput() {
        vm.watchEnabled = true;
        var startDate, endDate; // needed for datepicker
        var startJSON, endJSON; // needed for query
        if (vm.start !== undefined) {
            vm.start.setHours(12, 0, 0, 0);
            startDate = moment.utc(vm.start.toJSON());
            startJSON = moment.utc(vm.start.toJSON()).startOf('day').toJSON();
            // Set minDate attribute of endDatePicker to startDate
            vm.minEnd = vm.start;
        } else {
            startDate = '';
            startJSON = '';
        }
        if (vm.end !== undefined) {
            vm.end.setHours(12, 0, 0, 0);
            endDate = moment.utc(vm.end.toJSON());
            endJSON = moment.utc(vm.end.toJSON()).endOf('day').toJSON();
            // Set maxDate attribute of startDatePicker to endDate
            vm.maxStart = vm.end;
        } else {
            endDate = '';
            endJSON = '';
        }
        if (vm.start || vm.end) {
            vm.input = (startDate == "" ? startDate : $filter('date')(startDate.toDate(), vm.format))
                + '..'
                + (endDate == "" ? endDate : $filter('date')(endDate.toDate(), vm.format));
            vm.data = (startJSON == "" ? startJSON : startJSON.substring(0, 10))
                + '..'
                + (endJSON == "" ? endJSON : endJSON.substring(0, 10));
        } else {
            vm.input = '';
            vm.data = '';
        }
        vm.input = '_' + vm.input;      // needed to trigger the watch afterwards
    }

    // Watch on input to trigger the query
    $scope.$watch(function () { return vm.input }, function (nVal) {
        if (nVal != undefined && vm.watchEnabled) {
            if (vm.input.substring(0, 1) !== '_') {
                vm.exchange();
            } else {
                vm.input = vm.input.substring(1);   // triggers the watch again (otherwise, no change detected when blurring out of input field)
            }
        }
    });

    // Watch on the search fields to clear data when 'clear' is called
    $scope.$watch(function () { return vm.data }, function (nVal) {
        if (!nVal) {
            resetPeriodPicker();
        }
    });

    // Watch on periodpicker open to close when clicked outsitde
    $scope.$watch(function () { return vm.open; }, function (value) {
        if (value) {
            $document.bind('click', documentClickBind);
        } else {
            $document.unbind('click', documentClickBind);
        }
    });

    function documentClickBind() {
        var dpContainsTarget = $element[0].contains(event.target);
        if (vm.open && !dpContainsTarget) {
            var partOfDatePicker = false;
            var el = event.target;
            if (el) {
                do {
                    if (el.nodeName == 'BUTTON') {
                        partOfDatePicker = true;
                        break;
                    }
                    el = el.parentElement;
                } while (el);
            }
            if (!partOfDatePicker) {
                $scope.$apply(function () {
                    vm.open = false;
                });
            }
        }
    }
}

(module || {}).exports = ivdPeriodPicker;