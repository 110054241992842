ivdAuthBox.$inject = ['$window', '$document'];

function ivdAuthBox($window, $document) {
    // Usage:
    //     <ivd-auth-box></ivd-auth-box>
    // Creates:
    //
    var directive = {
        restrict: 'E',
        scope: {
            tabOpen: '@',               // values (string)  : login, register
            allowRegistration: '<',      // values (bool)    : true, false
            changeLanguage: '&',        // function
            hidePasswordReset: '='      // values (bool)    : true, false
        },
        templateUrl: "ivd/ui/auth-box/auth-box.html",
        controllerAs: 'authBox',
        controller: AuthBoxController,
        bindToController: true,
        transclude: true,
        link: function (scope, el, attrs) {
            $document.bind('keypress', function (e) {
                if (e.keyCode == 13) {
                    if (scope.authBox.tabOpened[0] && !scope.authBox.authenticated)
                        scope.authBox.login();
                    else if (scope.authBox.tabOpened[1])
                        scope.authBox.register();
                }
            });
        }
    }
    return directive;
}

AuthBoxController.$inject = ['$scope', '$uibModal', 'authenticationTokenPersistenceService',
    'authenticationService', '$location', 'storage', 'log4d']

function AuthBoxController($scope, $uibModal, authenticationTokenPersistenceService,
    authenticationService, $location, storage, log4d) {

    var logger = log4d.logger('authBox');

    // Linkup
    var vm = this;
    vm.login = login;
    vm.logout = logout;
    vm.changePassword = changePassword;

    //if (angular.isUndefined(vm.changeLanguage))
        vm.changeLanguage = changeLanguage;
    vm.register = register;
    vm.resetPasswordModal = resetPasswordModal;

    // Init
    vm.tabOpened = [];
    vm.username = '';
    vm.displayname = '';
    vm.password = '';

    vm.registration = {};
    vm.registration.username = '';
    vm.registration.firstname = '';
    vm.registration.lastname = '';
    vm.registration.email = '';
    vm.registration.password = '';
    vm.registration.passwordconfirmation = '';
    vm.passwordScoreStyle = 'danger';
    vm.passwordFeedback = {};

    var zxcvbn = require('zxcvbn');


    activate();


    function activate() {
        vm.loginAlert = '';
        vm.loggingIn = false;
        vm.registering = false;
        vm.resetting = false;

        vm.tabOpened[0] = (vm.tabOpen === 'login');
        vm.tabOpened[1] = (vm.tabOpen === 'register');

        vm.authenticated = authenticationTokenPersistenceService.authenticated;
        if (authenticationTokenPersistenceService.user) {
            vm.username = authenticationTokenPersistenceService.user.unique_name;
            vm.displayname = authenticationTokenPersistenceService.user.userName;
        }


    }

    function login() {
        if (!vm.loggingIn) {
            vm.loggingIn = true;
            authenticationService.login(vm.username, vm.password)
                .then(function (result) {
                    if (!result.Authenticated && result.Message) {
                        vm.loginAlert = result.Message;
                    }
                    if (result.Authenticated) {
                        var pathToLoadWhenAuthenticated = storage.getItem('pathToLoadWhenAuthenticated');
                        if (pathToLoadWhenAuthenticated) {
                            storage.removeItem('pathToLoadWhenAuthenticated');
                            $location.path(pathToLoadWhenAuthenticated);
                        } else {
                            $location.path('/');
                        }
                    }
                    vm.loggingIn = false;
                })
                .catch(function (result) {
                    if (!result.Authenticated && result.Message) {
                        vm.loginAlert = result.Message;
                    }
                    vm.loggingIn = false;
                });
        }
    }
    
    function logout() {
        authenticationService.logout();
        vm.username = '';
        vm.password = '';
    }

    function changePassword(email) {
        // $location.path('/changePassword');
        resetPasswordModal();
    }

    function changeLanguage() {
        $location.path('/changeLanguage');
    }

    function register() {
        vm.registerAlert = null;
        vm.registerSuccess = null;

        if (!vm.registering) {
            vm.registering = true;
            authenticationService.register(vm.registration)
                .then(function (result) {
                    vm.registering = false;
                    vm.registerSuccess = result.data;
                })
                .catch(function (error) {
                    vm.registering = false;
                    vm.registerAlert = error.data;
                });
        }
    }

    function resetPasswordModal() {
        vm.resetting = true;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'ivd/authentication/password-recovery/passwordResetModal.html',
            controller: 'passwordRecoveryModalController',
            controllerAs: 'pwrm',
            resolve: {
                items: function () {
                    //return $scope.items;
                }
            },
            backdrop: 'true'
        });

        modalInstance.result.then(function () {
            vm.resetting = false;
        }, function () {
            vm.resetting = false;
        });
    }


    // Watch authentication variables
    $scope.$watch(function () {
        return authenticationTokenPersistenceService.authenticated;
    }, function (newValue, oldValue) {
        if (newValue !== oldValue) //See https://docs.angularjs.org/api/ng/type/$rootScope.Scope
        {
            logger.log('Detected change in authentication state: ' + oldValue + ' -> ' + newValue);
            activate();
        }
    });

    $scope.$watch(function () {
        return vm.registration.password;
    }, function (newValue) {
        if (!newValue) {
            vm.passwordFeedback = null;
            vm.passwordScoreStyle = 'danger';

            return;
        }

        var result = zxcvbn(newValue);
        var score = result.score;
        vm.passwordFeedback = result;
        vm.passwordScoreStyle = score >= 3 ? 'success'
            : score >= 2 ? 'warning'
            : 'danger';
        vm.passwordFormGroupStyle = vm.passwordScoreStyle === 'danger' ? 'error'
            : vm.passwordScoreStyle;
    });
}


(module || {}).exports = ivdAuthBox;
