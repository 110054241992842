ivdFeedbackButton.$inject = [];

function ivdFeedbackButton() {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/feedback-button/feedback-button.html',
        controller: FeedbackButtonController,
        controllerAs: 'vm',
        bindToController: true
    };
    return directive;
}

FeedbackButtonController.$inject = ['$scope', 'ivdInsights'];

function FeedbackButtonController($scope, ivdInsights) {

    var vm = this;
    vm.answered = false;
    vm.available = ivdInsights.isAvailable();
    vm.score = 1;
    vm.feedback = "";
    vm.submit = submit;
    vm.good = setPositive;
    vm.bad = setNegative;

    function submit(){
        ivdInsights.trackMetric("Happiness", vm.score, { feedback: vm.feedback });
        vm.answered = true;
    }

    function setPositive() {
        vm.score = 1;
    }

    function setNegative() {
        vm.score = -1;
    }

    
}

(module || {}).exports = ivdFeedbackButton