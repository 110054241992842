/**
 * @file query-storage.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

queryStorage.$inject = ['storage', 'log4d'];

/**
 * An Angular service for storing and retrieving search and sort criteria 
 * in session storage.
 * 
 * @namespace
 */
function queryStorage(storage, log4d) {

    var logger = log4d.logger('restService');

    var service = {
        searchCriteria : searchCriteria,
        sortCriteria : sortCriteria,
        clearSearchCriteria: clearSearchCriteria,
        clearSortCriteria: clearSortCriteria,
        clear: clear
    };
    return service;

    // implementation details
    function storageItem(storageKey, endpoint, newCriteria) {
        var item = storage.getItem(storageKey) || {};
        if (angular.isDefined(newCriteria)) {
            // setter
            item[endpoint] = newCriteria;
            storage.setItem(storageKey, item);
        } 
        
        //getter anyway
        return item[endpoint] || {};
    }

    function clearItem(storageKey, endpoint) {
        if (angular.isUndefined(endpoint)) {
            storage.removeItem(storageKey);
        } else {
            var item = storage.getItem(storageKey);
            item[endpoint] = undefined;
            storage.setItem(storageKey, item);
        }
    }
    
    /**
     * Stores or retrieves the search criteria
     * 
     * ```javascript
       queryStorage.searchCriteria('Vehicles', {'Brand': 'BMW', FuelType: 'diesel'})
       queryStorage.searchCriteria('Vehicles') // returns {'Brand': 'BMW', FuelType: 'diesel'}
     * ```
     * 
     * @param {string} endpoint - endpoint
     * @param {Object} - the new criteria to set, omit if you want to call the getter
     * @returns {Object|undefined} - returns an Object when getter wass called,  
     * returns nothing if setter was called
     */
    function searchCriteria(endpoint, newCriteria) {
        return storageItem('search_criteria', endpoint, newCriteria);
    }

    function sortCriteria(endpoint, newCriteria) {
        return storageItem('sort_criteria', endpoint, newCriteria);
    }

    function clearSearchCriteria(endpoint) {
        clearItem('search_criteria', endpoint);
    }

    function clearSortCriteria(endpoint) {
        clearItem('sort_criteria', endpoint);
    }

    function clear() {
        clearSearchCriteria();
        clearSortCriteria();
        logger.log('queryStorage cleared');
    }
}

(module || {}).exports = queryStorage;
