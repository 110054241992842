ivdOnFinishRender.$inject = [];

function ivdOnFinishRender() {
    // Usage:
    //     as an attribute with a ng-repeat
    // Creates:
    // 
    var directive = {
        restrict: 'A',        
        link: function (scope, element, attrs) {
            if (scope.$last === true) {
                scope.$eval(attrs.ivdOnFinishRender);
            }
        }
    };
    return directive;
}

(module || {}).exports = ivdOnFinishRender;