ivdEmailField.$inject = [];

function ivdEmailField() {
    /*
        Usage:
        <ivd-email-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
    */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/email-field/email-field.html',
        controller: EmailFieldController,
        controllerAs: 'field',
        scope: {
            data: '=',
            metadata: '=',
            edit: '=',
            align: '='
        },
        bindToController: true
    };

    return directive;
}

EmailFieldController.$inject = ['objectAccess'];

function EmailFieldController(objectAccess) {
    var vm = this;

    //Bindable members
    vm.get = get;

    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    function get(obj, str) {
        if (obj == undefined) {
            return '';
        }
        return objectAccess.getValueByString(obj, str);
    }
}

(module || {}).exports = ivdEmailField;