
function doOnFilter() {
    return function (func, data, key) {
        data = data || [];
        switch (func) {
            case 'sum':
                var array = data.map(function (d) {
                    return d[key];
                });
                var sum = 0;
                for (var i = array.length; i--;) {
                    sum += array[i];
                }
                return sum;
            case 'count':
                return data.length;
        }
        return "";
    };
}

(module || {}).exports = doOnFilter;