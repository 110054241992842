displayAsFilter.$inject = ['$filter'];
// for a complete list of standard Angular filters : https://docs.angularjs.org/api/ng/filter

function displayAsFilter($filter) {
    return function (input, type, format) {
        switch (type) {
            case "date":
                return $filter('date')(input, format || 'dd/MM/yyyy');
            case "time":
                return $filter('date')(input, format || 'HH:mm');
            case "datetime":
                return $filter('date')(input, format || 'dd/MM/yyyy HH:mm');
            case "integer":
            case "int":
                if (angular.isNumber(input)) {
                    if (format === "HH:mm" || format === "H:mm") {
                        var d = new Date(0, 0, 0, 0, input);
                        return $filter('date')(d, format );
                    }
                }
                return input;
            default:
                return input;
        }
    };
}

(module || {}).exports = displayAsFilter;