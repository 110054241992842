creatorService.$inject = ['$uibModal'];

function creatorService($uibModal) {
    var service = {
        create: create
    };

    return service;

    function create(metadata, createFunction) {
        var vm = this;
        vm.metadata = metadata;
        vm.createFunction = createFunction;

        vm.CreateModalController = CreateModalController;
        vm.CreateModalController.$inject = ['$uibModalInstance', 'metadata', 'createFunction', 'log4d'];

        $uibModal.open({
            animation: true,
            size: 'lg',
            templateUrl: 'ivd/services/creator/creator-modal.html',
            controller: vm.CreateModalController,
            controllerAs: 'creator',
            bindToController: true, //Bind this scope to the controller's scope
            resolve: { //Pass the given ngModel to the controller of the modal
                metadata: function () {
                    return vm.metadata;
                },
                createFunction: function () {
                    return vm.createFunction;
                }
            },
            backdrop: 'static'
        });

        function CreateModalController($uibModalInstance, metadata, createFunction, log4d) {
            
            var logger = log4d.logger('createModal');
            
            var vm = this;

            vm.cancel = cancel;
            vm.create = create;

            activate();

            function activate() {
                vm.metadata = metadata;
                logger.debug('Metadata in CreateModalController: ', vm.metadata);
                vm.createFunction = createFunction;
                vm.editMode = true;
                vm.data = {};
                //for (var idx in vm.metadata) {
                //    vm.data[vm.metadata[idx].data] = null;
                //}
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function create() {
                createFunction(vm.data);
                $uibModalInstance.close('created');
            }
        }
    }
}

(module || {}).exports = creatorService;