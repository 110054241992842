/**
 * @file refresh-token-getter.js
 * @copyright (c) 2016 4D vision
 * @author Marcel Samyn
 * @license Proprietary
 */

tokenGetter.$inject = ['$http', 'authenticationTokenPersistenceService', 'appConfiguration'];

function tokenGetter($http, authenticationTokenPersistenceService, appConfiguration) {

    return $http({
        url: appConfiguration.apiUrl + "TokenAuthentication",
        skipAuthorization: true, // no infinite loop plz
        method: "POST",
        data: {
            token: authenticationTokenPersistenceService.refreshToken
        }
    }).then(function (response) {
        // Update token in store
        var token = response.data.Token;
        var claims = deconstructJWT(response.data.Token);
        authenticationTokenPersistenceService.login(token, claims);

        return token;
    });
}

function deconstructJWT(token) {
    var segments = token.split(".");
    if (!(segments instanceof Array) || segments.length !== 3) {
        throw new Error("Invalid JWT");
    }
    var claims = segments[1];
    return JSON.parse(decodeURIComponent(escape(window.atob(claims))));
}

(module || {}).exports = tokenGetter;
