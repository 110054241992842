var objectMapper = require('../../../lib/object-mapper/');

ivdSortBox.$inject = ['$window'];

function ivdSortBox($window) {
    // Usage:
    //     <ivd-sort-box></ivd-sort-box>
    // Creates:
    // 
    var directive = {
        restrict: 'E',
        scope: {
            sortFields: '=',
            endpoint: '=',
            loaded: '=?',
            refreshData: '&',
            delayedSorting: '=',
            isOpen: '=',
            onActivated: '&'
        },
        templateUrl: 'ivd/ui/sort-box/sort-box.html',
        controllerAs: 'sortBox',
        controller: SortBoxController,
        bindToController: true // because the scope is isolated
    };
    return directive;
}

SortBoxController.$inject = ['$q', 'restQueryBuilder', 'queryStorage', '$scope',
    'jwtTokenLocalReadService', 'log4d'];

function SortBoxController($q, restQueryBuilder, queryStorage, $scope,
    jwtTokenLocalReadService, log4d) {

    var vm = this;
    vm.doSort = doSort;
    vm.changeOrder = changeOrder;
    vm.changeFieldOrder = changeFieldOrder;
    vm.addSort = addSort;
    vm.clear = clear;
    vm.initialized = $q.defer();

    var userlang = jwtTokenLocalReadService.getUserLanguage();

    var logger = log4d.logger('sortBox');

    function activate() {

        logger.debug('activate sort', vm);

        if (vm.delayedSorting === '' || angular.isUndefined(vm.delayedSorting)) {
            vm.delayedSorting = false;
        }

        vm.localSortFields = [];      //first collect input in local array, then search when search button is clicked
        vm.optionalSortFields = [];
        var fields = vm.sortFields || [];

        fields.forEach(function (field) {
            field.forUserLanguage = true;
            if (field.languageRestrictions 
                && field.languageRestrictions.length > 0 
                && field.languageRestrictions.indexOf(userlang) === -1) {
                field.forUserLanguage = false;
            }
            if (field.visible && field.forUserLanguage) {
                var localField = { 
                    id: field.id, 
                    name: field.name, 
                    sortAsc: field.sortAsc, 
                    order: field.order, 
                    active: true 
                };
                if (field.optional) {
                    vm.optionalSortFields.push(localField);
                }
                else {
                    vm.localSortFields.push(localField);
                }
            }
        });

        //set order and sortAsc if persisted localSortFields exist for the given endpoint
        var extractedSortFields =  queryStorage.sortCriteria(vm.endpoint);
        logger.debug('SortFields retrieved: ', extractedSortFields)
        _.forEach(extractedSortFields, function (field2) {
            var field = _.find(vm.localSortFields, function (f) {
                return f.id === field2.id;
            });
            if (field) {
                field.order = field2.order;
                field.sortAsc = field2.sortAsc;
                field.active = field2.active;
            } else if (field2.active) { // if optional sort field, only show if active
                vm.localSortFields.push(field2);
                _.remove(vm.optionalSortFields,function(optField){
                    return optField.id === field2.id;
                });
            }
        });

        vm.localSortFields.sort(function (a, b) {
            return (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0;
        });

        vm.maxOrderValue = _.max(_.map(vm.localSortFields, 'order')); // maximum value of order property of localSortFields

        setOrderBy();

        vm.loaded = true;
        vm.initialized.resolve(true);
        logger.debug('SORT is loaded');
        vm.onActivated();
    }

    function doSort() {
        setOrderBy();
        logger.debug('SORT: refreshing')
        if (vm.refreshData) {
            vm.refreshData();
        }
        // persist local Sort fields after data is sorted
        queryStorage.sortCriteria(vm.endpoint, vm.localSortFields);
        logger.debug('localSortFields persisted!');
    }

    function changeOrder(field) {
        logger.debug('changing order', field.id);
        if (!field.active) {
            field.active = true;
            field.sortAsc = true;
        } else if (field.sortAsc) {
            field.sortAsc = false;
        } else {
            field.active = false;
        }
        if (!vm.delayedSorting) {
            doSort();
        }
    }

    function setOrderBy() {
        restQueryBuilder.getSingleton(vm.endpoint).clearOrderby();
        restQueryBuilder.getSingleton(vm.endpoint).clearSkip();

        vm.localSortFields.forEach(function (localField) {
            if (localField.active) {
                restQueryBuilder.getSingleton(vm.endpoint).addOrderby(localField.id, !localField.sortAsc);
            }
        });
    }

    function changeFieldOrder(fieldId, up) {
        vm.localSortFields.forEach(function (field) {
            if (field.id === fieldId) {
                var newOrder = up ? field.order - 1 : field.order + 1;
                vm.localSortFields.forEach(function (field2) {
                    if (field2.order === newOrder) {
                        if (up) { field2.order = field2.order + 1; } else { field2.order = field2.order - 1; }
                    }
                });
                field.order = newOrder;
            }
        });
        vm.localSortFields.sort(function (a, b) {
            return (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0;
        });
        if (!vm.delayedSorting) {
            doSort();
        }
    }

    function addSort(field) {
        vm.optionalSortFields = _.without(vm.optionalSortFields, field);
        vm.localSortFields.push(field);
        if (!vm.delayedSorting) {
            doSort();
        }
    }

    function clear() {
        queryStorage.clearSortCriteria(vm.endpoint);
        activate();
        vm.refreshData();
    }


    // if input changes: re-activate !
    $scope.$watch(function () {
        return vm.sortFields;
    }, function (newValue, oldValue) {
        if (angular.isDefined(newValue)) {
            logger.debug('detected non-empty change in sort input')
            activate();
        }
    });

}


(module || {}).exports = ivdSortBox;
