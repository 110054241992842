'use strict';

var mod = angular.module('ivd.authentication',
    [
        'ivd.services'
    ]);

mod.config(['$routeProvider', authenticationRoute]);


function authenticationRoute($routeProvider) {
    $routeProvider.when('/authentication', {
        templateUrl: 'ivd/authentication/authentication.html',
        controller: 'authenticationController',
        controllerAs: 'vm'
    });

    $routeProvider.when('/accountActivation', {
        templateUrl: 'ivd/authentication/activation.html',
        controller: 'activationController',
        controllerAs: 'vm'
    });
}

mod.controller('authenticationController', require('./authentication-controller.js'));
mod.controller('activationController', require('./activation-controller.js'));


require('./password-recovery');


(module || {}).exports = 'ivd.authentication';