ivdUrlField.$inject = [];

function ivdUrlField() {
    /*
        Usage:
        <ivd-email-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
    */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/email-field/email-field.html',
        controller: UrlFieldController,
        controllerAs: 'field',
        scope: {
            data: '=',
            metadata: '=',
            edit: '=',
            align: '='
        },
        bindToController: true
    };

    return directive;
}

UrlFieldController.$inject = [];

function UrlFieldController() {
    var vm = this;

    //Bindable members
    vm.placeholder = 'http://...'

    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

}

(module || {}).exports = ivdUrlField;