ivdDecimalField.$inject = [];

function ivdDecimalField() {
    /*
       Usage:
       <ivd-decimal-field data="data" meta-data="meta" edit="bool"></ivd-decimal-field>
   */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/decimal-field/decimal-field.html',
        controller: DecimalFieldController,
        controllerAs: 'field',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

DecimalFieldController.$inject = ['appConfiguration'];

function DecimalFieldController(appConfiguration) {
    var vm = this;

    //Bindable members
    vm.placeholder = (!!appConfiguration.fields_show_placeholder) ? vm.metadata.label : "";
    
    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    
}

(module || {}).exports = ivdDecimalField; 