ivdField.$inject = ['log4d'];

function ivdField(log4d) {

    // var logger = log4d.logger('ivdField');

    var directive = {
        restrict: 'E',
        controller: FieldController,
        controllerAs: 'field',
        // templateUrl: getTemplateUrl,
        templateUrl: 'ivd/ui/fields/4d-field/4d-field.html',
        scope: {
            data: '=',
            metadata: '<',
            edit: '<',
            form: '=',
            name: '=',
            linkProperty: '=',
            linkValue: '=',
            processing: '=',
            context: '<'
        },
        bindToController: true
    };
    return directive;
}

// function getTemplateUrl(element, attrs) {
//     logger.log('ivdField.getTemplateUrl:',attrs.metadata)
//     if (!attrs.metadata)
//         logger.warn('no metadata found for ivd-field in getTemplateUrl');
//     switch (attrs.metadata.type){
//         case 'text':
//             return 'ivd/ui/fields/text-field/text-field.html';
//         default:
//             logger.warn('no mapping for type =', attrs.metadata.type, 'in getTemplateUrl');
//             return 'ivd/ui/fields/text-field/text-field.html';
//     }
// }

FieldController.$inject = ['appConfiguration'];

function FieldController(appConfiguration) {

    var vm = this;
    vm.placeholder = (appConfiguration.fields_show_placeholder) ? vm.metadata.label : "";

    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

}

(module || {}).exports = ivdField;
