HtmlBoxConfig.$inject = ['$provide'];

function HtmlBoxConfig($provide) {
    // Standard textAngular toolbar, consisting of:
    //      Heading sizes, paragraph, preformatted text, quote
    //      Bold, italic, underline, strikethrough, (un)ordered list, redo, undo, clear
    //      Text align, indent, outdent
    //      Toggle html, insert: image, link, video; Wordcount, charactercount
    //  Sublists describe how the buttons will be grouped
    //
    // taOptions.toolbar = [
    // ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
    // ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
    // ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
    // ['html', 'insertImage', 'insertLink', 'insertVideo', 'wordcount', 'charcount']
    //];


    //To add a new button with new functionality to the standard toolbar
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function (taRegisterTool, taOptions) {
        //example:
        //taRegisterTool('test', {
        //    buttontext: 'Test',
        //    action: function () {
        //        alert('Test Pressed')
        //    }
        //});
        //taOptions.toolbar[1].push('test');

        taOptions.toolbar = [
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'],
        ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
        ['insertImage', 'insertLink', 'wordcount', 'charcount']
        ];

        taRegisterTool('fontSizeUp', {      // uses deprecated 'font' tag
            iconclass: 'fa fa-level-up',
            action: function () {
                var size = document.queryCommandValue('fontSize');
                size = parseInt(size) + 1;
                return this.$editor().wrapSelection('fontSize', size);
            }
        });
        taOptions.toolbar[0].push('fontSizeUp');

        taRegisterTool('fontSizeDown', {    // uses deprecated 'font' tag
            iconclass: 'fa fa-level-down',
            action: function () {
                var size = document.queryCommandValue('fontSize');
                size = parseInt(size) - 1;
                return this.$editor().wrapSelection('fontSize', size);
            }
        });
        taOptions.toolbar[0].push('fontSizeDown');

        taRegisterTool('fontSize', {
            display: '<div id="toolbarFS" style="display:block; min-width:120px;">Font Size: <span ng-bind="fs"></span></div>',
            disabled: true,
            fs: 0,
            activeState: function () {
                this.fs = document.queryCommandValue('fontSize');
            }
        });
        taOptions.toolbar[0].push('fontSize');

        taRegisterTool('fontFamily', {
            display: '<span type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">'
                   + 'Font <span class="caret"></span></span>'
                   + '<ul class="dropdown-menu"  style="position: absolute;">'
                   + '<li ng-repeat="o in options" style="font-size: {{o.css}}" ng-click="action(o.css)"><i ng-if="o.active" class="fa fa-check"></i> {{o.name}}</li></ul>',

            action: function (font) {
                if (font !== '') {
                    this.$editor().wrapSelection('fontName', font);
                }
            },
            options: [
				{ name: 'Sans-Serif', css: 'Arial, Helvetica, sans-serif' },
				{ name: 'Serif', css: "'times new roman', serif" },
				{ name: 'Wide', css: "'arial black', sans-serif" },
				{ name: 'Narrow', css: "'arial narrow', sans-serif" },
				{ name: 'Comic Sans MS', css: "'comic sans ms', sans-serif" },
				{ name: 'Courier New', css: "'courier new', monospace" },
				{ name: 'Garamond', css: 'garamond, serif' },
				{ name: 'Georgia', css: 'georgia, serif' },
				{ name: 'Tahoma', css: 'tahoma, sans-serif' },
				{ name: 'Trebuchet MS', css: "'trebuchet ms', sans-serif" },
				{ name: "Helvetica", css: "'Helvetica Neue', Helvetica, Arial, sans-serif" },
				{ name: 'Verdana', css: 'verdana, sans-serif' },
				{ name: 'Proxima Nova', css: 'proxima_nova_rgregular' }
            ],
            active: 'Font Family',
            setActive: function (font) {
                angular.forEach(this.options, function (option) {
                    if (font === option.css) {
                        this.active = option.name;
                        option['active'] = true;
                    } else {
                        option['active'] = false;
                    }
                });
            },
            getActive: function () {
                var font = document.queryCommandValue('fontName');
                if (font === '') {
                    return 'Arial, Helvetica, sans-serif';
                } else {
                    return font;
                }
            }
        });
        taOptions.toolbar[1].push('fontFamily');
        return taOptions;
    }]);
}

(module || {}).exports = HtmlBoxConfig;