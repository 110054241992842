'use strict';

angular.module('ivd.ui', [
    'ivd.filters',
    'angular-loading-bar',
    'angularFileUpload',
    'infinite-scroll',
    'textAngular',
    'ui.bootstrap',
    'ui.select',
    'ngMessages',
    require('./validation')
])

.directive('ivdGrid', require('./grid/grid-directive.js'))
.directive('ivdSearchBox', require('./search-box/search-box-directive.js'))
.directive('ivdSortBox', require('./sort-box/sort-box-directive.js'))
.directive('ivdHtmlBox', require('./html-box/html-box-directive.js'))
.directive('ivdDateTimePicker', require('./date-time-picker/date-time-picker-directive.js'))
.directive('ivdDateTimePickerTimezoneFix', require('./date-time-picker/date-time-picker-timezone-fix.js'))
.directive('ivdPeriodPicker', require('./period-picker/period-picker-directive.js'))

.directive('ivdDetailView', require('./detail-view/detail-view-directive.js'))
.factory('detailViewService', require('./detail-view/detail-view-service.js'))
.factory('detailViewChangesService', require('./detail-view/detail-view-changes-service.js'))

.directive('ivdFormattedDateBox', require('./formatted-date-box/formatted-date-box-directive.js'))
.directive('ivdQueryGrid', require('./query-grid/query-grid-directive.js'))
.directive('ivdAuthBox', require('./auth-box/auth-box-directive.js'))
.directive('ivdFileUploadBox', require('./file-upload-box/file-upload-box-directive.js'))
.directive('ivdExportButton', require('./export-button/export-button-directive.js'))

.directive('ivdFeedbackButton', require('./feedback-button/feedback-button-directive.js'))

.directive('ivdOnFinishRender', require('./on-finish-render/on-finish-render.js'))
.directive('ivdFormField', require('./form-field/form-field-directive.js'))

.component('ivdReadField', require('./read-field/read-field-component.js'))
.component('ivdMatrix', require('./matrix/matrix-component.js'))

.config(require('./html-box/html-box-config.js'))

;

// master page
require('./master-page');

// menu
require('./menu');

// fields
require ('./fields');

// footer
require('./footer');

(module || {}).exports = 'ivd.ui';
