ivdDateTimeField.$inject = [];

function ivdDateTimeField() {
    /*
        Usage:
        <ivd-text-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
    */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/date-time-field/date-time-field.html',
        controller: DateTimeFieldController,
        controllerAs: 'dateTimeField',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

DateTimeFieldController.$inject = ['objectAccess'];

function DateTimeFieldController(objectAccess) {
    var vm = this;

    //Bindable members
    vm.get = get;
    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    //Implementation
    function get(obj, str) {
        if (obj == undefined) {
            return '';
        }
        return objectAccess.getValueByString(obj, str);
    }
}

(module || {}).exports = ivdDateTimeField;