var ivdMatrix = {
    templateUrl: 'ivd/ui/matrix/matrix.html',
    controller: MatrixController,
    bindings: {
        options: '@',
        onSaved: '&'
    },
    controllerAs: 'vm'
};

MatrixController.$inject = [
    '$scope', '$q', '$parse', 'log4d', 'restQueryBuilder', 'metadataService',
    'dataService', 'detailViewService', 'queryFilter'];

function MatrixController($scope, $q, $parse, log4d, restQueryBuilder,
                          metadataService, dataService, detailViewService, queryFilter) {

    var vm = this;
    var logger = log4d.logger('matrix');

    // Options; merge them with default options
    vm.options = _.assign({
        rowMatcher: 'TextKeyId',
        columnMatcher: 'LanguageId',
        endpoints: {
            row: 'textkeys',
            column: 'languages',
            cell: 'translations'
        },
        pageSize: 10
    }, vm.options);

    // Data
    vm.data = {
        row: dataService.get(vm.options.endpoints.row),
        column: dataService.get(vm.options.endpoints.column),
        cell: dataService.get(vm.options.endpoints.cell)
    };
    vm.metadata = {
        list: {
            row: metadataService.get(vm.options.endpoints.row, 'list'),
            column: metadataService.get(vm.options.endpoints.column, 'list'),
            cell: metadataService.get(vm.options.endpoints.cell, 'list')
        },
        view: {
            row: metadataService.get(vm.options.endpoints.row, 'view'),
            column: metadataService.get(vm.options.endpoints.column, 'view'),
            cell: metadataService.get(vm.options.endpoints.cell, 'view')
        }
    };

    vm.refreshRows = refreshRows;

    // Display helper functions
    vm.findCell = findCell;
    vm.isFieldInContext = isFieldInContext;

    // Cell state
    vm.editing = {}; // the trick with JavaScript 'sparse matrices'
    vm.toggleEditing = toggleEditing;

    vm.columnVisibility = {};
    vm.toggleColumnVisibility = toggleColumnVisibility;

    // Editing data
    vm.create = create;
    var contexts = {};
    vm.getContext = getContext;

    // Infinite scrolling
    vm.infiniteScroll = {
        enabled: false,
        isLoadingMore: false,
        top: vm.pageSize,
        skip: 0,
        loadMore: loadMore
    };

    // Search & Sort
    vm.showSearchBox = false;
    vm.searchBoxIsOpen = true;
    vm.toggleSearchBox = toggleSearchBox;

    vm.showSortBox = false;
    vm.sortBoxIsOpen = true;
    vm.toggleSortBox = toggleSortBox;

    activate();

    function activate() {
        restQueryBuilder.getSingleton(vm.options.endpoints.row).addExpand(vm.options.endpoints.cell);
        // Rows and cells will be pulled in by infiniteScoll, but we'll force
        // queue a refresh for the columns here
        dataService.refresh(vm.options.endpoints.column);

        // We have to wait until sort & search boxes are initialized, becuase
        // they set values on the query builder. Only then can we start loading
        // data.
        // Potential improvement: sort & search client-side as well
        $q.all([
            vm.sortInitialized
        ]).then(function () {
            vm.infiniteScroll.enabled = true;
        });
    }

    function loadMore() {
        if (vm.infiniteScroll.isLoadingMore || !vm.infiniteScroll.enabled) {
            return $q.resolve(null);
        }

        vm.infiniteScroll.isLoadingMore = true;

        var currentQuery = _.clone(restQueryBuilder.getSingleton(vm.options.endpoints.row));
        currentQuery.setTop(vm.infiniteScroll.top);
        currentQuery.setSkip(vm.infiniteScroll.skip);

        // Start fetching new data
        return dataService.add(vm.options.endpoints.row, currentQuery.getQueryParameters())
            .then(function (data) {
                // Update the relative query
                vm.infiniteScroll.skip += _.size(data);

                // Update the singleton query, used when refreshing *all* data
                restQueryBuilder.getSingleton(vm.options.endpoints.row).setSkip(0);
                restQueryBuilder.getSingleton(vm.options.endpoints.row).setTop(vm.infiniteScroll.skip);

                vm.infiniteScroll.isLoadingMore = false;

                // Re-check scroll position: the list might not have been
                // populated until the bottom of the screen
                if (_.size(data) > 0) {
                    $scope.$emit('infinitescroll:check');
                }
            });
    }

    function findCell(row, column) {
        if (!row || !column) {
            return undefined;
        }

        return _.find(vm.data.cell, function (cell) {
            return cell
                && cell[vm.options.columnMatcher] === column.Id
                && cell[vm.options.rowMatcher] === row.Id;
        });
    }

    function toggleColumnVisibility(column) {
        vm.columnVisibility[column.Id] = !vm.columnVisibility[column.Id];
    }

    function toggleEditing(i, j, cell) {
        if (angular.isUndefined(vm.editing[i])) {
            vm.editing[i] = {};
        }
        if (vm.editing[i][j]) {
            saveCell(cell);
        }

        vm.editing[i][j] = !vm.editing[i][j];
    }

    function saveCell(cell) {
        return dataService
            .save(vm.options.endpoints.cell, cell)
            .then(function (data) {
                vm.onSaved({data: data});
                return data;
            });
    }

    function create(type, row, column) {
        return detailViewService.create({
            endpoint: vm.options.endpoints[type],
            context: (row && column) ? getContext(row, column) : null
        }).then(function (modal) {
            return modal.close.then(vm.onSaved);
        });
    }

    // We have to memoize this function because it gets called every $digest
    // cycle (when row or column changes). If we don't memoize and return a new
    // object every time, Angular will think it's different from the previous
    // one and trigger another $digest. Infinite loops are not cool :( So the
    // solution is to return a *reference* to the same object every time, and
    // just change (not replace) the object as necessary.
    function getContext(row, column) {
        if (!contexts[row.Id]) {
            contexts[row.Id] = {};
        }

        if (!contexts[row.Id][column.Id]) {
            contexts[row.Id][column.Id] = {};
        }

        var context = contexts[row.Id][column.Id];
        context[vm.metadata.view.row.singularName] = row;
        context[vm.metadata.view.column.singularName] = column;

        return context;
    }

    function isFieldInContext(field, row, column) {
        var context = getContext(row, column);
        return $parse(field.data)(context);
    }

    function toggleSearchBox() {
        vm.showSearchBox = !vm.showSearchBox;
    }

    function toggleSortBox() {
        vm.showSortBox = !vm.showSortBox;
    }

    function refreshRows() {
        dataService.refresh(vm.options.endpoints.row);
    }

}

(module || {}).exports = ivdMatrix;
