/**
 * @file stopwatch.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

// see https://masteringmean.com/lessons/429-Building-a-Stopwatch-Directive

Stopwatch.$inject = ['$interval'];

/**
 * An Angular service which delivers stopwatch features. 
 * Useful for debugging and profiling scenarios.
 * 
 * Example:
 * ```javascript
 * var stopwatch = new Stopwatch();
 * stopwatch.start();
 * ...
 * console.debug(moment(stopwatch.stop()).format('mm:ss.SSS'));
 * ```
 * @namespace 
 */
function Stopwatch($interval) {

    return function (options) {

        var startTime = 0,
            currentTime = null,
            offset = 0,
            interval = null;
          
        options = options || {};
        if (!options.interval) {
            options.interval = 100;
        }
                        
        options.elapsedTime = new Date(0);

        var self = {
            running: false,
            start: startTimer,
            stop: stopTimer,
            reset: resetTimer,
            cancel: cancelTimer,
            updateTime: updateTime
        };
        return self;

        function pushToLog(lap) {
            if (options.log !== undefined) {
                options.log.push(lap);
            }
        }

        function updateTime() {
            currentTime = new Date().getTime();
            var timeElapsed = offset + (currentTime - startTime);
            options.elapsedTime.setTime(timeElapsed);
        };

        function startTimer() {
            if (self.running === false) {
                startTime = new Date().getTime();
                interval = $interval(self.updateTime, options.interval);
                self.running = true;
            }
        };

        function stopTimer() {
            if (self.running === false) {
                return;
            }
            self.updateTime();
            offset = offset + currentTime - startTime;
            pushToLog(currentTime - startTime);
            $interval.cancel(interval);
            self.running = false;
            return options.elapsedTime;
        };

        function resetTimer() {
            startTime = new Date().getTime();
            options.elapsedTime.setTime(0);
            offset = 0;
        };

        function cancelTimer() {
            $interval.cancel(interval);
        };

    };

}

(module || {}).exports = Stopwatch;