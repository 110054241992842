function ivdDateTimeValidator() {

    var service = {};
    var datePattern = /^\s*([1-2][0-9]|30|31|0?[1-9])\s*(\/|-|\s)\s*(10|11|12|0?[1-9])\s*(\/|-|\s)\s*(\d{4})\s*$/;
    var timePattern = /^\s*(1[0-9]|20|21|22|23|0?[0-9])\s*(:|\s|\.|,|u|h)\s*([0-5][0-9])$/;
    var dateTimePattern = /^\s*([1-2][0-9]|30|31|0?[1-9])\s*(\/|-|\s)\s*(10|11|12|0?[1-9])\s*(\/|-|\s)\s*(\d{4})\s*(1[0-9]|20|21|22|23|0?[0-9])\s*(:|\s|\.|,|u|h)\s*([0-5][0-9])$/;
    var dayPattern = /^\s*([1-2][0-9]|30|31|0?[1-9])\s*$/;
    var dayMonthPattern = /^\s*([1-2][0-9]|30|31|0?[1-9])\s*(\/|-|\s)\s*(10|11|12|0?[1-9])\s*$/;
    var shortcutPattern = /^\s*([\-\+])\s*(\d+)\s*([mqwy])\s*$/;
    var shortcutDayPattern = /^\s*([\-\+])\s*(\d+)\s*$/;
    // See if a certain date is valid
    // Code taken from http://webrewrite.com/validate-date-format-yyyymmdd-javascript/

    service.validateDateFormat = validateDateFormat;

    return service;

    function validateDateFormat (dateVal, format) {

        // prevents temporary invalidation while typing e.g. "21/" or "8-9-"
        //dateVal = (''+dateVal).replace("/", " ").replace("/", " ").replace("-", " ").replace("-", " ");
        // RB: commented out -> this broke the " -x" mechanism for dates in the past

        var obj = {
            isValid: false,
            newDate: undefined
        };
        switch (format) {
            case "dd/MM/yyyy":
                if (dateVal) {
                    if (dateVal === ' ') {
                        obj.isValid = true;
                        obj.newDate = moment.utc().toDate();
                    } else if (dayPattern.test(dateVal)) {
                        obj.isValid = true;
                        obj.newDate = moment.utc().startOf('month')
                                              .add(Math.min(parseInt(dateVal), moment.utc().endOf('month').date()) - 1, 'd')
                                              .toDate();
                    } else if (dayMonthPattern.test(dateVal)) {
                        var dayAndMonth = dateVal.match(dayMonthPattern);
                        obj.isValid = true;
                        obj.newDate = moment.utc().startOf('year')
                                              .add(parseInt(dayAndMonth[3]) - 1, 'M')
                                              .add(Math.min(
                                                    parseInt(dayAndMonth[1]),
                                                    moment.utc().startOf('year').add(parseInt(dayAndMonth[3]) - 1, 'M').endOf('month').date()) - 1
                                                , 'd')
                                              .toDate();
                    } else if (shortcutPattern.test(dateVal)) {
                        var shortcutValues = dateVal.match(shortcutPattern);
                        var numberOfUnits = shortcutValues[2];
                        if (shortcutValues[1] === "-") {
                            numberOfUnits = -numberOfUnits;
                        }
                        obj.isValid = true;
                        switch (shortcutValues[3]) {
                            case "w":
                                obj.newDate = moment.utc().add(numberOfUnits, 'w').toDate();
                                break;
                            case "m":
                                obj.newDate = moment.utc().add(numberOfUnits, 'M').toDate();

                                break;
                            case "q":
                                obj.newDate = moment.utc().add(3 * numberOfUnits, 'M').toDate();
                                break;
                            case "y":
                                obj.newDate = moment.utc().add(numberOfUnits, 'y').toDate();
                                break;
                            default:
                                break;
                        }
                    } else if (shortcutDayPattern.test(dateVal)) {
                        var shortcutValues = dateVal.match(shortcutDayPattern);
                        var numberOfUnits = shortcutValues[2];
                        if (shortcutValues[1] === "-") {
                            numberOfUnits = -numberOfUnits;
                        }
                        obj.isValid = true;
                        obj.newDate = moment.utc().add(numberOfUnits, 'd').toDate();
                    } else if (datePattern.test(dateVal)) {
                        var fullDate = dateVal.match(datePattern);
                        obj.isValid = true;
                        obj.newDate = moment.utc().add(parseInt(fullDate[5]) - moment.utc().year(), 'y')
                                              .startOf('year')
                                              .add(parseInt(fullDate[3]) - 1, 'M')
                                              .add(Math.min(
                                                    parseInt(fullDate[1]),
                                                    moment.utc().add(parseInt(fullDate[5]) - moment.utc().year(), 'y')
                                                            .startOf('year')
                                                            .add(parseInt(fullDate[3]) - 1, 'M')
                                                            .endOf('month').date()) - 1
                                                , 'd')
                                              .toDate();
                    }
                }
                break;
            case "dd/MM/yyyy HH:mm":
                if (dateVal) {
                    if (dateTimePattern.test(dateVal)) {
                        var fullDate = dateVal.match(dateTimePattern);
                        obj.isValid = true;
                        obj.newDate = moment.utc([fullDate[5], fullDate[3] - 1, fullDate[1], fullDate[6], fullDate[8]]).toDate();
                    }
                }
                break;
            case "HH:mm":
                if (dateVal) {
                    if (timePattern.test(dateVal)) {
                        var fullDate = dateVal.match(timePattern);
                        obj.isValid = true;
                        obj.newDate = moment.utc([1970, 0, 1, fullDate[1], fullDate[3]]).toDate();
                    }
                }
                break;
            default:
                break;
        }
        return obj;
    }


    // logica is verhuisd naar validateDateFormat
    function validateDateTimeFormat(dateTimeVal) {
        if (dateTimeVal == null || dateTimeVal == undefined)
            return false;

        // prevents temporary invalidation while typing e.g. "21/" or "8-9-"
        dateTimeVal = (''+dateTimeVal).replace("/", " ").replace("/", " ").replace("-", " ").replace("-", " ");

        var dateTimeValues = dateTimeVal.match(validDateTimePattern);
        if (dateTimeValues == null)
            return false;

        var dtYear = dateTimeValues[5],
            dtMonth = dateTimeValues[3],
            dtDay = dateTimeValues[1];
        var dtHour = dateTimeValues[7],
            dtMinute = dateTimeValues[9];

        if (dtMonth < 1 || dtMonth > 12)
            return false;
        if (dtDay < 1 || dtDay > 31)
            return false;
        if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
            return false;
        if (dtMonth == 2) {
            var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
            if (dtDay > 29 || (dtDay == 29 && !isleap))
                return false;
        }
        else if (dtHour < 0 || dtHour > 23) return false;
        else if (dtMinute < 0 || dtMinute > 59) return false;

        return true;
    }

    // logica is verhuisd naar validateDateFormat
    function validateTimeFormat (timeVal) {
        if (timeVal == null || timeVal == undefined)
            return false;
        var timeValues = timeVal.match(validTimePattern);
        if (timeValues == null)
            return false;

        var dtHour = timeValues[1],
            dtMinute = timeValues[3];

        if (dtHour < 0 || dtHour > 23) return false;
        else if (dtMinute < 0 || dtMinute > 59) return false;

        return true;
    }
}

(module || {}).exports = ivdDateTimeValidator;