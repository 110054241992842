/**
 * @file index.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

'use strict';

/**
 * The 'base' module does not only contain Angular injectables,
 * instead it also provides base classes (to be inherited) and
 * helper classes providing basic functionalities.
 * 
 * They are meant to be used from anywhere and be accessed from
 * the globally accessible 'ivd' variable. 
 * This variable is also assigned to the Angular constant 'ivd'.
 * 
 * Available classes:
 * - ivd.BaseEntityController
 * - ivd.CircularArray
 * @module 'ivd.base' 
 */

var ivd = require('../ivd-namespace.js');
ivd.BaseEntityController = require('./base-entity-controller.js');
ivd.CircularArray = require('./circular-array.js');

var mod = angular.module('ivd.base', [])
.constant('ivd', ivd);

(module || {}).exports = 'ivd.base';
