var DetailViewController = require('./detail-view-controller.js');

detailViewService.$inject = ['$uibModal', '$route', '$rootScope', 'log4d', '$log', 'restService'];

function detailViewService($uibModal, $route, $rootScope, log4d, $log, restService) {

    var logger = log4d.logger('detailView');

    return {
        open: open,
        create: create
    };

    function open(options) {
        //This is for the modal instance (i.e. When the modal is open)
        var modalInstance = $uibModal.open({
            animation: options.animationsEnabled,
            size: 'lg',
            backdropClass: 'animated',
            windowClass: 'animated',
            templateUrl: 'ivd/ui/detail-view/detail-view-modal.html',
            controller: DetailViewController,
            controllerAs: 'vm',
            bindToController: true, //Bind this scope to the controller's scope
            resolve: { //Pass the given ngModel to the controller of the modal
                data: function () {
                    return options.data;// || false;
                },
                metadata: function () {
                    return options.metadata;
                },
                id: function () {
                    return options.id || options.data.Id;
                },
                endpoint: function () {
                    return options.endpoint;
                },
                mode: function () {
                    return options.mode || 'view';
                },
                fixedValues: function () {
                    return options.fixedValues;
                },
                refreshList: function () {
                    return options.refreshList;
                },
                actionAfterCreated: function () {
                    return options.actionAfterCreated; // function or 'view'/'next'/'list'/'close'
                },
                customFunctions: function () {
                    return options.customFunctions;
                },
                context: function () {
                    return options.context || {};
                },
                number: function () {
                    return options.number || 'false';
                },
                ctnweight: function () {
                    return options.ctnweight || 'false';
                }
            },
            backdrop: 'static'
        });

        var closeEvents = {};

        closeEvents.close = modalInstance.result.then(function (result) {
            logger.debug('Close -> Endpoint is: ', options.endpoint);
            logger.debug('Passed refreshList funcion object: ', options.refreshList);
            if (angular.isFunction(options.refreshList)) {
                options.refreshList(options.endpoint);
            }

            return result;
        });

        closeEvents.dismiss = modalInstance.result.catch(function () {
            //Do stuff with respect to dismissal

            logger.debug('Dismissal -> Endpoint is: ', options.endpoint);
            logger.debug('Passed refreshList funcion object: ', options.refreshList);

            if (angular.isFunction(options.refreshList)) {
                options.refreshList(options.endpoint);
            }
        });

        return closeEvents;

    }

    /**
     * Opens a modal to create an entity for the given endpoint.
     * `options.endpoint` is required.
     */
    function create(options) {
        _.assign(options, defaultOptions);
        _.assign(options, {
            id: options.id || options.data ? (options.data.Id || options.data.id) : undefined,
            mode: 'create'
        });

        return restService.getNew(options.endpoint).then(function (prefilled) {
            _.assign(options, {data: prefilled});
            return open(options);
        });
    }

    /**
     * Opens a modal to edit an entity for the given endpoint.
     * `options.endpoint` and `options.data` are required.
     */
    function edit(options) {
        _.assign(options, defaultOptions);
        _.assign(options, {
            id: options.id || options.data ? (options.data.Id || options.data.id) : undefined,
            mode: 'edit'
        });

        return open(options);
    }

    var defaultOptions = {
        animationsEnabled: true
    };

}

(module || {}).exports = detailViewService;
