var ivd = require('../ivd-namespace.js');

var mod = angular.module('ivd.translate',
    [
        'pascalprecht.translate'   // angular-translate
    ]);

mod.config(['$translateProvider', function ($translateProvider) {
    $translateProvider
        .useStaticFilesLoader({
            prefix: 'data/locale-',
            suffix: '.json'
        })
        .preferredLanguage('en')
        .useSanitizeValueStrategy('escaped')
        //.useMissingTranslationHandlerLog();
}]);

(module || {}).exports = 'ivd.translate';
