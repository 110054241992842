//Inject necessary dependencies into the controller
DetailViewController.$inject = ['$scope', '$q', '$uibModalInstance', '$route', '$routeParams',
                                'restService', '$timeout', 'restQueryBuilder', '$injector',
                                'metadataService', 'log4d', 'detailViewService',
                                'data', 'metadata', 'id', 'endpoint', 'mode', 'fixedValues',
                                'actionAfterCreated', 'customFunctions', 'context',
                                'jwtTokenLocalReadService', '$parse', '$translate',
                                'appConfiguration', 'dataService', 'detailViewChangesService', '$rootScope', 'ivdUtils', 'number', 'ctnweight'];

function DetailViewController($scope, $q, $uibModalInstance, $route, $routeParams,
                              restService, $timeout, restQueryBuilder, $injector,
                              metadataService, log4d, detailViewService,
                              data, metadata, id, endpoint, mode, fixedValues,
                              actionAfterCreated, customFunctions, context,
                              jwtTokenLocalReadService, $parse, $translate,
                              appConfiguration, dataService, detailViewChangesService, $rootScope, ivdUtils, number, ctnweight) {

    var logger = log4d.logger('detailView');

    var vm = this;

    //Bindable members
    // -- functions
    vm.editMode = editMode;
    vm.remove = remove;
    vm.create = create;
    vm.edit = edit;
    vm.createNew = createNew;
    vm.discard = discard;
    vm.close = close;
    vm.save = save;
    vm.showList = showList;
    vm.showDefault = showDefault;
    vm.defaultViewIsActive = defaultViewIsActive;
    vm.loadData = loadData;
    vm.getProperyValue = getProperyValue;
    // -- variables
    vm.processingRequest = false;
    vm.fieldOperationsInProgress = {};
    vm.processingFieldOperation = false;
    vm.allowEdit = false;
    vm.number = number;
    vm.ctnweight = ctnweight;

    // parameters received from detailViewService.open through 'resolve':
    var originalData = angular.copy(data);
    vm.data = originalData;
    vm.endpoint = endpoint;
    vm.mode = mode;
    vm.metadata = undefined;
    vm.fixedValues = fixedValues || [];
    vm.customFunctions = customFunctions;
    vm.context = context;
    vm.currentContext = context;
    vm.isFieldInContext = isFieldInContext;
    vm.setActivated = setActivated;
    vm.weightVolumeRatio = 0;

    var userlang = jwtTokenLocalReadService.getUserLanguage();

    var DEFAULT_PAGE = '_default_page_';
    var initialMode = undefined;

    //Init
    activate();

    function loadViewData() {
        vm.processingRequest = true;
        var restQuery = restQueryBuilder.getScoped(vm.endpoint);
        restQuery.setTop(1);
        restQuery.addFilter('id', vm.id);

        _.each(vm.metadata.fields, function (field) {
            // Set fixed values
            var fixedValue = vm.fixedValues[field.data];
            if (fixedValue) {
                field.readOnly = true;
                vm.data[field.data] = fixedValue;
            }

            fixedValue = $parse(field.data)(vm.context);
            if (fixedValue) {
                field.readOnly = true;
                vm.data[field.data] = fixedValue;

                // For pre-filled object Type = {...}, fill in the foreign key TypeId = Type.Id
                if (field.type === 'selectbox') {
                    vm.data[field.id] = vm.context[field.data][field.selectbox_id];
                }
            }

            setInvisibleIfTextAndLanguageRestricted(field);
            if (field.visible && field.forUserLanguage) {
                if (field.type === 'selectbox') {
                    restQuery.addExpand(field.data);
                } else if (field.type === 'file') {
                    restQuery.addExpand(field.data);
                }
            }
            if (field.currencyProvider) {
                var propertytoExpand = field.currencyProvider.substring(0, field.currencyProvider.lastIndexOf('.'));
                restQuery.addExpand(propertytoExpand);
            }
        });
        if (vm.endpoint.indexOf('file/') > -1) {
            vm.allowEdit = false;
        }
        else {
            vm.allowEdit = true;
        }

        restService.get(vm.endpoint, id, restQuery.getQueryParameters()).then(function (viewData) {
            vm.data = viewData;
            setContextualViewMetadata();
            vm.originalData = _.cloneDeep(vm.data);
            vm.processingRequest = false;
        });

    }

    function loadDeleteData() {
        vm.data = {
            Id: vm.id
        };
    }

    function activate() {

        if (!actionAfterCreated) {
            actionAfterCreated = appConfiguration.defaultActionAfterCreated;
        }

        let aName = 'WeightVolumeRatio';
        restService.query("ctns/sysparams", { name: aName })
            .then(function (data) {
                vm.weightVolumeRatio = data[0];
            }, function (reason) {
                console.log("Failed getting sysparam " + aName + " with " + reason);
            });

        vm.currentView = DEFAULT_PAGE;
        vm.defaultEndpoint = vm.endpoint;
        vm.id = id;
        
        // Safe merge removes $promise from context, prevents to much recursion when merging directly with angular
        ivdUtils.safeMerge(vm.data, vm.context);
        // Prevent edits to persist to the rest of the application. This should
        // only happen on save.
        vm.data = ivdUtils.cleanObject(vm.data);        

        if (metadata) {
             //logger.info('Metadata passed to detail-view-controller: ', metadata);
            vm.metadata = angular.copy(metadata);           
            continueActivateAfterMetadataIsLoaded();            
        }
        else {        
            // TODO Move the `require` to the functions that actually need it.
            // This is kind of a dirty hack
            metadataService.require(vm.endpoint, vm.mode).then(function (md) {
                //logger.info('Metadata retrieved by detail-view-controller: ', md);
                vm.metadata = angular.copy(md);                
                continueActivateAfterMetadataIsLoaded();                
            });
        }
    }

    function continueActivateAfterMetadataIsLoaded() {

        initialMode = vm.mode = vm.mode || 'view';
        switch (vm.mode) {
            case 'view':
            case 'edit':
                loadViewData();
                break;
            case 'create':
                prepareCreateData();
                break;
            case 'remove':
                loadDeleteData();
                break;
        }

        // Set field processing status (used for fileupload fields to indicate upload is in progress => no 'save' allowed)
        vm.fieldOperationsInProgress = {};
        _.each(vm.metadata.fields, function (field) {
            vm.fieldOperationsInProgress[field.label] = field.processing || false;
        });
    }

    function setContextualViewMetadata() {
        _.each(vm.metadata.fields, function (header) {
            if (header.currencyProvider) {
                header.currency = $parse(header.currencyProvider)(vm.data);
            }
        });
    }

    function setInvisibleIfTextAndLanguageRestricted(field) {
        // TGH 2016-04-14 show all in view & edit & create if field is purely text
        field.forUserLanguage = true;
        if (field.type !== 'text' && field.languageRestrictions &&
                field.languageRestrictions.length > 0 && field.languageRestrictions.indexOf(userlang) === -1) {
            field.forUserLanguage = false;
        }
    }

    function prepareCreateData() {
        metadataService.require(vm.endpoint, 'create')
            .then(function (md) {

                // Reset metadata   WHY????? Die werd net geladen tijdens het activeren (Gijs) en wordt enkel aangeroepen na activatie
                // angular.copy(md, vm.metadata);
                if (!vm.metadata) { angular.copy(md, vm.metadata); }

                // `originalData` has the prefilled data from the server
                vm.data = angular.copy(originalData);
                //logger.info('Original data for create: ', originalData);

                var fixedValueKeys = _.keys(vm.fixedValues);

                _.each(vm.metadata.fields, function (field) {
                    // Fill fixed value from `vm.fixedValues`
                    var fixedValue = vm.fixedValues[field.data];
                    if (fixedValue) {
                        field.readOnly = true;
                        vm.data[field.data] = fixedValue;
                    }

                    // Fill fixed value from context
                    fixedValue = $parse(field.data)(vm.context);
                    if (fixedValue) {
                        field.readOnly = true;
                        vm.data[field.data] = fixedValue;

                        // For pre-filled object Type = {...}, fill in the foreign key TypeId = Type.Id
                        if (field.type === 'selectbox') {
                            vm.data[field.id] = vm.context[field.data][field.selectbox_id];
                        }
                    }

                    setInvisibleIfTextAndLanguageRestricted(field);
                });
                //logger.info('Metadata for create after preparation: ', vm.metadata);

                vm.allowEdit = true;
                setContextualViewMetadata();
            });
    }

    function editMode() {
        return vm.mode === 'edit' || vm.mode === 'create';
    }

    function showDefault() {
        vm.currentView = DEFAULT_PAGE;
        vm.list = {};
        vm.view = {};
        vm.endpoint = vm.defaultEndpoint;
        delete vm.parentLink;
    }

    function defaultViewIsActive() {
        return vm.currentView === DEFAULT_PAGE;
    }

    function showList(list) {
        vm.searchLoaded = false;
        vm.sortLoaded = false;
        vm.currentView = list;

        //FOLLOWING 4 LINES ADDED
        vm.parentLink = {};
        // vm.parentLink[vm.currentView.link] = vm.id;
        // vm.parentLink[vm.currentView.navigationalProperty] = vm.data;
        vm.parentLink[vm.currentView.navigationalProperty.name] = vm.id;
        vm.currentContext = angular.copy(vm.context || {});
        vm.currentContext[vm.currentView.navigationalProperty.name] = vm.data;

        vm.list = {};  // to attach metadata
        vm.view = {};  // to attach metadata
        vm.endpoint = list.name;

        vm.list.metadata = metadataService.get(vm.endpoint, 'list');
        vm.view.metadata = metadataService.get(vm.endpoint, 'view');

    }

    /**
     * This loads data for the current sublist.
     */
    function loadData() {
        if (!vm.defaultViewIsActive()) {
            logger.debug('DETAIL-VIEW == loading data from detail view service');
            //FOLLOWING LINE REMOVED
            //restQueryBuilder.getSingleton(vm.endpoint).addFilter(vm.currentView.link, vm.id);
            restQueryBuilder.getSingleton(vm.endpoint).setTop(10);
            restService.query(vm.endpoint, restQueryBuilder.getSingleton(vm.endpoint).getQueryParameters())
                .then(function (result) {
                    vm.list.data = result;
                });
        }
    }

    var activated = {};
    function setActivated(type) {
        activated[type] = true;
        if (activated.sort && activated.search && activated.metadata) {
            vm.loadData();
        }
    }


    // for list
    function createNew() {
        restService.getNew(vm.endpoint).then(function (prefilled) {
            detailViewService.open({
                data: prefilled,
                endpoint: vm.endpoint,
                mode: 'create',
                // fixedValues: vm.parentLink,
                context: vm.currentContext
            });
        });
    }

    function discard() {
        if (initialMode === 'view') {
            vm.mode = 'view';
        } else {
            close();
        }
    }

    function close() {
        $uibModalInstance.close();
        //goToOverview();
    }

    function remove() {
        vm.processingRequest = true;
        restService.remove(vm.endpoint, vm.data)
            .then(function () {
                dataService.setDeleted(vm.endpoint, vm.data);
                $uibModalInstance.close();
            }).finally(function () {
                vm.processingRequest = false;
            });
    }

    var tmp = {};

    function create(confirmed = false) {
        if (!confirmed && vm.metadata.confirmFunc) {
            vm.metadata.confirmFunc(vm.data, vm.create);
            return;
        }
        vm.processingRequest = true;
        vm.errorMessage = '';

        var newData = angular.copy(vm.data);

        _.each(vm.metadata.fields, function (field) {
            if (field.type === 'selectbox') { 
               
                if (newData[field.data]) {
                    newData[field.id] = newData[field.data][field.selectbox_id] || newData[field.data].id;
                    //keep local data in tmp
                    tmp[field.data] = newData[field.data];
                }

                newData[field.data] = null;
               
            }
            else if (field.type === 'file') {
                newData[field.data + 'Guid'] = newData[field.data] ? newData[field.data][field.id] : null;
                newData[field.data] = null;
            }
        });

        var isMultiFileUpload = vm.endpoint.indexOf('file/') > -1;
        if (isMultiFileUpload) {
            vm.endpoint = vm.endpoint + '/multiple';
            newData.Attachments = vm.data.Attachment;
            newData.Attachment = null;
        }

        restService.add(vm.endpoint, newData)
            .then(function (newEntity) {
                vm.data = dataService.setSaved(vm.endpoint, newEntity);

                if (isMultiFileUpload) {
                    $uibModalInstance.close();
                }

                else {
                    if (angular.isFunction(actionAfterCreated)) {
                        actionAfterCreated(newEntity.Id || newEntity.id, $uibModalInstance);
                    } else if (actionAfterCreated === 'view') {
                        vm.mode = 'view';
                    } else if (actionAfterCreated === 'next') {
                        vm.data = null;
                        vm.form.$setPristine(); // otherwise client-side validation will be triggered
                        vm.mode = 'create';
                    } else if (actionAfterCreated === 'close') {
                        $uibModalInstance.close();
                    } else {
                        vm.mode = 'view';
                    }
                }
            }, function (error) {
                vm.errorMessage = '';
                if (angular.isArray(error.data)) {
                    _.each(error.data, function (msgObject) {
                        $translate([msgObject.Message]).then(function (result) {
                            vm.errorMessage += result[msgObject.Message] + '\n';
                            if (msgObject.Message == 'BUSINESS_RULE_DENSITY') {
                                vm.errorMessage = vm.errorMessage.replace('{0}', vm.weightVolumeRatio);
                            }
                        });
                    });
                } else {
                    vm.errorMessage = 'Exception: ' + (error.data ? error.data + ' ' : '')
                        + error.status + ' ' + error.statusText;
                }
            }).finally(function () {
                vm.processingRequest = false;
                //restore tmp to data
                _.each(vm.metadata.fields, function(field) {
                    if (field.type === 'selectbox') {
                        // vm.data[field.data] = tmp[field.data];
                        // tmp[field.data] = null;
                        vm.data[field.data] = tmp[field.data];
                        if (tmp[field.data].id) {
                            vm.data[field.data][field.selectbox_id] = tmp[field.data].id;
                        }
                        if (tmp[field.data].text) {
                            vm.data[field.data][field.selectbox_text] = tmp[field.data].text;
                        }
                        tmp[field.data] = null;
                    }
                });
            });
    }

    function edit() {
        vm.mode = 'edit';
        vm.originalData = _.cloneDeep(vm.data);
    }

    function save(confirmed = false) {
        if (!confirmed && vm.metadata.confirmFunc) {
            vm.metadata.confirmFunc(vm.data, vm.save);
            return;
        }
        vm.processingRequest = true;
        _.each(vm.metadata.fields, function (field) {
            if (field.type === 'selectbox') {
                if (vm.data[field.data]) {
                    vm.data[field.id] = vm.data[field.data][field.selectbox_id] || vm.data[field.data].id;
                    //keep local data in tmp
                    tmp[field.data] = vm.data[field.data];
                }

                vm.data[field.data] = null;
            }

            else if (field.type === 'file') {
                // remove old file, update record to refer to new file
                if (vm.data[field.data + 'Guid'] &&
                    vm.originalData[field.data + 'Guid'] !== vm.data[field.data + 'Guid']) {
                    var entity = { Guid: vm.data[field.data + 'Guid'] };
                    restService.removeAttachment(field.endpoint, entity);
                }
                vm.data[field.data + 'Guid'] = vm.data[field.data] ? vm.data[field.data][field.id] : null;
            }
        });

        restService.update(vm.endpoint, vm.data)
            .then(function (updatedData) {
                dataService.setSaved(vm.endpoint, updatedData);
                loadViewData();
                if (initialMode === 'view') {
                    vm.mode = 'view';
                } else {
                    close();
                }
            })
            .catch(function (error) {
                vm.errorMessage = '';
                if (angular.isArray(error.data)) {
                    _.each(error.data, function (msgObject) {
                        $translate([msgObject.Message]).then(function (result) {
                            vm.errorMessage += result[msgObject.Message] + '\n';
                            if (msgObject.Message == 'BUSINESS_RULE_DENSITY') {
                                vm.errorMessage = vm.errorMessage.replace('{0}', vm.weightVolumeRatio);
                            }
                        });
                    });
                } else {
                    vm.errorMessage = 'Exception: '
                        + (error.data ? error.data + ' ' : '')
                        + error.status + ' ' + error.statusText;
                }
            })
            .finally(function () {
                vm.processingRequest = false;
                //restore tmp to data
                _.each(vm.metadata.fields, function(field) {
                    if (field.type === 'selectbox' && tmp[field.data]) {
                        vm.data[field.data] = tmp[field.data];
                        if (tmp[field.data].id) {
                            vm.data[field.data][field.selectbox_id] = tmp[field.data].id;
                        }
                        if (tmp[field.data].text) {
                            vm.data[field.data][field.selectbox_text] = tmp[field.data].text;
                        }
                        tmp[field.data] = null;
                    }
                });
            });
    }

    // this will listen for route changes and call the callback
    $scope.$on('$routeChangeStart', function () {
        $uibModalInstance.close();
    });

    function goToOverview() {
        $route.updateParams({ id: null });
    }

    function getProperyValue(propertyName) {
        return $parse(propertyName)(vm.data);
    }

    function isFieldInContext(field) {
        return $parse(field.data)(vm.context);
    }

    $scope.$watch('vm.fieldOperationsInProgress', function (newValue, oldValue) {
        if (newValue) {
            var processing = false;
            for (var property in newValue) {
                if (newValue.hasOwnProperty(property)) {
                    processing = processing || newValue[property];
                }
            }
            vm.processingFieldOperation = processing;
        }
    }, true);

    _.forEach(_.filter(detailViewChangesService.subscriptions, { endpoint: vm.endpoint }),
        function (subscription) {
            if (subscription) {
                $scope.$watch(function () {
                    return vm.data[subscription.property];
                }, function (newValue, oldValue) {
                    $rootScope.$broadcast(subscription.eventName, newValue, vm.data);
                });
            }
        }
    );
}

(module || {}).exports = DetailViewController;
