/**
 * @file index.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

'use strict';

/**
 * A module bundling all general purpose services.
 * 
 * **Services:**
 * - restService
 * - dataService
 * - restQueryBuilder
 * - ivdUtils
 * - objectAccess
 * - creator
 * - jsonDateParser
 * - timeboxSelectBoxService
 * - authenticationService
 * - authenticationTokenPersistenceService
 * - authenticationInterceptorService
 * - metadataService
 * - generalMetadataService
 * - storage
 * - Stopwatch
 * - jwtTokenLocalReadService
 * - queryStorage
 * - query
 * 
 * **Configured on load:**
 * - appConfigurationProvider
 * - $httpProvider by pushing authenticationInterceptorService as an interceptor
 * @module ivd.services
 * @requires ui.bootstrap
 */
angular.module('ivd.services', ['ui.bootstrap'])

.factory('restService', require('./rest-service.js'))
.factory('dataService', require('./data-service.js'))
.factory('restQueryBuilder', require('./rest-query-builder.js'))
.factory('ivdUtils', require('./ivd-utils-service.js'))

.factory('objectAccess', require('./object-access-service.js'))
.factory('creator', require('./creator/creator-service.js'))
.factory('jsonDateParser', require('./json-date-parser.js'))

.factory('timeboxSelectBoxService', require('./select-box-rest-services/timebox-select-box-service.js'))

.factory('authenticationService', require('./authentication-service.js')) 
.factory('authenticationTokenPersistenceService', require('./authentication-token-persistence-service.js')) 
.factory('authenticationInterceptorService', require('./authentication-interceptor-service.js'))

.factory('metadataService', require('./metadata-service.js'))
.factory('generalMetadataService', require('./general-metadata-service.js'))

.factory('storage', require('./storage-service.js'))
.factory('Stopwatch', require('./stopwatch.js'))

.factory('jwtTokenLocalReadService', require('./jwttoken-local-read-service.js'))

.factory('queryStorage', require('./query-storage.js'))

.filter('query', require('./query-filter.js'))

.config(configureAppconfiguration)
.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('authenticationInterceptorService');
}]);


configureAppconfiguration.$inject = ['appConfigurationProvider'];
function configureAppconfiguration(appConfigurationProvider) {
    appConfigurationProvider
        .defaultValue('refreshTokenGetter', require('./refresh-token-getter.js'));
}

(module || {}).exports = 'ivd.services';
