ivdName.$inject = ['$compile', '$parse', 'log4d'];
/// get name for an HTML field from scope (i.e. metadata) based on a path set in the attribute 'ivd-name'
function ivdName ($compile, $parse, log4d) {
    var logger = log4d.logger('ivdName');
    return {
        restrict: 'A',
        replace: false,
        terminal: true, // prevent lower priority directives to compile after it
        priority: 1001, // compiles first
        link: function link(scope, el, attrs) {
            try {
                var dynamicName = $parse(el.attr('ivd-name'))(scope);
                if (dynamicName == undefined) return;
                logger.debug('ivd-name', el.attr('ivd-name'), '-->', dynamicName);
                el.attr('name', dynamicName);
            } catch (error) {
                logger.error(error)
            } finally {
                el.removeAttr('ivd-name'); // necessary to avoid infinite compile loop
                el.removeAttr('data-ivd-name');
                $compile(el)(scope);
            }
        }
    };
}

(module || {}).exports = ivdName;