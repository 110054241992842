currencyFilter.$inject = ['$filter', 'appLocalization'];

/**
 *  Enhancing the currency filter in Google Angular
 *  http://polishinggems.blogspot.be/2011/03/enhancing-currency-filter-in-google.html
 */
function currencyFilter($filter, appLocalization) {

    return function (input, curSymbol, decPlaces) {

        var curSymbol = (curSymbol || "") + " "; // no default currency
        var decPlaces = decPlaces || 2;
        
        // Check for invalid inputs
        var out = isNaN(input) || input === '' || input === null ? 0.0 : input;

        //Deal with the minus (negative numbers)
        var minus = input < 0;
        out = Math.abs(out).toFixed(decPlaces);
        out = $filter('decimal')(out, decPlaces);

        return curSymbol + out;

    };

}

(module || {}).exports = currencyFilter;