log4d.$inject = ['$injector', '$log', 'appConfiguration', 'ivdInsights'];

function log4d($injector, $log, appConfiguration, ivdInsights) {

    var svc = {
        logger: logger
    };

    function logger(name, remoteLogging) {
        // var self = this;
        var name = name;
        var remoteLoggingEnabled = remoteLogging || false;

        var $http;
        var ivdNotifications;

        var logLevel = {
            DEBUG: 0,
            LOG: 1,
            INFO: 2,
            WARN: 3,
            ERROR: 4,
            MUTE: 5
        };

        return {
            debug: debug,
            log: log,
            info: info,
            warn: warn,
            error: error
        };

        function globalLevel() {
            var lvl = logLevel[('' + appConfiguration.log4d.global.minLevel).toUpperCase()];
            return (lvl == undefined) ? logLevel.MUTE : lvl;
        }

        function level4Logger() {
            var loggerConfig = appConfiguration.log4d.loggers[name];
            if (loggerConfig == undefined) return globalLevel();
            var lvl = logLevel[('' + loggerConfig.minLevel).toUpperCase()];
            return (lvl == undefined) ? logLevel.MUTE : lvl;
        }

        function globalInsightLevel() {
            var lvl = logLevel[('' + appConfiguration.log4d.global.insightMinLevel).toUpperCase()];
            return (lvl == undefined) ? globalLevel() : lvl;
        }

        function insightLevel4Logger() {
            var loggerConfig = appConfiguration.log4d.loggers[name];
            if (loggerConfig == undefined) return globalInsightLevel();
            var lvl = logLevel[('' + loggerConfig.insightMinLevel).toUpperCase()];
            return (lvl == undefined) ? logLevel.MUTE : lvl;
        }

        function globalSerilogLevel() {
            var lvl = logLevel[('' + appConfiguration.log4d.global.serilogMinLevel).toUpperCase()];
            return (lvl == undefined) ? globalLevel() : lvl;
        }

        function serilogLevel4Logger() {
            var loggerConfig = appConfiguration.log4d.loggers[name];
            if (loggerConfig == undefined) return globalSerilogLevel();
            var lvl = logLevel[('' + loggerConfig.serilogMinLevel).toUpperCase()];
            return (lvl == undefined) ? logLevel.MUTE : lvl;
        }

        function logToServer(level, arguments) {
            $http = ($http == undefined) ? $injector.get('$http') : $http;
            var data = {
                MessageTemplate: angular.isObject(arguments[0]) ? arguments[0].message : arguments[0],
                PropertyValues: Array.prototype.slice.call(arguments) //Array-ify Arguments object
            };
            var req = {
                method: 'POST',
                url: appConfiguration.apiUrl + 'logging/' + level,
                data: data
            };
            $http(req).error(function () { $log.error('log4d server logging failed') });
        }

        function globalNotificationLevel() {
            var lvl = logLevel[('' + appConfiguration.log4d.global.notificationMinLevel).toUpperCase()];
            return (lvl == undefined) ? globalLevel() : lvl;
        }

        function level4Notification() {
            ivdNotifications = (ivdNotifications == undefined) ? $injector.get('ivdNotifications') : ivdNotifications;
            var loggerConfig = appConfiguration.log4d.loggers[name];
            if (loggerConfig == undefined) return globalNotificationLevel();
            var lvl = logLevel[('' + loggerConfig.notificationMinLevel).toUpperCase()];
            return (lvl == undefined) ? logLevel.MUTE : lvl;
        }

        // Note for Application Insights:
        //      If both Serilog and client side AI are enabled, the same error will be logged twice to AI (once via client-side AI and once via the Serilog AI sink).
        //      To counter this we will only log client-side AI if it is not being sent to Serilog.
        //      This behavour can be overwritten in the "active.json" configuration file by settings the "alwaysLogAI" parameter to "true".
        function debug() {
            if (level4Logger() <= logLevel.DEBUG) $log.debug.apply(this, arguments);
            if ((insightLevel4Logger() <= logLevel.DEBUG) && (appConfiguration.log4d.alwaysLogAI || (logLevel.DEBUG < serilogLevel4Logger()))) ivdInsights.trackEvent(arguments[0], arguments);
            if (serilogLevel4Logger() <= logLevel.DEBUG && this.remoteLoggingEnabled) logToServer('DEBUG', arguments);
            if (level4Notification() <= logLevel.DEBUG) {
                var logMessage = undefined;
                if (angular.isObject(arguments[0])) {
                    if (typeof (arguments[0].message) === 'string') {
                        logMessage = arguments[0].message;
                    }
                } else if (typeof (arguments[0]) === 'string') {
                    logMessage = arguments[0];
                } else {
                    logMessage = JSON.stringify(arguments[0]);
                }

                ivdNotifications.notify(ivdNotifications.type.WARNING, logMessage, logLevel[logLevel.DEBUG])
            }
        }

        function log() {
            if (level4Logger() <= logLevel.LOG) $log.log.apply(this, arguments);
            if ((insightLevel4Logger() <= logLevel.LOG) && (appConfiguration.log4d.alwaysLogAI || (logLevel.LOG < serilogLevel4Logger()))) ivdInsights.trackEvent(arguments[0], arguments);
            if (serilogLevel4Logger() <= logLevel.LOG && this.remoteLoggingEnabled) logToServer('LOG', arguments);
            if (level4Notification() <= logLevel.LOG) {
                var logMessage = undefined;
                if (angular.isObject(arguments[0])) {
                    if (typeof (arguments[0].message) === 'string') {
                        logMessage = arguments[0].message;
                    }
                } else if (typeof (arguments[0]) === 'string') {
                    logMessage = arguments[0];
                } else {
                    logMessage = JSON.stringify(arguments[0]);
                }

                ivdNotifications.notify(ivdNotifications.type.WARNING, logMessage, logLevel[logLevel.LOG])
            }
        }

        function info() {
            if (level4Logger() <= logLevel.INFO) $log.info.apply(this, arguments);
            if ((insightLevel4Logger() <= logLevel.INFO) && (appConfiguration.log4d.alwaysLogAI || (logLevel.INFO < serilogLevel4Logger()))) ivdInsights.trackEvent(arguments[0], arguments);
            if (serilogLevel4Logger() <= logLevel.INFO && this.remoteLoggingEnabled) logToServer('INFO', arguments);
            if (level4Notification() <= logLevel.INFO) {
                var logMessage = undefined;
                if (angular.isObject(arguments[0])) {
                    if (typeof (arguments[0].message) === 'string') {
                        logMessage = arguments[0].message;
                    }
                } else if (typeof (arguments[0]) === 'string') {
                    logMessage = arguments[0];
                } else {
                    logMessage = JSON.stringify(arguments[0]);
                }

                ivdNotifications.notify(ivdNotifications.type.WARNING, logMessage, logLevel[logLevel.INFO])
            }
        }

        function warn() {
            if (level4Logger() <= logLevel.WARN) $log.warn.apply(this, arguments);
            if ((insightLevel4Logger() <= logLevel.WARN) && (appConfiguration.log4d.alwaysLogAI || (logLevel.WARN < serilogLevel4Logger()))) ivdInsights.trackEvent(arguments[0], arguments);
            if (serilogLevel4Logger() <= logLevel.WARN && this.remoteLoggingEnabled) logToServer('WARN', arguments);
            if (level4Notification() <= logLevel.WARN) {
                var logMessage = undefined;
                if (angular.isObject(arguments[0])) {
                    if (typeof (arguments[0].message) === 'string') {
                        logMessage = arguments[0].message;
                    }
                } else if (typeof (arguments[0]) === 'string') {
                    logMessage = arguments[0];
                } else {
                    logMessage = JSON.stringify(arguments[0]);
                }

                ivdNotifications.notify(ivdNotifications.type.WARNING, logMessage, logLevel[logLevel.WARN])
            }
        }

        function error() {
            if (level4Logger() <= logLevel.ERROR) $log.error.apply(this, arguments);
            if ((insightLevel4Logger() <= logLevel.ERROR) && (appConfiguration.log4d.alwaysLogAI || (logLevel.ERROR < serilogLevel4Logger()))) ivdInsights.trackEvent(arguments[0], arguments);
            if (serilogLevel4Logger() <= logLevel.ERROR && this.remoteLoggingEnabled) logToServer('ERROR', arguments);
            if (level4Notification() <= logLevel.ERROR) {
                var logMessage = undefined;
                if (angular.isObject(arguments[0])) {
                    if (typeof (arguments[0].message) === 'string') {
                        logMessage = arguments[0].message;
                    }
                } else if (typeof (arguments[0]) === 'string') {
                    logMessage = arguments[0];
                } else {
                    logMessage = JSON.stringify(arguments[0]);
                }

                ivdNotifications.notify(ivdNotifications.type.WARNING, logMessage, logLevel[logLevel.ERROR])
            }
        }
    }

    return svc;
}

(module || {}).exports = log4d;