/**
 * @file json-date-parser.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

/**
 * An Angular service that parses input and converts it into a Date
 * 
 * Also have a look at [http://weblog.west-wind.com/posts/2014/Jan/06/JavaScript-JSON-Date-Parsing-and-real-Dates](http://weblog.west-wind.com/posts/2014/Jan/06/JavaScript-JSON-Date-Parsing-and-real-Dates)
 * @namespace
 */
function jsonDateParser() {
    var service = {
        parse: parse
    };

    var reISO = /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2}(\.\d*)?)(Z?|(\+|-)([\d|:]*))?)?$/;
    var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

    return service;

    /**
     * Parses a string or an array of strings
     * and converts it/them into a Date or an array of Dates.
     * @param {string|Array} value - the input
     * @returns {Date|Array} - the output
     */
    function parse(value) {
        if (value instanceof Array) {
            return parseArray(value);
        } else if (typeof value == 'string') {
            var a = reISO.exec(value);
            if (a)
                return new Date(value);
            a = reMsAjax.exec(value);
            if (a) {
                var b = a[1].split(/[-+,.]/);
                return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }
        return value;
    }

    function parseArray(arr) {
        var result = [];
        for (var i = 0; i < arr.length; i++) {
            result.push(parse(arr[i]));
        }
        return result;
    }
}


(module || {}).exports = jsonDateParser;