applicationInsightRunner.$inject = ['$rootScope', 'log4d', 'ivdInsights'];

function applicationInsightRunner($rootScope, log4d, ivdInsights) {
    var logger = log4d.logger('application-insight-runner');

    if (ivdInsights.isAvailable()) {

        logger.info("ApplicationInsights: keeping an eye $on $locationChangeStart & $locationChangeSuccess");

        $rootScope.$on('$locationChangeStart', function (event, newUrl, previousUrl) {
            ivdInsights.startTrackPage(newUrl);
        });

        $rootScope.$on('$locationChangeSuccess', function (event, newUrl) {
            ivdInsights.stopTrackPage(newUrl, newUrl);
        });

    } else {
        logger.debug("ApplicationInsights is not configured.");
    }
    
}

(module || {}).exports = applicationInsightRunner;