/**
 * @file rest-service.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

restService.$inject = ['$resource', 'appConfiguration', 'authenticationService',
                       '$location', '$q', 'log4d', 'restQueryBuilder', 'ivdInsights'];

/**
 * An Angular service for talking to a REST API endpoint on a server.
 * @namespace
 */
function restService($resource, appConfiguration, authenticationService,
                     $location, $q, log4d, restQueryBuilder, ivdInsights) {

    var logger = log4d.logger('restService');
    var headersPerEndpoint = new ivd.CircularArray(10, logger);

    var service = {
        query: query,
        maxQuerySize: 0,
        exporter: exporter,
        get: get,
        add: add,
        update: update,
        remove: remove,
        removeAttachment: removeAttachment,
        getNew: getNew,
        list: list,
        getResponseHeader: getResponseHeader
    };
    return service;

    /**
     * Gets the HTTP response header corresponding to an endpoint.
     * The header needs to have been buffered before by calling the query function first.
     * Attention: the header is partial, very partial.
     * @param {string} endpoint - endpoint
     * @returns {Object} the partial response header with possible properties:
     * - maxQuerySize
     * - statistics
     */
    function getResponseHeader(endpoint) {
        return  _.find(headersPerEndpoint, {'endpoint': endpoint}) || {};
    }

    // internal function
    function resource(endpoint, context) {
        return $resource(appConfiguration.apiUrl + endpoint + '/:id', { id: '@Id' }, {
            update: {
                method: 'PUT' // adds support for PUT request
            },
            query: {
                isArray: true,
                headers: {'X-Context':  !!context ? encodeURI(JSON.stringify(context)) : null},
                interceptor: {
                    response: function (response) {
                        service.maxQuerySize = response.headers('MaxResultSize');
                        var header = _.find(headersPerEndpoint, {'endpoint': endpoint});
                        if (!header) {
                            header =  {'endpoint': endpoint};
                            headersPerEndpoint.push(header);
                        }
                        header.maxQuerySize = response.headers('MaxResultSize');
                        header.statistics = angular.fromJson(response.headers('X-Statistics'));
                        return response.resource;
                    }
                }
            },
            export: {
                method: 'GET',
                url: appConfiguration.apiUrl + endpoint + '/export',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    return { data: data, headers: headers() };
                }
            },

        });
    }

    /**
     * Queries an endpoint. Sends out a GET to the REST API endpoint.
     * ```http
     *  GET http://server/api/endpoint
     * ```
     * @param {string} endpoint - endpoint
     * @param {Object} parameters - query parameters object (key-values), with such properties as $expand, $sort...
     * @returns {Promise}
     */
    function query(endpoint, parameters, context) {
        return resource(endpoint, context).query(parameters).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    /**
     * Asks for an export of an endpoint. 
     * ```http
     *  GET http://server/api/endpoint/export
     * ```
     * @param {string} endpoint - endpoint
     * @param {Object} parameters - query parameters object (key-values), with such properties as $expand, $sort...
     * @param {string} filetype - file 
     * @returns {Promise}
     */
    function exporter(endpoint, parameters, filetype) {
        parameters.$export_format = filetype;
        ivdInsights.trackEvent('ExportFile', {type: filetype});
        return resource(endpoint).export(parameters).$promise
            .catch(function(reason) {
                return handle_http_error_statuses(reason);
            });
    }
    
    /**
     * Gets an item from an endpoint. 
     * ```http
     *  GET http://server/api/endpoint/123
     * ```
     * @param {string} endpoint - endpoint
     * @param {Object} extraQueryParameters - query parameters object (key-values), with such properties as $expand, $sort...
     * @returns {Promise}
     */
    function get(endpoint, id, extraQueryParameters) { //GET
        var q = (extraQueryParameters) ? extraQueryParameters : {};
        q.id = id;
        return resource(endpoint).get(q).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    /**
     * Asks the server to construct a new object for the given endpoint.
     * The resulting resource will have default properties.
     * ```http
     *  GET http://server/api/endpoint/new
     * ```
     * @param {string} endpoint - endpoint
     * @returns {Object}
     */
    function getNew(endpoint) { //GET newly created resource with default properties
        var q = {};
        q.id = 'new';
        return resource(endpoint).get(q).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    /**
     * Adds a new resource
     * ```http
     *  POST http://server/api/endpoint/ + payload
     * ```
     * @param {string} endpoint - endpoint
     * @param {Object} entity - the resource to add
     * @returns {Promise}
     */
    function add(endpoint, entity) { //POST
        return resource(endpoint).save(entity).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    /**
     * Updates a resource
     * ```http
     *  PUT http://server/api/endpoint/123 + payload
     * ```
     * @param {string} endpoint - endpoint
     * @param {Object} entity - the resource to update
     * @returns {Promise}
     */
    function update(endpoint, entity) { //PUT
        return resource(endpoint).update({ id: (entity.Id || entity.ID) }, entity).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    /**
     * Deletes a resource
     * ```http
     *  DELETE http://server/api/endpoint/123
     * ```
     * @param {string} endpoint - endpoint
     * @param {Object} entity - the resource to update
     * @returns {Promise}
     */
    function remove(endpoint, entity) { //DELETE
        return resource(endpoint).remove({ id: (entity.Id || entity.ID) }).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    /**
     * ??
     */
    function list(endpoint) {
        var currentQuery = restQueryBuilder.getSingleton(endpoint).getQueryParameters();
        return resource(endpoint).query(currentQuery).$promise
            .catch(function (reason) {
                return handle_http_error_statuses(reason);
            });
    }

    // TODO move this function outside of this service
    /**
     * ??
     */
    function removeAttachment(endpoint, entity) { //DELETE
        return $resource(appConfiguration.apiUrl + endpoint + '/:guid', { guid: "@Guid" }, {})
            .remove({ guid: (entity.guid || entity.Guid || '') }).$promise;
    }

    function handle_http_error_statuses(reason) {
        if (reason.status == 401) {
            authenticationService.logout();
            authenticationService.goToAuthenticationPage($location.path());
        } else if (reason.status == 403 && reason.data === 'ToS') {
            authenticationService.goToTermsOfServicePage($location.path());
        } else {
            logger.error(reason);
            return $q.reject(reason);
        }
    }
}

(module || {}).exports = restService;
