/**
 * @file ivd-utils-service.js
 * @copyright (c) 2016 4D vision
 * @author Fatah Barzangy
 * @license Proprietary
 */

ivdUtils.$inject = ['$translate'];

function ivdUtils($translate) {
    
    // public API:
    var service = {
        errToStr: errToStr,
        cleanEndpointStr: cleanEndpointStr,
        /* Copy the fields from src to destination without the $promise and $resolved fields */
        safeMerge: safeMerge,
        /* Removes all functions from object */
        cleanObject: cleanObject
    };
    
    return service;
    
    function errToStr(error) {
        var ret = '';
        if (Array.isArray(error.data)) {
            _.each(error.data, function (msgObject) {
                ret += $translate.instant(msgObject.Message) + '\n';
            });
        } else if (error.data.Message) {
            ret += $translate.instant(error.data.Message);
        } else {
            ret = 'Exception: ' + (error.data ? error.data + ' ' : '') + error.status + ' ' + error.statusText;
        }
        return ret;
    }

    function cleanEndpointStr(ep) {
        var ep1 = ep.replace(/^\//, '');
        ep1 = ep1.replace(/\?.+$/, '');
        ep1 = ep1.replace(/\/$/, '');
        ep1 = ep1.replace(/\/[0-9]+$/, '');
        return ep1;
    }

    
    function safeMerge(dst, src) {
        src = cleanObject(src);
        angular.merge(dst, src);
    }

    function cleanObject(resp) {
        return JSON.parse(angular.toJson(resp));
    }

}

(module || {}).exports = ivdUtils;