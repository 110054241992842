/**
 * @file base-entity-controller.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

/**
 * Base class for the generic entity controller (Angular controller).
 * @class 
 */
function BaseEntityController(
  // 4D services (in order of importance):
  endpoint, restService, restQueryBuilder, creator, detailViewService, metadataService, authenticationService, ivdUtils,
  // external services (alphabetically) :
  // Angular '$' services (alphabetically):
  $injector, $log, $routeParams, $scope, $timeout, $location) {
    /* jshint validthis:true */

    var logger = $injector.get('log4d').logger('BaseEntityController');
    var $translate = $injector.get('$translate');
    var dataService = $injector.get('dataService');
    var ivdNotifications = $injector.get('ivdNotifications');

    var vm = this;

    /** @member {string} title - title for the view  */
    vm.title = '';

    /** @member {string} endpoint endpoint */
    vm.endpoint = endpoint;

    // model data :
    /** @member {Object} */
    vm.list = {
        metadata: metadataService.get(vm.endpoint, 'list'),
        data: undefined,
        searchFields: [],
        sortFields: [],
        showViewButton: true,
        showEditButton: false,
        showDeleteButton: false,
        noEverScroll: false
    };
    /** @member {Object} */
    vm.view = {
        metadata: metadataService.get(vm.endpoint, 'view'),
        data: {}
    };
    /** @member {Object} */
    vm.create = {
        metadata: metadataService.get(vm.endpoint, 'create'),
        data: {}
    };
    /** @member {Object} */
    vm.edit = {
        metadata: metadataService.get(vm.endpoint, 'edit'),
        data: {}
    };

    /** @member {Object} */
    vm.dataService = restService;

    vm.customFunctions = '';
    vm.setActivated = setActivated;

    vm.createNew = createNew;
    vm.createSpecific = createSpecific;
    vm.loadData = loadData;
    vm.exportData = exportData;

    vm.customizeCreateMetadata = customizeCreateMetadata;

    vm.errorMessage = '';


    // init :
    activate();

    function activate() {
        //Reading routing parameters
        vm.id = $routeParams.id;

        if (!vm.id) {
            vm.list.data = dataService.get(vm.endpoint); // gets a reference to the data // TGH 2016-12-15 this is no longer used ???
        }

        $timeout(function () {
            metadataService.require(vm.endpoint, 'view').then(handleSingleRoute);
        });
    }

    function handleSingleRoute(viewMetadata) {
        logger.log('1st require returned with', viewMetadata);
        if (vm.id && !vm.customView) {
            detailViewService.open({
                endpoint: vm.endpoint,
                id: vm.id
            });
        }
    }

    /**
     * Loads data
     * @returns {Promise}
     */
    function loadData() {
        if (!vm.id) {
            // Load data from services :
            return restService.query(vm.endpoint, restQueryBuilder.getSingleton(vm.endpoint).getQueryParameters())
              .then(function (data) {
                  vm.list.data = data;
                  return data;
              });
        }
    }

    /**
     * Exports data
     * @returns {Promise}
     */
    function exportData(filetype) {
        if (!vm.id) {
            var waitNotification = ivdNotifications.notify(ivdNotifications.type.INFO, "Start downloading file...", null, {
                autoHide: false,
                closeButton: false,
                tapToDismiss: false
            });
            restService.exporter(vm.endpoint, restQueryBuilder.getSingleton(vm.endpoint).getQueryParameters(), filetype)
                .then(function (data) {
                    var octetStreamMime = 'application/octet-stream';
                    var success = false;
                    // Get the headers
                    headers = data.headers;
                    data = data.data;

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headers['content-disposition'].split('=')[1]// || 'download.bin';
                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headers['content-type']// || octetStreamMime;

                    try {
                        // Try using msSaveBlob if supported
                        var blob = new Blob([data], { type: contentType });
                        if (navigator.msSaveBlob)
                            navigator.msSaveBlob(blob, filename);
                        else {
                            // Try using other saveBlob implementations, if available
                            var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                            if (saveBlob === undefined) throw "Not supported";
                            saveBlob(blob, filename);
                            logger.log('Download: save blob succeeded');
                        }
                        success = true;
                        ivdNotifications.clearAll();
                        ivdNotifications.notify(ivdNotifications.type.SUCCESS, "File downloaded!");
                    } catch (ex) {
                        logger.log('Download: save blob failed');
                    }
                    if (!success) {
                        // Get the blob url creator
                        var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                        if (urlCreator) {
                            // Try to use a download link
                            var link = document.createElement('a');
                            if ('download' in link) {
                                // Try to simulate a click
                                try {
                                    // Prepare a blob URL
                                    var blob = new Blob([data], { type: contentType });
                                    var url = urlCreator.createObjectURL(blob);
                                    link.setAttribute('href', url);

                                    // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                                    link.setAttribute("download", filename);

                                    // Simulate clicking the download link
                                    var event = document.createEvent('MouseEvents');
                                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                                    link.dispatchEvent(event);
                                    success = true;
                                    ivdNotifications.clearAll();
                                    ivdNotifications.notify(ivdNotifications.type.SUCCESS, "File downloaded!");
                                    logger.log('Download: simulated click succeeded');
                                } catch (ex) {
                                    logger.log('Download: simulated click failed');
                                }
                            }

                            if (!success) {
                                // Fallback to window.location method
                                try {
                                    // Prepare a blob URL
                                    // Use application/octet-stream when using window.location to force download
                                    var blob = new Blob([data], { type: octetStreamMime });
                                    var url = urlCreator.createObjectURL(blob);
                                    window.location = url;
                                    logger.log('Download: window.location method succeeded');
                                    success = true;
                                    ivdNotifications.clearAll();
                                    ivdNotifications.notify(ivdNotifications.type.SUCCESS, "File downloaded!");
                                } catch (ex) {
                                    logger.log('Download: window.location method failed');
                                }
                            }

                        }
                    }
                    if (!success) {
                        logger.error("Downloading file failed");
                        ivdNotifications.clearAll();
                        ivdNotifications.notify(ivdNotifications.type.ERROR, "Use a more recent browser to download exports.", "Download failed");
                    }

                }
                ,function (rejectReason) {
                    ivdNotifications.clearAll();
                    ivdNotifications.notify(ivdNotifications.type.ERROR, "", "Download failed");
                });
        }
    }

    /**
     * Calls the REST API for a newly created resource (default constructor-ish),
     * then opens the create view bound to this newly created resource.
     */
    function createNew() {
        var ep = ivdUtils.cleanEndpointStr(vm.endpoint);
        return createSpecific(ep, false);
    }

    function createNew(number) {
        var ep = ivdUtils.cleanEndpointStr(vm.endpoint);
        return createSpecific(ep, number);
    }

    function createSpecific(thisEndpoint, number, context) {
        metadataService.require(thisEndpoint, 'create')
            .then(function (createMetadata) {

                var actionAfterCreated;
                if (vm.customView) {
                    var separator = _.endsWith($location.path(), '/') ? '' : '/';
                    actionAfterCreated = function (id) {
                        if (id) {
                            $location.path($location.path() + separator + id);
                        } else {
                            //Force a refresh if id is undefined.
                            $location.path($location.path()+ separator);
                        }
                    };
                }

                restService.getNew(thisEndpoint).then(function (prefilled) {
                    vm.customizeCreateMetadata(createMetadata, prefilled);
                    detailViewService.open({
                        data: prefilled,
                        metadata: vm.create.metadata,
                        endpoint: thisEndpoint,
                        mode: 'create',
                        refreshList: doAll(vm.loadData),
                        actionAfterCreated: doAll(actionAfterCreated, vm.onCreated),
                        customJS: vm.customJS,
                        context: context,
                        number: number
                    });
                });
            });
    }

    /**
     * Function that can be overridden in the derived class to
     * customize the create metadata
     */
    function customizeCreateMetadata(createMetadata, prefilled) {

    }

    var activated = {};
    /**
     * Sets activated
     * 
     * This is triggered by 
     * - on-metadata-processed on ivd-grid directive
     * - on-activated on ivd-search-box
     * - on-activated on ivd-sort-box
     * 
     * When the last trigger arrives, `loadData` will be called.
     */
    function setActivated(type) {
        activated[type] = true;
        if (activated.sort && activated.search && activated.metadata) {
            vm.loadData();
        }
    }

    function doAll() {
        var fn = _.filter(arguments,function(arg){return arg!==undefined;});
        if (fn.length==0) return undefined;
        return function () {
            for (var i = 0; i < fn.length; i++) {
                if (angular.isFunction(fn[i])) {
                    fn[i].apply(this, arguments);
                }
            }
        };
    }

    return vm;

}

(module || {}).exports = BaseEntityController;
