/**
 * @file object-access-service.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

objectAccess.$inject = [];
/**
 * An Angular service for getting a (nested) property of an object.
 * 
 * (Idea: use Angular's $parse service instead)
 * @namespace
 */
function objectAccess() {
    var service = {
        getValueByString: getValueByString,
        setValueByString: setValueByString
    };

    return service;

    /**
     * Given a string representing the location, gets the value on the location in the object

        var obj = { a: { b: 1, c: { d: 3, e: 4 }, f: 5 } }
        str = 'a.c.d'
        getValueByString(obj, str) // 3

        @param {Object} object - the input
        @param {string} string - the property, use a dot to denote depth
        @returns {Object|string|number|whatever}
    */
    function getValueByString(object, string) {
    //This is basically lodash's _.property() method
        var obj = _.cloneDeep(object);
        var str = _.cloneDeep(string);
        str = str.split(".");
        for (var i = 0; i < str.length; i++) {
            obj = obj[str[i]];
            if (obj == undefined) {
                return undefined;
            }
        }
        return obj;
    }

    /** Sets the value of a variable, defined by the path, on the object
    
        var obj = { a: { b: 1, c: { d: 3, e: 4 }, f: 5 } }
        str = 'a.c.d'
        set(obj, str, 99)
        obj.a.c.d // = 99

        @param {Object} obj - the input
        @param {string} str - the property, use a dot to denote depth
        @param {Object|string|number|whatever} val - the value to set
    */
    function setValueByString(obj, str, val) {
        str = str.split(".");
        while (str.length > 1)
            obj = obj[str.shift()];
        return obj[str.shift()] = val;
    }
}

(module || {}).exports = objectAccess;