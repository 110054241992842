ivdFormattedDateBox.$inject = [];

function ivdFormattedDateBox() {
    // Directive definition object
    var directive = {
        restrict: 'E',
        replace: true, // because of Bootstrap styling
        controller: FormattedDateBoxController,
        controllerAs: 'formattedDateBox',
        templateUrl: 'ivd/ui/formatted-date-box/formatted-date-box.html',
        scope: {
            date: '=', //The date object to bind to
            fieldName: '=', // 'name' attribute for input field to render
            required: '=',
            format: '=' //The format of the date
        },
        bindToController: true //Bind directive's scope to controller
    }

    return directive;
}

FormattedDateBoxController.$inject = ['ivdDateTimeValidator','$filter', '$scope', '$parse'];

function FormattedDateBoxController(ivdDateTimeValidator, $filter, $scope, $parse) {
    var vm = this;

    //Bindable member
    vm.formatDate = formatDate;
    vm.reset = resetDateTime;
    //Activation
    activate();

    function resetDateTime() {
        vm.date = null;
        vm.rawDate = '';
    }

    function activate() {
        vm.format = vm.format || 'dd/MM/yyyy HH:mm';
               
    }

    function formatDate(input) {
        vm.date = ivdDateTimeValidator.validateDateFormat(input, vm.format).newDate;
    }

    $scope.$watch(function () { return (vm.date); },
        function (newValue, oldValue) {
            if (moment.isDate(vm.date)) {
                vm.date = vm.date.toJSON();
            }
            if (vm.date) {
                if (_.endsWith(vm.date,'Z')) {
                    vm.rawDate = $filter('date')(vm.date, vm.format, 'UTC');
                } else {
                    vm.rawDate = $filter('date')(vm.date, vm.format);
                }
            }
        }
    );
}

(module || {}).exports = ivdFormattedDateBox;