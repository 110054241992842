notificationsService.$inject = ['toastr'];


function notificationsService(toastr) {
    var type = {
        SUCCESS: 0,
        INFO: 1,
        WARNING: 2,
        ERROR: 3
    };

    var service = {
        type : type,
        notify: notify,
        clear: clear,
        clearAll: clearAll
    };
    return service;

    function notify(type, message, title, options) {
        var notifyFunction = toastr.info;
        switch (type) {
            case this.type.SUCCESS:
                notifyFunction = toastr.success;
                break;
            case this.type.WARNING:
                notifyFunction = toastr.warning;
                break;
            case this.type.ERROR:
                notifyFunction = toastr.error;
                break;
        }
        var o = angular.extend({
            closeButton: true,

        }, options);
        return notifyFunction(message, title, angular.extend({
            closeButton: true,
            
        }, options));
    }
    
    function clear(notifications) {
        if (notifications.constructor !== Array) {
            notifications = [notifications];
        }
        toastr.clear(notifications);
    }

    function clearAll(notifications) {
        toastr.clear()
    }
    
}

(module || {}).exports = notificationsService;