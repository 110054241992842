ivdSearchBox.$inject = ['$window'];

function ivdSearchBox($window) {
    // Usage:
    //     <ivd-search-box></ivd-search-box>
    // Creates:
    // 
    var directive = {
        //link: link, // Directives that want to modify the DOM typically use the link option. E.g. updating a DIV to display current time...
        restrict: 'E',
        scope: {
            searchFields: '=',
            endpoint: '=',
            loaded: '=',
            refreshData: '&',
            delayedSearching: '<?',
            isOpen: '=',
            fixedFilter: '=',
            filterActive: '=',
            onActivated: '&'
        },
        templateUrl: 'ivd/ui/search-box/search-box.html',
        controllerAs: 'searchBox',
        controller: SearchBoxController,
        bindToController: true // because the scope is isolated
    };
    return directive;
}

SearchBoxController.$inject = ['restQueryBuilder', 'queryStorage', '$scope',
    'jwtTokenLocalReadService', '$translate', 'log4d'];

function SearchBoxController(restQueryBuilder, queryStorage, $scope,
    jwtTokenLocalReadService, $translate, log4d) {

    var vm = this;
    vm.doSearch = doSearch;
    vm.signalChange = signalChange;
    vm.clear = clear;
    vm.hasAdvancedFields = false;
    vm.filterActive = false;
    vm.advancedFilterActive = false;
    vm.booleanOptions = undefined; 

    var userlang = jwtTokenLocalReadService.getUserLanguage();

    var logger = log4d.logger('searchBox');

    function activate() {

        if (userlang) {
            $translate.use(userlang).then(function () {
                $translate(['YES', 'NO']).then(function (translations) {                   
                    vm.booleanOptions = [
                        { id: true, text: translations.YES },
                        { id: false, text: translations.NO }
                    ];
                });
            });
        }

        var fixedFilterKeys = [];
        for (var key in vm.fixedFilter) {
            fixedFilterKeys.push(key);
        }

        if (vm.delayedSearching === '' || angular.isUndefined(vm.delayedSearching)) {
            vm.delayedSearching = false;
        }

        if (vm.advancedOpen === '' ||  angular.isUndefined(vm.advancedOpen)) {
            vm.advancedOpen = false;
        }

        vm.localSearchFields = [];      //first collect input in local array, then search (when search button is clicked e.g.)
        
        
        var fields = _.cloneDeep(vm.searchFields) || [];            //clonen, anders wordt een verandering in value van een zoekveld geïnterpreteert als een verandering in metadata 
        logger.debug('Initial search fields: ', vm.searchFields);

        var extractedSearchFields = queryStorage.searchCriteria(vm.endpoint);

        fields.forEach(function (field) {
            field.forUserLanguage = true;
            if (field.languageRestrictions && 
                field.languageRestrictions.length > 0 && 
                field.languageRestrictions.indexOf(userlang) === -1) {
                field.forUserLanguage = false;
            }
            if (field.visible && field.forUserLanguage && !_(fixedFilterKeys).includes(field.id)) { // exclude the fixed filter from the visible filters                
                
                if (vm.loaded !== true && field.defaultValue && angular.toJson(extractedSearchFields) === '{}') {
                    setDefaultValue(field);
                    logger.debug('Search field with set default value: ', field);
                }

                vm.localSearchFields.push(field);

                if (field.advanced) {
                    vm.delayedSearching = true;
                    vm.hasAdvancedFields = true;
                }
            }
        });
        
        //set search value if persisted localSearchFields exist for the given endpoint
        logger.debug('SearchFields retrieved: ', extractedSearchFields);

        vm.localSearchFields.forEach(function (field) {
            if (extractedSearchFields.length > 0) {
                extractedSearchFields.forEach(function (field2) {
                    if (field.id === field2.id) {
                        field.value = field2.value;
                    }
                });
            }
            else {
                field.value = undefined;
            }           
        });               

        setQuery();

        vm.loaded = true;

        vm.onActivated();
    }

    function setDefaultValue(field) {
       
        switch (field.type) {
            case 'boolean':
                if (userlang) {
                    $translate.use(userlang).then(function () {
                        $translate(['YES', 'NO']).then(function (translations) {                         
                            field.value = field.defaultValue == "true" ? { id: true, text: translations.YES } : { id: false, text: translations.NO };
                        });
                    });
                }
                else { field.value = field.defaultValue == "true" ? { id: true, text: 'Yes' } : { id: false, text: 'No' }; }                
                logger.debug('Search field end: ', field);
                break;
        }        
    }

    function signalChange(fieldId) {
        if (!vm.delayedSearching) {
            vm.doSearch();
        }
    }

    function doSearch() {
        setQuery();
        logger.log('SEARCH: refreshing')
        if (vm.refreshData) {
            vm.refreshData();
        }
        logger.log('localSearchFields persisted!');
    }

    function setQuery() {
        vm.filterActive = false;
        vm.advancedFilterActive = false;

        restQueryBuilder.getSingleton(vm.endpoint).clearFilter();
        restQueryBuilder.getSingleton(vm.endpoint).clearSkip();

        // add fixed filters first
        for (var key in vm.fixedFilter) {
            var val = vm.fixedFilter[key];
            if (angular.isNumber(val) || angular.isString(val) || angular.isDate(val)) {
                restQueryBuilder.getSingleton(vm.endpoint).addFilter(key, val);
            }
        }

        vm.localSearchFields.forEach(function (localField) {
            if (!!localField.value && localField.value !== '') {
                vm.filterActive = true;
                if (localField.advanced) {
                    vm.advancedFilterActive = true;
                }
                var filterValue = '';
                if (localField.value instanceof Array) {// .type can be "selectbox" or "boolean"
                    filterValue = _.map(localField.value, 'id').join(',');
                }
                else if (angular.isObject(localField.value)) {
                    // ducktape coding for fixing passing boolean options YES/NO to REST API
                    filterValue = localField.value.id;
                }
                else {
                    filterValue = localField.value;
                }
                if (filterValue !== '') {
                    restQueryBuilder.getSingleton(vm.endpoint).addFilter(localField.id, filterValue);
                }
            }
        });
        
        // persist local Search fields after data is filtered
        queryStorage.searchCriteria(vm.endpoint, vm.localSearchFields);
    }

    function clear() {
        queryStorage.clearSearchCriteria(vm.endpoint);
        activate();
        vm.refreshData();
    }

    // if input changes: re-activate !
    $scope.$watch(function () {
        return vm.searchFields;
    }, function (newValue, oldValue) {
        logger.log('detected change in search input');
        activate();
    });
}


(module || {}).exports = ivdSearchBox;
