ivdFormField.$inject = [];

function ivdFormField() {
    var directive = {
        restrict: 'E',
        require: '?ngModel', // get a hold of NgModelController
        controller: FormFieldController,
        controllerAs: 'vm',
        templateUrl: 'ivd/ui/form-field/form-field.html',
        scope: {
            ngModel: '=',
            metadata: '=',
            edit: '=',
            align: '=',
            form: '=',
            linkProperty: '=?',
            linkValue: '=?',
            labelCols: '=?', // integer between 1 and 11
            hideLabels: '=?',
            processing: '=',
            context: '<',
            preLabelHtml: '<?'
        },
        bindToController: true
    };
    return directive;
}

FormFieldController.$inject = [];

function FormFieldController() {
    var vm = this;

    //Bindable members 
    vm.name = vm.metadata.data;

    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
        vm.hideLabels = (vm.hideLabels == true);
        if (vm.hideLabels) {
            vm.labelCss = "hidden";
            vm.dataCss = "col-sm-12";
        } else {
            if (vm.labelCols && !isNaN(parseInt(vm.labelCols)) && vm.labelCols > 0 && vm.labelCols < 12) {
                vm.labelCss = "col-sm-" + vm.labelCols;
                vm.dataCss = "col-sm-" + (12 - vm.labelCols);
            } else {
                vm.labelCss = "col-sm-3";
                vm.dataCss = "col-sm-9";
            }
        }
    }
    
}

(module || {}).exports = ivdFormField;