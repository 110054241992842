ivdBooleanField.$inject = [];

function ivdBooleanField() {
    /*
       Usage:
       <ivd-boolean-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
   */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/boolean-field/boolean-field.html',
        controller: BooleanFieldController,
        controllerAs: 'vm',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

BooleanFieldController.$inject = ['objectAccess'];

function BooleanFieldController(objectAccess) {
    var vm = this;

    //Bindable members
    vm.get = get;
    vm.alterValue = alterValue;
    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    function alterValue() {
        if (vm.edit && !vm.metadata.readOnly) {
            vm.data = !vm.data;
        }
    }

    function get(obj, str) {
        if (obj == undefined) {
            return '';
        }
        return objectAccess.getValueByString(obj, str);
    }
}

(module || {}).exports = ivdBooleanField;
