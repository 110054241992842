detailViewChangesService.$inject = [];

function detailViewChangesService() {
    var subscriptions = [];
    var uid = 0;

    return {
        subscriptions: subscriptions,
        subscribe: subscribe,
        unsubscribe: unsubscribe
    };

    function subscribe(endpoint, property) {
        if (!property) {
            return;
        }
        var subscription = {
            id: uid++,
            endpoint: endpoint,
            property: property,
            eventName: 'detail-changed-for-' + endpoint  + ':' + property
        };
        subscriptions.push(subscription);
        return subscription;
    }

    function unsubscribe(id) {
        _.remove(subscriptions, function(s) {
            return s.id === id;
        });
    }
}

(module || {}).exports = detailViewChangesService;