(function (global, window) {

    'use strict';

    var ivd = global.ivd = require('./ivd-namespace.js');

    //ivd.vendors = require('./ivd.vendors.js');

     angular.module('app.config', [])
        .constant('appSettings', window.appSettings);

     angular
         .module('ivd',
         [
             'ngRoute',
             'ngResource',
             'ngStorage',
             'app.config',
             // alphabetically :
             require('./base'),
             require('./configuration'),
             require('./filters'),
             require('./insights'),
             require('./localization'),
             require('./notifications'),
             require('./services'),
             require('./translate'),
             require('./ui'),
             // dependent modules (alphabetically) :
             require('./authentication'), // depends on ivd.services
             require('./logging'), // depends on ivd.notifications
         ])
         .run(["$locale", function ($locale) {
             $locale.NUMBER_FORMATS.GROUP_SEP = ".";
             $locale.NUMBER_FORMATS.DECIMAL_SEP = ",";
         }
         ])
         .filter('decimal', function () {
             return function (decimal) {
                 var value = decimal.toString();
                 value = value.replace(/\.(\d*)$/, ',$1');
                 if (value.indexOf(',') == -1) value = value + ',';
                 value = value.replace(/(\d{3},)/, '.$1')
                 var oldValue = "";
                 while (oldValue !== value) {
                     oldValue = value;
                     value = value.replace(/(\d{3}\.)/, '.$1').replace('..','.');
                 }
                 return value.replace(/,$/, '').replace(/^(-?)\./, '$1');
             }
         })
         .filter('integer', function () {
             return function (integer) {
                 var value = integer.toString();
                 value = value.replace(/(\d{3})$/, '.$1');
                 var oldValue = "";
                 while (oldValue !== value) {
                     oldValue = value;
                     value = value.replace(/(\d{3}\.)/, '.$1').replace('..', '.');
                 }
                 return value.replace(/^(-?)\./, '$1');
             }
         })
         ;

        
}(this, window));

ivd = this.ivd; // turn into global variable on window property
