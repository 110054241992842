/**
 * @file query-filter.js
 * @copyright (c) 2016 4D vision
 * @author Marcel Samyn
 * @license Proprietary
 */

/**
 * An Angular filter for querying an array.
 * 
 * The options passed is an Object with possible properties:
 * - filters: an array with Objects as {field: 'name', value: 'Tony'} 
 * - orderby
 * - orderDirection
 * - skip
 * - top
 * 
 * Attention: this seems not te be used
 * 
 * @namespace
 */
function QueryFilter() {
    return function (input, options) {
        return _.chain(input)
            .filter(function (entity) {
                return _.isEmpty(options.filters) || _.some(options.filters, function (filter) {
                    return entity[filter.field].search(filter.value) > -1;
                });
            })
            .sortBy(options.orderby, options.orderDirection)
            .slice(options.skip || 0, options.top ? (options.skip + options.top + 1) : undefined);
    };
}

(module || {}).exports = QueryFilter;
