'use strict';

angular.module('ivd.configuration', [])

    .provider('appConfiguration', require('./app-configuration.js'))

    .config(configureAppConfigurationProvider)
    
    ;

configureAppConfigurationProvider.$inject = ['appConfigurationProvider'];

function configureAppConfigurationProvider(appConfigurationProvider) {
    appConfigurationProvider
        .defaultValue('apiUrl', 'api/')
        .defaultValue('menuFolder', 'data/')
        .defaultValue('selectbox.hyperlink', true)
        .defaultValue('selectbox.helpText', true)
        .defaultValue('defaultActionAfterCreated', 'view') // options are 'view'/'next'/'close'
        .defaultValue('date_format','dd/MM/yyyy')
        .defaultValue('datetime_format', 'dd/MM/yyyy HH:mm')
        .defaultValue('time_format', 'HH:mm')
        .defaultValue('fields_show_placeholder', true);
}

(module || {}).exports = 'ivd.configuration';