ivdDetailView.$inject = [];

function ivdDetailView() {
    //Directive Definition Object
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/detail-view/detail-view-opener.html',
        controller: DetailViewOpenerController,
        controllerAs: 'detailView',
        scope: {
            data: '<', //Pass all data that needs to be displayed in the modal
            metadata: '<',
            endpoint: '<', //The REST-service (or some other service) to access the database
            id: '<?',
            refreshGrid: '&',
            customView : '=',
            mode: '@',
            context: '<?',
            onSaved: '&',
            number: '<',
            ctnweight: '<'
        },
        bindToController: true //Bind directive's scope to controller's scope
    };
    return directive;
}

DetailViewOpenerController.$inject = ['detailViewService', '$route', '$location', 'log4d'];

function DetailViewOpenerController(detailViewService, $route, $location, log4d) {

    var logger = log4d.logger('detailView');

    var vm = this;

    //Bindable members
    vm.open = open;
    vm.refreshList = function () {
        if (angular.isFunction(vm.onSaved)) {
            vm.onSaved();
        }
        refreshList();
    }
    // + scope from ivdDetailView

    //Init
    activate();

    function activate() {
        vm.animationsEnabled = true;
        if (vm.mode==undefined) vm.mode = 'view';
    }

    //Implementation
    function open() {
        if(vm.customView){
            //$route.updateParams({ id: vm.id });
            var separator = _.endsWith($location.path(),'/') ? '' : '/';
            var url = $location.path() + separator + vm.id;
            if (vm.mode!='view') {
                url += '?mode=' + vm.mode;
            }
            $location.path( url );
        } else {
            detailViewService.open(vm);
        }
    }

    function refreshList(endpoint) {
        logger.debug('Endpoint passed to detail-view-service: ', endpoint);
        vm.refreshGrid();
    }

}

(module || {}).exports = ivdDetailView;
