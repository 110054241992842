ivdDateField.$inject = [];

function ivdDateField() {
    /*
       Usage:
       <ivd-boolean-field data="data" meta-data="meta" edit="bool"></ivd-text-field>
   */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/date-field/date-field.html',
        controller: DateFieldController,
        controllerAs: 'dateField',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

DateFieldController.$inject = ['objectAccess', '$scope'];

function DateFieldController(objectAccess, $scope) {
    var vm = this;

    //Bindable members
    vm.get = get;
    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
    }

    //Implementation
    function get(obj, str) {
        if (obj == undefined) {
            return '';
        }
        return objectAccess.getValueByString(obj, str);
    }

    $scope.$watch(function () {
        return vm.data;
    }, function (newValue, oldValue) {
        vm.showData = (newValue && !_.endsWith(newValue, 'Z')) ? (newValue + 'Z') : (newValue);
    })

}

(module || {}).exports = ivdDateField;