/**
 * @file metadata-service.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

metadataService.$inject = ['$http', '$q', 'ivdUtils'];

/**
 * An Angular service for retrieving metadata from the server.
 * @namespace
 */
function metadataService($http, $q, ivdUtils) {
    var service = {
        get: get,
        refresh: refresh,
        require: require
    };

    var cache = {
        view: {},
        list: {}
    };

    var progress = {
        view: {},
        list: {}
    };

    var typeMap = {
        view: 'view',
        create: 'view',
        list: 'list',
        edit: 'view',
        remove: 'view'
    };

    return service;

    function forList(entityPluralName) {
        var ep = ivdUtils.cleanEndpointStr(entityPluralName);
        return $http.get('metadata/' + ep + '/list').then(function (result) {
            _.assign(cache.list[ep], result.data);
            return result.data;
        });
    }

    function forView(entityPluralName) {
        var ep = ivdUtils.cleanEndpointStr(entityPluralName);
        return $http.get('metadata/' + ep + '/view').then(function (result) {
            _.assign(cache.view[ep], result.data);
            return result.data;
        });
    }

    function forCreate(entityPluralName) {
        var ep = ivdUtils.cleanEndpointStr(entityPluralName);
        return $http.get('metadata/' + ep + '/view').then(function (result) {
            _.assign(cache.view[ep], result.data);
            return result.data.fields;
        });
    }

    /**
     * Returns a reference to the requested metadata and kicks off a refresh.
     * When this function returns, it might not have a value but Angular's data
     * binding should update it once data has arrived. If you want to be sure,
     * call require (it returns a Promise).
     * @param {string} entityPluralName plural name of the entity to be fetched
     * @param {string} type type of requested metadata. Can be view, create or
     * list.
     * @return reference to a metadata object
     */
    function get(entityPluralName, type) {
        type = typeMap[type];
        var ep = ivdUtils.cleanEndpointStr(entityPluralName);

        // cache[type][ep] = cache[type][ep] || {};

        // Make sure data gets pulled in
        require(entityPluralName, type);

        return cache[type][ep];
    }

    /**
     * Forces a refresh of the requested metadata.
     * @param {string} entityPluralName plural name of the entity to be fetched
     * @param {string} type type of requested metadata. Can be view, create or
     * list.
     * @return {Promise} promise that will resolve to the requested
     * metadata.
     */
    function refresh(entityPluralName, type) {
        type = typeMap[type];
        var ep = ivdUtils.cleanEndpointStr(entityPluralName);

        cache[type][ep] = cache[type][ep] || {};

        if (!progress[type][ep]) {
            progress[type][ep] = $http.get('metadata/' + ep + '/' + type).then(function (result) {
                _.assign(cache[type][ep], result.data);
                progress[type][ep] = null;
                return cache[type][ep];
            });
        }

        return progress[type][ep];
    }

    /**
     * Returns a promise that will resolve to the requested metadata. This could
     * be retrieved from the cache or fetched from the server, if necessary.
     * @param {string} entityPluralName plural name of the entity to be fetched
     * @param {string} type type of requested metadata. Can be view, create or
     * list.
     * @return {Promise} promise that will resolve to the requested
     * metadata.
     */
    function require(entityPluralName, type) {
        type = typeMap[type];
        var ep = ivdUtils.cleanEndpointStr(entityPluralName);

        if (cache[type][ep] && !_.isEmpty(cache[type][ep])) {
            return $q.resolve(cache[type][ep]);
        } else {
            return refresh(entityPluralName, type);
        }
    }
}


(module || {}).exports = metadataService;
