// Based on Angular-ui's DatePicker and TimePicker
// Found at: https://angular-ui.github.io/bootstrap/

ivdDateTimePicker.$inject = ['$window'];

function ivdDateTimePicker($window) {
    // Directive Definition Object
    var directive = {
        //link = link, //Directives that modify the DOM typically use the link option. E.g. updating a DIV to display current time...
        restrict: 'E',
        scope: {
            ngModel: '=', //The object to bind the selected date to
            showTime: '=', //Whether to show the timepicker or not
            showDate: '=', //Whether to show the datepicker or not
            fieldName: '=', // 'name' attribute for input field to render
            ngRequired: '=',
            format: '@' //The formatting of the date
        },
        templateUrl: 'ivd/ui/date-time-picker/date-time-picker.html',
        controllerAs: 'dateTimePicker',
        controller: DateTimePickerController,
        bindToController: true //Bind directive's scope to controller
    };

    return directive;
}

DateTimePickerController.$inject = ['$scope', '$document', '$element'];

function DateTimePickerController($scope, $document, $element) {
    var vm = this;

    //Public API
    vm.clear = clear;
    vm.disabled = disabled;
    vm.toggleOpen = toggleOpen;
    vm.open = open;
    vm.close = close;

    //init
    activate();

    function activate() {

        vm.time = vm.ngModel;

        vm.minDate = vm.minDate || new Date(1900, 1, 1);
        vm.maxDate = vm.maxDate || new Date(2100, 12, 31);
        
        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        vm.format = vm.format || // TODO get default format from config
                    (vm.showDate && vm.showTime ? 'dd/MM/yyyy HH:mm' : false) ||
                    (vm.showDate ? 'dd/MM/yyyy' : false) ||
                    (vm.showTime ? 'HH:mm' : 'dd/MM/yyyy HH:mm');

        vm.isOpen = false;

        vm.hstep = 1;
        vm.mstep = 15;

        vm.options = {
            hstep: [1, 2, 3],
            mstep: [1, 5, 10, 15, 25, 30]
        };

        vm.ismeridian = true;

        vm.timeOptions = {
            readonlyInput: true,
            showMeridian: false
        };

    }

    //Implementation of public functionality
    
    function clear() {
        vm.ngModel = null;
    };

    // Disable weekend selection
    function disabled(date, mode) {
        return false;
    };

    //Open the datePicker
    function toggleOpen($event) {
        vm.isOpen = !vm.isOpen;
    };

    function open($event) {
        vm.isOpen = true;;
    };

    function close($event)
    {
        vm.isOpen = false;
    }

    //Close datepicker upon date selection id date-only
    if(!vm.showTime){
        $scope.$watch(function watchDate(scope) {
            return (vm.ngModel);
        }, function (newValue, oldValue) {
            vm.close();
        });
    }

    $scope.$watch(function () { return vm.time; },
        function () {
            if (vm.ngModel != vm.time) {
                vm.ngModel = vm.time;
            }
        });

    $scope.$watch(function () { return vm.ngModel; },
        function () {
            if (vm.ngModel && !moment.isDate(vm.ngModel)) {
                if (vm.time && moment.isDate(vm.time)) {
                    if (vm.time.getFullYear() != vm.ngModel.substr(0, 4) || vm.time.getMonth() + 1 != vm.ngModel.substr(5, 2) || vm.time.getDate() != vm.ngModel.substr(8, 2)) {
                        vm.time = vm.ngModel;
                    }
                } else if (vm.time && !moment.isDate(vm.time)) {
                    if (vm.time.substr(0,10) != vm.ngModel.substr(0, 10)) {
                        vm.time = vm.ngModel;
                    }
                } else {
                    vm.time = vm.ngModel;
                }
            }
        });

    // Watch on datetimepicker open to close when clicked outsitde
    $scope.$watch(function () { return vm.isOpen; }, function (value) {
        if (value) {
            $document.bind('click', documentClickBind);
        } else {
            $document.unbind('click', documentClickBind);
        }
    });

    function documentClickBind() {
        var dpContainsTarget = $element[0].contains(event.target);        
        if (vm.isOpen && !dpContainsTarget) {
            var partOfDatePicker = false;
            var el = event.target;
            if (el) {
                do {
                    if (el.nodeName == 'BUTTON') {
                        partOfDatePicker = true;
                        break;
                    }
                    el = el.parentElement;
                } while (el);
            }
            if (!partOfDatePicker) {
                $scope.$apply(function () {
                    vm.isOpen = false;
                });
            }
        }
    }
}
(module || {}).exports = ivdDateTimePicker;