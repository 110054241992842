(function (global) {

    'use strict';
    global.ivd = global.ivd || {};

    var ivd = global.ivd;

})(this);

(module || {}).exports = this.ivd;
