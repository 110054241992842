/**
 * @file authentication-interceptor-service.js
 * @copyright (c) 2016 4D vision
 * @author Tom De Smedt
 * @license Proprietary
 */

authenticationInterceptorService.$inject = ['$injector', '$q', 'authenticationTokenPersistenceService', 'appConfiguration', 'log4d'];

/**
 * An Angular service that intercepts each HTTP request and reponse send out and received by the client.
 * 
 * This service is added to the interceptors of `$httpProvider`. 
 * This way this service's `request` method kicks in before each HTTP request is made from client code to server.
 * Likewise, this service's `response` method is called each time an HTTP response is received which corresponds 
 * to an HTTP request originated in Angular client-side code.
 * The `responseError` method is only called when such a response has an HTTP status code in the range 4xx or 5xx.
 * @namespace
 */
function authenticationInterceptorService($injector, $q, authenticationTokenPersistenceService, appConfiguration, log4d) {

    var svc = this;
    var logger = log4d.logger('authentication-interceptor');

    this.request = request;
    this.response = response;
    this.responseError = responseError;
    this.tokenGetter = appConfiguration.refreshTokenGetter || function () { return null; };
    this.refreshedTokenPromise = null;

    init();

    return svc;

    function init() {
        authenticationTokenPersistenceService.loadUser();
    }

    /**
     * The request interceptor that adds an Authorization header to the HTTP request,
     * based on the token stored in local/session storage.
     * @param {Object} config - $http config Object
     * @returns {Object} config - $http config Object
     */
    function request(config) {
        config.headers = config.headers || {};

        if (config.skipAuthorization === true) {
            return config;
        }

        if (!!authenticationTokenPersistenceService.token) {
            // Get a token refresh if the current one is expired
            if (authenticationTokenPersistenceService.refreshToken
                && (!authenticationTokenPersistenceService.token || isTokenExpired(authenticationTokenPersistenceService.token))) {
                logger.debug('Access Token expired or non-existent. Fetching a new one using the refresh token...');

                // If we're not already requesting a refreshed token, start a new request
                if (!svc.refreshedTokenPromise) {
                    logger.debug("Opened new refresh token request");
                    svc.refreshedTokenPromise = $q.when($injector.invoke(svc.tokenGetter, this, {
                        config: config
                    }));
                }

                return svc.refreshedTokenPromise
                    .then(function (token) {
                        svc.refreshedTokenPromise = null;

                        // Add token to the request
                        authenticationTokenPersistenceService.token = token;
                        config.headers.Authorization = "Bearer " + authenticationTokenPersistenceService.token;

                        return config;
                    });
            } else {
                config.headers.Authorization = "Bearer " + authenticationTokenPersistenceService.token;
                return config;
            }
        }
        return config;
    }

    /**
     * The response interceptor that adds the Bearer token from the HTTP response header Token
     * to the local/session storage.
     * @param {Object} config - $http config Object
     * @returns {Object} config - $http config Object
     */
    function response(config) {
        config.data = config.data || {};

        if (config.data.Token) {
            var jwtToken = config.data.Token;
            var temp = config.data.Token;
            var claims = deconstructJWT(temp);
            authenticationTokenPersistenceService.login(jwtToken, claims);
        }

        if (config.data.RefreshToken) {
            var token = config.data.RefreshToken;
            authenticationTokenPersistenceService.setRefreshToken(token);
        }

        return config;
    }

    function deconstructJWT(token) {
        var segments = token.split(".");
        if (!(segments instanceof Array) || segments.length !== 3) {
            throw new Error("Invalid JWT");
        }
        var claims = segments[1];
        return JSON.parse(decodeURIComponent(escape(window.atob(claims))));
    }

    function isTokenExpired(token) {
        var decoded = deconstructJWT(token);
        var expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decoded.exp);

        return expirationDate.getTime() <= (new Date()).getTime();
    }
  
    /**
     * The response interceptor that handles HTTP status code 401 = Unauthorized.
     * Then redirects to logout.
     * @param {Object} rejection - $http response
     */
    function responseError(rejection) {
        if (rejection.status === 401) {
            authenticationTokenPersistenceService.logout();
        }
        return $q.reject(rejection);
    }

    }


    (module || {}).exports = authenticationInterceptorService;
