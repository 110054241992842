/**
 * @file storage-service.js
 * @copyright (c) 2016 4D vision
 * @author Gijs Barrezeele
 * @license Proprietary
 */

storage.$inject = ['$exceptionHandler', '$window', 'log4d'];

/**
 * An Angular service that works as a wrapper around localStorage.
 * All data is kept in memory and when the event beforeunload is fired,
 * the data is persisted in localStorage.
 * @namespace
 */
function storage($exceptionHandler, $window, log4d) {

    var logger = log4d.logger('storage');
    
    // localStorage key that will be used to persist data for this demo.
    var storageKey = "TimeBox-dev";
    // Try to load the initial payload from localStorage.
    var items = loadData();
    // I maintain a collection of callbacks that want to hook into the
    // unload event of the in-memory cache. This will give the calling
    // context a chance to update their relevant storage items before
    // the data is persisted to localStorage.
    var persistHooks = [];
    // I determine if the cache should be persisted to localStorage when the
    // application is unloaded.
    var persistEnabled = true;
    // During the application lifetime, we're going to be using in-memory
    // data access (since localStorage I/O is relatively expensive and
    // requires data to be serialized - two things we don't want during the
    // user to "feel"). However, when the application unloads, we want to try
    // to persist the in-memory cache to the localStorage.
    $window.addEventListener( "beforeunload", persistData );   

    // Return the public API.
    var service = {
        clear: clear,
        disablePersist: disablePersist,
        enablePersist: enablePersist,
        extractItem: extractItem,
        getItem: getItem,
        getItems: getItems,
        onBeforePersist: onBeforePersist,
        removeItem: removeItem,
        setItem: setItem
    };
    return service;

    // ---
    // PUBLIC METHODS.
    // ---
    
    /** Clears the current item cache. 
     */
    function clear() {
        items = {};
    }
    /** Disables the persisting of the cache to localStorage on unload. */
    function disablePersist() {
        persistEnabled = false;
    }
    /** enable the persisting of the cache to localStorage on unload. */
    function enablePersist() {
        persistEnabled = true;
    }
    /** 
     * Removes the given key from the cache and return the value that was
     * cached at that key; returns null if the key didn't exist. 
     * @param {string} key - key
     * @returns {string|Object|Number|whatever} the value
     */
    function extractItem( key ) {
        var value = getItem( key );
        removeItem( key );
        return( value );
    }
    /** Returns the item at the given key; returns null if not available.
     * @param {string} key - key
     * @returns {string|Object|Number|whatever} the value
     */
    function getItem( key ) {
        key = normalizeKey( key );
        // NOTE: We are using .copy() so that the internal cache can't be
        // mutated through direct object references.
        return( ( key in items ) ? angular.copy( items[ key ] ) : null );
    }
    
    /** Gets all items as an Object, i.e. key-value pairs
     * @returns {Object}
     */
    function getItems() {
        return items;
    }
    /** Adds the given operator to persist hooks that will be invoked prior
     * to unload-based persistence.
     * @param {Function} operator - the function to be called before the actual persistence call to localStorage
     */
    function onBeforePersist( operator ) {
        persistHooks.push( operator );
    }
    
    /** Removes the given key from the cache.
     * @param {string} key - key
     */
    function removeItem( key ) {
        key = normalizeKey( key );
        delete( items[ key ] );
    }
    /** Stores the item at the given key.
     * @param {string} key - key
     * @param {whatever} value - value
     */
    function setItem( key, value ) {
        key = normalizeKey( key );
        // NOTE: We are using .copy() so that the internal cache can't be
        // mutated through direct object references.
        items[ key ] = angular.copy( value );
    }

    // ---
    // PRIVATE METHODS.
    // ---

    // I attempt to load the cache from the localStorage interface. Once the
    // data is loaded, it is deleted from localStorage.
    function loadData() {
        // There's a chance that the localStorage isn't available, even in
        // modern browsers (looking at you, Safari, running in Private mode).
        try {
            if ( storageKey in $window.localStorage ) {
                var data = $window.localStorage.getItem( storageKey );
                $window.localStorage.removeItem( storageKey );
                // NOTE: Using .extend() here as a safe-guard to ensure that
                // the value we return is actually a hash, even if the data
                // is corrupted.
                logger.log('Data loaded: ', data);
                return (angular.extend({}, angular.fromJson(data)));                
            }
        } catch ( localStorageError ) {
            $exceptionHandler( localStorageError );
        }
        // If we made it this far, something went wrong.
        return( {} );
    }
    // I normalize the given cache key so that we never collide with any
    // native object keys when looking up items.
    function normalizeKey( key ) {
        return( "ivdStorage_" + key );
    }
    // I attempt to persist the cache to the localStorage.
    function persistData() {
        // Before we persist the data, invoke all of the before-persist hook
        // operators so that consuming services have one last chance to
        // synchronize their local data with the storage data.
        for ( var i = 0, length = persistHooks.length ; i < length ; i++ ) {
            try {
                persistHooks[ i ]();
            } catch ( persistHookError ) {
                $exceptionHandler( persistHookError );
            }
        }
        // If persistence is disabled, skip the localStorage access.
        if ( ! persistEnabled ) {
            return;
        }
        // There's a chance that localStorage isn't available, even in modern
        // browsers. And, even if it does exist, we may be attempting to store
        // more data that we can based on per-domain quotas.
        try {
            $window.localStorage.setItem(storageKey, angular.toJson(items));
            logger.log('Data persisted');
        } catch ( localStorageError ) {
            $exceptionHandler( localStorageError );
        }
    }

}

(module || {}).exports = storage;
