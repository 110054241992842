var ivdMultiLingualField = {
    templateUrl: 'ivd/ui/fields/multi-lingual-field/multi-lingual-field.html',
    controller: MultiLingualFieldController,
    bindings: {
        data: '=',
        metadata: '<',
        edit: '<',
        context: '<'
    },
    controllerAs: 'vm'
};

MultiLingualFieldController.$inject = ['$scope', '$q', '$parse', 'log4d', 'restQueryBuilder',
                            'metadataService', 'dataService', 'restService', 'appLocalization'];

function MultiLingualFieldController($scope, $q, $parse, log4d, restQueryBuilder,
                          metadataService, dataService, restService, appLocalization) {
    var vm = this;
    var logger = log4d.logger('multiLingualField')

    vm.applicationLanguages = [];
    vm.translations = [];
    vm.linesData = "";
    vm.toggleViewAllLines = toggleViewAllLines;
    vm.applicationDefaultLanguage = ''

    activate();

    $scope.$watch(function () {
        return vm.translations
    }, function (newValue, oldValue) {
        _.map(vm.translations, function (translation) {
            if (!!vm.data && !!vm.data.Translations && !_.some(vm.data.Translations, { 'LanguageISO': translation.LanguageISO })) {
                if (!!translation.Text) {
                    vm.data.Translations = vm.data.Translations || [];
                    vm.data.Translations.push(translation);
                }
            } 
        });
    }, true)

    $scope.$watch(function () {
        return vm.data;
    }, formatForView, true);

    function activate() {
        vm.applicationDefaultLanguage = appLocalization.defaultLocale();

        loadData().then(function () {
            vm.data = vm.data || {};
            vm.data.Translations = vm.data.Translations || [];
            _.map(vm.applicationLanguages, function (language) {
                var item = {
                    Id: 0,
                    LanguageISO: language.IsoCode,
                    MultiLingualTextId: 0,
                    Text: ''
                };

                if (!!vm.data && !!vm.data.Translations) {
                    item = _.find(vm.data.Translations, function (dataTrans) {
                        return dataTrans.LanguageISO == language.IsoCode;
                    }) || item;
                }

                vm.translations.push(item);
            })
        }).catch(function (e) {
            logger.error('preloading multilingualtext data failed')
        });
    }

    function loadData() {
        return $q.all([
                restService.query('languages', {}).then(function (data) {
                    vm.applicationLanguages = data;
                })
        ])
    }

    function toggleViewAllLines(translation) {
        if (vm.metadata.multiline && vm.metadata.multiline.rows > 1) {
            translation.viewAllLines = !translation.viewAllLines;
            translation.displayData = translation.viewAllLines ? translation.linesData : translation.Text;
        }
    }

    function formatForView() {
        if (!vm.data) return;

        var newline = '\n';
        _.map(vm.data.Translations, function (translation) {
            if (vm.metadata.multiline && vm.metadata.multiline.rows > 1) {
                translation.viewAllLines = false;
                var lines = translation.Text.split(newline);
                if (lines.length > vm.metadata.multiline.rows) {
                    translation.linesData = lines.splice(0, vm.metadata.multiline.rows).join(newline) + '...';
                } else {
                    translation.linesData = translation.Text;
                }
                translation.displayData = translation.linesData;
            }
            else {
                translation.displayData = translation.Text;
            }
        });
    }

}

(module || {}).exports = ivdMultiLingualField;
