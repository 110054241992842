ivdMasterPage.$inject = [];


function ivdMasterPage () {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/master-page/master-page.html',
        controller: MasterController,
        controllerAs: "master"
    }
    return directive;
}

MasterController.$inject = ['$rootScope', 'log4d'];

function MasterController($rootScope, log4d) {
    var vm = this;
    var logger = log4d.logger('MasterController')
    
    vm.showSearchBox= false,
    vm.showSortBox= false,
    vm.showAddButton = false;
    vm.showExportButton = false;
    vm.menuVisible = false;
    
    vm.toggleSearchBox = function(){};
    vm.toggleSortBox = function(){};
    vm.openCreate = function () { };
    vm.exportData = function (filetype) { console.log(filetype) };
    

    $rootScope.$on("$routeChangeStart", function (event, next, current) {
        if (next && next.$$route) {
            logger.log('MasterController handling $routeChangeStart', next);
            vm.showSearchBox= false,
            vm.showSortBox= false,
            vm.showAddButton = false;
            vm.showExportButton = false;
            vm.menuVisible = (next.$$route.originalPath!='/');
        }
    });
}

(module || {}).exports = ivdMasterPage;