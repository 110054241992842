var customField = {
    template: '<ng-include src="vm.metadata.customField.templateUrl"/>',
    controller: CustomFieldController,
    bindings: {
        data: '=',
        metadata: '<',
        edit: '<',
        context: '<'
    },
    controllerAs: 'vm'
};

CustomFieldController.$inject = ['$document'];

function CustomFieldController($document) {
    var svc ;

    var vm = this;

    activate();

    function activate() {
        svc = angular.element($document.find('body')).injector().get(vm.metadata.customField.service);
        vm.properties = svc.properties;
        svc.setContext(vm.context);
    }
}

(module || {}).exports = customField;
