/**
 * @file circular-array.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

/**
 * Circular buffer or ring buffer, i.e. a LIFO buffer (Last In = First Out).
 * CircularArray inherits from Array.
 * ```javascript
 * var ringBuffer = new ivd.CircularArray(10, console);
 * ringBuffer.push({"name": "Dennis", "age": 40});
 * ```
 * @class CircularArray
 */
var CircularArray = (function () {

    /** Constructor
     * @param {Number} maxLength - the size of the buffer
     * If ommited, defaults to 100.
     * @param {Logger} logger - the logger, i.e. an Object
     * that supports the functions debug, log, warn, info and error.
     * Optional parameter.
     */
    function CircularArray(maxLength, logger) {
        this.maxLength = maxLength || 100;
        if (!logger) {
            logger = {
                debug: function() {},
                log: function() {},
                warn: function() {},
                info: function() {},
                error: function() {}
            };
        }
        this.logger = logger;

    }

    CircularArray.prototype = new CircularArray();
    CircularArray.prototype = Object.create(Array.prototype);

    /** Override of Array's push function.
     * When the length of the array exceeds the maximum length 
     * (=size of the buffer), one element is shifted.
     * @param {whatever} element - the element to add 
     */
    CircularArray.prototype.push = function (element) {
        this.logger.debug('CircularArray: adding', element);

        Array.prototype.push.call(this, element);
        while (this.length > this.maxLength) {
            this.shift();
            this.logger.debug('CircularArray: removing oldest element => keeping', this);
        }
    };

    return CircularArray;
})();


(module || {}).exports = CircularArray;