/**
 * @file rest-query.js
 * @copyright (c) 2016 4D vision
 * @author Tom Ghekiere
 * @license Proprietary
 */

/**
 * A RestQuery object contains the parameters to call a REST API endpoint
 * and has functions to set them.
 * 
 * The parameters are :
 * - $expands
 * - $sort
 * - $skip
 * - $top 
 * @class
 */
function RestQuery(logger) {

    if (!logger) {
        logger = {
            debug: function () { },
            log: function () { },
            warn: function () { },
            info: function () { },
            error: function () { }
        };
    }

    // hidden variables:
    var qBuilder = {};

    var expands = [],
        filters = [],
        orderby = [],
        //select = '', // NOT SUPPORTED IN OUR REST API
        skip = 0,
        top = 0,
        aggregates = [];
    //count = false; // NOT SUPPORTED IN OUR REST API

    var staticFilters = [];

    // public API:
    var service = {
        getQueryParameters: getQueryParameters,
        addExpand: addExpand,
        addFilter: addFilter,
        addOrderby: addOrderby,
        getSkip: getSkip,
        setSkip: setSkip,
        getTop: getTop,
        setTop: setTop,
        clearExpand: clearExpand,
        clearFilter: clearFilter,
        clearOrderby: clearOrderby,
        clearSkip: clearSkip,
        clearTop: clearTop,
        setStaticFilters: setStaticFilters,
        //,includeCount: includeCount
        addAggregate: addAggregate,
        clearAggregate: clearAggregate
    };
    return service;

    // implementation details

    /**
     * Gets the query parameters that can be passed to the REST API's 
     * GET endpoint.
     * @returns {Object} object with parameters:
     * - $expand
     * - $sort
     * - $skip
     * - $sort
     * - $with (for aggregations)
     * - all other properties are considered filter values
     */
    function getQueryParameters() {
        var q = {};
        if (!!expands && expands.length > 0) {
            q.$expand = expands.join(',');
        }
        if (!!filters && filters.length > 0) { //q.$filter = filter; // FIXME !
            for (var i = 0; i < filters.length; i++) {
                // TODO: handle case of value being an array (--> value.join(','))
                q[filters[i].field] = filters[i].value;
            }
        }
        if (!!orderby && orderby.length > 0) {
            q.$sort = orderby.join(',');
        }
        //if (select != '') q.$select = select; // NOT SUPPORTED IN OUR REST API
        if (skip) {
            q.$skip = skip;
        }
        if (top) {
            q.$top = top;
        }
        if (aggregates && aggregates.length > 0) {
            q.$with = aggregates.join(',');
        }
        //if (count) q.$count = count; // NOT SUPPORTED IN OUR REST API
        logger.debug('RestQuery returned the following query', q);
        return q;
    }

    /**
     * Add the field to the list of expands
     * @param {string} x - field to add to the list of expands
     */
    function addExpand(x) {
        if (expands.indexOf(x) < 0) {
            expands.push(x);
        }
    }

    /**
     * Add the field and the associated value to the list of filters
     * @param {string} xField - fieldname
     * @param {string|Number} xValue - value to filter on
     */
    function addFilter(xField, xValue) {
        logger.debug('restQuery.addFilter', xField, xValue);
        if (xValue !== undefined) {
            filters.push({ field: xField, value: xValue });
        }
    }

    /**
     * Add a field to the list of field to perform sorting on
     * @param {string} fieldname - name of the field to sort on
     * @param {Boolean} descending - True if descending, False or omitted if ascending (default)
     */
    function addOrderby(fieldname, descending) {
        logger.debug('restQuery.addOrderby', fieldname, descending);
        orderby.push((descending ? '-' : '') + fieldname);
    }

    // TODO seems not to be used, remove?
    /**
     * Gets the filter values
     * @returns ??
     */
    function getFilter() {
        // Prepare sorting data
        var keys = [];
        var directions = [];

        _.each(orderby, function (val) {
            if (val.indexOf('-') === 0) {
                directions.push('desc');
            } else {
                directions.push('asc');
            }

            // Strip leading dash
            keys.push(val.replace(/^-/, ''));
        });

        return function (data) {
            return _.chain(data)
                .filter(function (entity) {
                    return _.isEmpty(filters) || _.any(filters, function (filter) {
                        return entity[filter.field].search(filter.value) > -1;
                    });
                })
                .sortBy(keys, directions)
                .slice(skip || 0, top ? (skip + top + 1) : undefined);
        };
    }

    /**
     * Gets the current skip value
     * @returns {Number} number of records to skip 
     */
    function getSkip() {
        return skip;
    }

    /**
     * Sets skip value
     * @param {Number} x - number of records to skip 
     */
    function setSkip(x) {
        skip = x;
    }

    /**
     * Gets the current top value (i.e. maximum number of records in result set)
     * @returns {Number} max number of records 
     */
    function getTop() {
        return top;
    }

    /**
     * Sets the top value (i.e. maximum number of records in result set)
     * @param {Number} max number of records 
     */
    function setTop(x) {
        top = x;
    }

    /**
     * Clears the expand list
     */
    function clearExpand() {
        expands = [];
    }

    /**
     * ?
     */
    function setStaticFilters(newStaticFilters) {
        staticFilters = newStaticFilters;
    }

    /**
     * Clears the filters list
     */
    function clearFilter() {
        filters = [];
        filters = _.cloneDeep(staticFilters);
    }

    /**
     * Clears the orderby list
     */
    function clearOrderby() {
        orderby = [];
    }

    /**
     * Clears the skip value
     */
    function clearSkip() {
        skip = 0;
    }

    /**
     * Clears the top value
     */
    function clearTop() {
        top = undefined;
    }

    /**
     * Adds an aggregate value
     * @param {Object} aggr - the aggregate definition, possible values:
     * - sum(fieldname)
     * - avg(fieldname)
     * - min(fieldname)
     * - max(fieldname)
     */
    function addAggregate(aggr) {
        if (aggregates.indexOf(aggr) < 0) {
            aggregates.push(aggr);
        }
    }

    /**
     * Clears the aggregates list
     */
    function clearAggregate() {
        aggregates = [];
    }
}

(module || {}).exports = RestQuery;
