ivdMenu.$inject = ['$window'];

function ivdMenu($window) {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/menu/menu.html',
        scope: {
            isVisible: '=',
            showSearchBox: '<',
            showSortBox: '<',
            showAddButton: '<',
            showExportButton: '<',
            exportData: '&',
            toggleSearchBox: '&',
            toggleSortBox: '&',
            openCreate: '&'
        },
        controllerAs: 'menu',
        controller: MenuController,
        bindToController: true
    };
    return directive;
}

MenuController.$inject = ['menuService', '$location', '$scope', 'authenticationService'];

function MenuController(menuService, $location, $scope, authenticationService) {
    var vm = this;

    // reading directive scope
    vm.isVisible = vm.isVisible || true;

    //Public API
    vm.hasChildren = hasChildren;
    vm.doSearch = doSearch;
    vm.filter = filter;
    vm.clearFilter = clearFilter;
    vm.navigateTo = navigateTo;
    vm.logout = logout;
    vm.login = login;

    vm.doToggleSearchBox = doToggleSearchBox;
    vm.doToggleSortBox = doToggleSortBox;
    vm.doOpenCreate = doOpenCreate;
    vm.doExportData = doExportData;
    vm.authenticated = authenticationService.authenticated;
    vm.getCurrentUser = getCurrentUser;
    vm.user = authenticationService.getUser();
    vm.goToUserPage = goToUserPage;

    vm.searchBoxOpen = false;
    vm.sortBoxOpen = false;
    vm.collapsed = true; // if width drops below 768px, indicates whether the mini-menu is collapsed

    //init
    activate();

    $scope.$watch(function () {
        return vm.authenticated();
    }, function (newValue, oldValue) {
        activate();
    });

    function activate() {
        vm.search = vm.search || defaultSearch;

        menuService.getMenu().then(function (menu) {
            vm.ngModel = vm.items = menu;
        });
    }

    function filter() {
        vm.ngModel = _.filter(vm.items, function (item) {
            return item.name.toLowerCase().indexOf(vm.searchTerm) >= 0;
        });
    }

    function clearFilter() {
        vm.searchTerm = '';
        filter();
    }

    function doToggleSearchBox() {
        vm.toggleSearchBox();
    }

    function doToggleSortBox() {
        vm.toggleSortBox();
    }

    function doOpenCreate() {
        vm.openCreate();
    }

    function doExportData(filetype) {
        vm.exportData({filetype: filetype});
    }

    function defaultSearch(query) {
        alert(query);
    }

    //Checks if a hierarchy object has children
    function hasChildren(element) {
        return element.children && element.children.length > 0;
    }

    function doSearch(query) {
        query = query || '';
        vm.search(query);
    }

    function logout() {
        authenticationService.logout();
        login();
    }

    function login() {
        $location.path('/authentication');
    }

    function getCurrentUser() {
        var currentuser = authenticationService.getUser();
        return currentuser ? currentuser.userName || '' : '';
    }

    function navigateTo(link) {
        //this route change makes sure that the opened menu collapses again
        $location.path(link);
    }

    function goToUserPage() {
        $location.path('/authentication');
    }
}

(module || {}).exports = ivdMenu;
