'use strict';

var mod = angular.module('ivd.localization',
    [
        'ivd.translate',
        'tmh.dynamicLocale'         // angular-dynamic-locale
    ]);

mod.config(['tmhDynamicLocaleProvider', function (tmhDynamicLocaleProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('data/angular-locale_{{locale}}.js'); //lib/angular-i18n/angular-locale_{{locale}}.js
}]);

mod.provider('appLocalization', require('./app-localization.js'));

mod.config(['appLocalizationProvider', function (appLocalizationProvider) {
    appLocalizationProvider.defaultLocale('en');
}]);


(module || {}).exports = 'ivd.localization';