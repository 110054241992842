/**
 * @file query-grid.js
 * @copyright (c) 2017 4D vision
 * @author Dat Ngo
 * @license Proprietary
 */

var objectMapper = require('../../../lib/object-mapper/');

ivdGrid.$inject = ['$window'];

/**
 * An Angular directive to show data in a grid-table in a everscroll container.
 * Normally used in combination with the query-grid directive
 * @namespace
 */

function ivdGrid($window) {
    var directive = {
        restrict: 'E',
        scope: {
            options: '<',
            data: '=',
            endpoint: '<',
            viewMetadata: '<',
            waitUntil: '<',  //TODO DAT: check if can delete not used here anywhere
            refreshData: '&',
            maxItems: '=',
            disableEverscroll: '<?',
            itemsPerRequest: '<',
            customView: '<',
            maxHeight: '@',
            showViewButton: '<?',
            showEditButton: '<?',
            showDeleteButton: '<?',
            context: '<?', //TODO TOM.G/DAT: Discussed with Tom G. Not sure where this is used and nessecary.Check where this is used and if can be deleted.
            onMetadataProcessed: '&',
            totalsStyle: '<?',
            number: '<?',
            ctnweight: '<?'
        },
        templateUrl: 'ivd/ui/grid/grid.html',
        controllerAs: 'grid',
        controller: GridController,
        bindToController: true // because the scope is isolated
    };

    return directive;
}

GridController.$inject = ['restQueryBuilder', 'Stopwatch', '$scope', '$anchorScroll', '$timeout',
    'jwtTokenLocalReadService', '$parse', 'log4d', '$document', '$window', 'ivdInsights', 'restService'];
/**
 * Usage: <ivd-grid></ivd-grid>
 * 
 * @param {object}        options               (1-way binding)    - Options object from the BaseEntityController object of your page 
 * @param {array}         data                  (2-way binding)    - The actual data that the grid has to show.  
 * @param {string}        endpoint              (1-way binding)    - The name of the endpoint of the grid, is used as a unique identifier for the grid itself.
 * @param {string}        viewMetadata          (1-way binding)    - The meta-data to display items.
 * @param {function}      refresh-data          (1-way binding)    - The function the grid should call to request new data from te server
 * @param {string}        context               (2-way binding)    - TODO TOM.G/DAT: Discussed with Tom G. Not sure where this is used and nessecary.Check where this is used and if can be deleted.
 * @param {function}      onMetadataProcessed   (1-way binding)    - Function to perform when the metadata is done processing.

 * @param {int}           max-items             [optional] (1-way binding)   (default: 200)      - the max records to show in the grid, should be any number between 0 and 1000.  Anything else will be set to 200 items.
 * @param {boolean}       disable-everscroll    [optional] (1-way binding)   (default: false)    - whether or not to disable everscroll functionality
 * @param {int}           items-per-request     [optional] (1-way binding)   (default: 10)       - number of records to fetch per refresh-data.  If disable-everscroll is true, max-items will be fetched.
 * @param {string}        custom-view           [optional] (2-way binding)   (default: '')       - The user's unencrypted password
 * @param {int}           max-height            [optional] (2-way binding)   (default: '')       - The max height in pixels you wish the grid to be, if not set, it will occupy the whole page with a padding of 22px
 * @param {boolean}       show-view-button      [optional] (1-way binding)   (default: true)     - Show or hide the button to open the detailview in view mode  
 * @param {boolean}       show-edit-button      [optional] (1-way binding)   (default: false)    - Show or hide the button to open the detailview in edit mode 
 * @param {boolean}       show-Delete-Button    [optional] (1-way binding)   (default: false)    - Show or hide the button to open the detailview in remove mode
 *
 * @return the ivdGrid directive
 */

function GridController(restQueryBuilder, Stopwatch, $scope, $anchorScroll, $timeout,
    jwtTokenLocalReadService, $parse, log4d, $document, $window, ivdInsights, restService) {
    var vm = this;

    //privates
    vm.infiniteScrollRequest = false;

    vm.rowCss = [];
    vm.rows = [];

    vm.headers = [];    // visible headers, parsed from 'metadata'

    vm.itemsPerRequest = vm.itemsPerRequest * 1 || 10; //set min number of items per request
    vm.maxItems = (!!vm.maxItems && vm.maxItems * 1 > 1000) ? 200 : vm.maxItems * 1 || 200; //set max number of items to show
    vm.disableEverscroll = (vm.disableEverscroll !== false);
    var everscrollInitiallyDisabled = vm.disableEverscroll;
    vm.maxHeight = vm.maxHeight || '';
    vm.showViewButton = (vm.showViewButton !== false); // default to 'true' if not set
    // vm.showEditButton --> default to 'false' if not set
    // vm.showDeleteButton --> default to 'false' if not set
    vm.context = vm.context || {};
    //vm.totalsStyle = vm.totalsStyle || false;

    // model functions :
    vm.showNext = showNext;
    vm.scrollFrom = 0;
    vm.scrollTo = vm.itemsPerRequest;
    var isContainerScroll = false;
    var isHeaderScroll = false;

    vm.infiniteScrollLoading = false;
    vm.escapedEndpoint = vm.endpoint.replace(/[\/\?\=]/g, '_');
    vm.container = 'div.grid-infiniteScroll-container.' + vm.escapedEndpoint;

    var animating = false;

    vm.refreshGrid = refreshGrid;

    vm.$parse = $parse;

    // init :
    var logger = log4d.logger('grid');


    activate();

    function fieldProperty(field, property, defaultValue) {
        var matches = vm.options.fields.filter(function (h) {
            return h.label === field;
        });
        return matches[0] ? matches[0][property] : defaultValue;
    }

    /**
     * Fetching initial data on loading the control
     */
    function activate() {
        if (!everscrollInitiallyDisabled) {
            restQueryBuilder.getSingleton(vm.endpoint).setTop(vm.itemsPerRequest);
        }
        else {
            restQueryBuilder.getSingleton(vm.endpoint).setTop(vm.maxItems || 200);
        }
    }

    /**
    * Processes the metadata for this grid:
    * -sets the userlanguage
    * -orders the field by it's order
    * -sets the visibility of the field
    * -sets the headers of the grid
    * -add the expands to the restQueryBuilder
    * -when processing is done, calls the onMetadataProcessed function passed as parameter
    */
    function processMetadata() {
        logger.info('GRID :: processMetadata for: ', vm.endpoint);
        vm.headers = [];

        var userlang = jwtTokenLocalReadService.getUserLanguage();

        // vm.options = vm.options || {};

        restQueryBuilder.getSingleton(vm.endpoint).clearExpand();

        var fields = vm.options.fields || []; // metadata passed into directive

        _.sortBy(fields, 'order');
        _.each(fields, function (field) {
            field.forUserLanguage = true;
            if (field.languageRestrictions && field.languageRestrictions.length > 0
                && field.languageRestrictions.indexOf(userlang) === -1) {
                field.forUserLanguage = false;
            }
            setInvisibleIfInContext(field);
            if (field.visible && field.forUserLanguage) {
                vm.headers.push(field);
                var propertytoExpand;

                if (field.data.indexOf('.') > 0) {
                    propertytoExpand = field.data.substring(0, field.data.lastIndexOf('.'));
                    logger.info('expanding', propertytoExpand);
                    restQueryBuilder.getSingleton(vm.endpoint).addExpand(propertytoExpand);
                }
                if (field.currencyProvider) {
                    propertytoExpand = field.currencyProvider.substring(0, field.currencyProvider.lastIndexOf('.'));
                    restQueryBuilder.getSingleton(vm.endpoint).addExpand(propertytoExpand);
                }
                if (field.type === 'file') {
                    restQueryBuilder.getSingleton(vm.endpoint).addExpand(field.data);
                }
                if (!field.align) {
                    field.align = (field.type === 'integer' || field.type === 'decimal') ? 'right' : 'left';
                }
            }
        });

        _.each(vm.options.aggregates || [], function (aggregate) {
            restQueryBuilder.getSingleton(vm.endpoint).addAggregate(aggregate);
        });

        vm.onMetadataProcessed();
    }

    function setInvisibleIfInContext(field) {
        if ($parse(field.data)(vm.context)) {
            field.visible = false;
        }
    }

    /**
     * Convert the data to rows on the grid.
     * - checks if the current grid already has the a record with the same Id
     */
    function addRowsFromData() {
        var stopwatch = new Stopwatch();
        stopwatch.start();
        vm.rowCss = vm.rowCss || [];
        var rowCssMap = getRowCssMap();
        vm.data = vm.data || [];
        vm.rows = vm.rows || [];
        vm.ids = vm.ids || [];
        _.each(vm.data, function (record) {
            if (vm.ids.indexOf(record.Id || record.ID) < 0) {
                setCurrency(record);
                vm.rowCss.push(appendRowCss(rowCssMap, record));
                vm.rows.push(record);
                vm.ids.push(record.Id || record.ID);
            }
        });
        $timeout(function () {
            // truukje om de uitvoering van deze functie uit te stellen tot na DOM rendering complete
            logger.debug(moment(stopwatch.stop()).format('mm:ss.SSS'));
        }).then(function () {
            if (!everscrollInitiallyDisabled) {
                vm.disableEverscroll = !(vm.data && vm.data.length > 0) || (vm.data.length < vm.itemsPerRequest);
            }
            setVisibleItems();
            setHeaders();
            $timeout(function () {
                setGridHeight();
            });
        });

        /**
         * Gets the row classes from the options to set the css class of the row on the grid
         * @return result object with an properties static(array of css classes) and dynamic(array of css classes)
         */
        function getRowCssMap() {
            var result = {
                static: [],
                dynamic: []
            };
            if (!!vm.options && !!vm.options.rowClasses) {
                _.each(vm.options.rowClasses.staticClasses, function (staticClass) {
                    result.static.push(staticClass);
                });
                _.each(vm.options.rowClasses.dynamicClasses, function (dynamicClass) {
                    result.dynamic.push(dynamicClass);
                });
            }
            return result;
        }

        function setCurrency(record) {
            _(vm.options.fields).filter(function (field) {
                return !!field.currencyProvider;
            }).each(function (field) {
                field.currency = $parse(field.currencyProvider)(record);
            });
        }

        function appendRowCss(cssMap, record) {
            var result = _.map(cssMap.dynamic, function (path) {
                return _.kebabCase($parse(path)(record));
            });
            result = _.union(result, cssMap.static); // distinct
            return result.join(' ');
        }
    }

    // if data changes: re-activate !
    $scope.$watch(function () {
        return vm.data;
    }, function (newValue, oldValue) {
        if (!vm.infiniteScrollRequest) {
            logger.debug('vm.data changed from querybuilder: ', vm.data);
            logger.debug('endpoint: ', vm.endpoint);
            vm.disableEverscroll = true;
            vm.rows = undefined;
            vm.ids = [];
            vm.rowCss = [];
        }
        if (newValue) {
            vm.footers = restService.getResponseHeader(vm.endpoint).statistics;
            addRowsFromData();
        }
    });

    $scope.$watchCollection(function () {
        return vm.rows;
    }, function () {
        if (vm.rows && vm.rows.length !== 0) {
            ivdInsights.trackMetric(vm.endpoint + ' GridSize', vm.rows.length);
        }
    });

    // if metadata changes: re-activate !
    $scope.$watchCollection(function () {
        return vm.options;
    }, function (newValue, oldValue) {
        if (newValue) {
            if (newValue.fields && newValue.fields.length > 0) {
                logger.debug('Detected non-empty change in grid metadata', newValue, oldValue);
                processMetadata();
            }
        }
    });

    $scope.$watch(function () {
        var container = $window.document.querySelector(vm.container);
        return container ? container.scrollTop : undefined;
    }, function (newValue, oldValue) {
        logger.debug('position changed: ', oldValue, newValue);
        if (newValue < oldValue) {
            $timeout(function () {
                setGridHeight();
            });
        }
    });

    function showNext() {
        logger.debug('infinite scroller request');
        angular.element(vm.container)
            .scrollTop(angular.element(vm.container).scrollTop() - 20);

        if (vm.infiniteScrollLoading && vm.infiniteScrollRequest) {
            return;
        }

        logger.debug('infinityscroll request new rows, current rows:' + vm.rows.length);
        vm.infiniteScrollLoading = true;
        vm.infiniteScrollRequest = true;

        if (vm.rows.length < vm.scrollTo + vm.itemsPerRequest) {
            restQueryBuilder.getSingleton(vm.endpoint).setSkip(vm.rows.length);
            logger.debug('GRID: refreshing from scroll');
            vm.refreshData()
                .then(function (data) {
                    if (!!data && data.length > 0) {
                        logger.debug('data returned');
                        logger.debug('data', data);
                    }
                    else {
                        vm.infiniteScrollLoading = false;
                        logger.debug('no data returned');
                    }
                });
        }
        else {
            setVisibleItems();
        }
    }
    /**
     * This function sets the visible items in the grid.  
     * If there are more rows to be shown than set in the max-items, then the visible rows will be resetted to the previous fetched batch of rows
     * of items to be fetched per request, added with the added rows.
     * If the everscroll functionality is disabled, all fetched rows are visible.
     */
    function setVisibleItems() {
        if (!vm.rows) {
            return;
        }

        if (!everscrollInitiallyDisabled) {
            if (vm.scrollFrom < 0) {
                vm.scrollFrom = 0;
                vm.scrollTo = vm.itemsPerRequest;
                vm.infiniteScrollLoading = false;
                return;
            }

            vm.scrollTo += vm.itemsPerRequest;

            if (vm.scrollTo > vm.rows.length) {
                vm.scrollTo = vm.rows.length;
            }

            if (vm.scrollTo - vm.scrollFrom > vm.maxItems) {
                vm.scrollFrom = vm.scrollTo - vm.itemsPerRequest;
            }
        }
        else {
            vm.scrollFrom = 0;
            vm.scrollTo = vm.maxItems;
        }
        logger.log('scrollFrom:' + vm.scrollFrom + ', scrollTo:' + vm.scrollTo);
    }

    /**
     * Scrolls to the last scroll position before new data/rows are added.  
     * Otherwise the scrollposition is at the top every time new rows are added.
     * If no items are added, it will scroll to just above the infinite scroll padding so it won't keep fetching data.
     */
    function scrollToLastItem() {
        var container = $window.document.querySelector(vm.container);
        var timeout = 300;

        var bottomScrollPosition = container.scrollHeight - container.offsetHeight;
        var footers = container.querySelectorAll('tfoot');
        var footersHeight = 0;
        for (var i = 0; i < footers.length; i++) {
            footersHeight += footers[i].offsetHeight;
        }

        if (container.scrollTop >= bottomScrollPosition - footersHeight) {
            $timeout(function () {
                if (!animating && container.scrollTop !== bottomScrollPosition
                    && !vm.infiniteScrollLoading && !vm.infiniteScrollRequest) {
                    logger.debug('animation start');
                    animating = true;
                    angular.element(container).animate({
                        scrollTop: container.scrollHeight - container.offsetHeight - footersHeight
                    }, 800, 'swing', function () {
                        logger.debug('animation done');
                        animating = false;
                    });
                }
            }, timeout);
        }
    }

    /**
     * Calculates the gridheight accourdingly to the window height.
     * The gridheight is only calculated when the the height of the container is not higher then
     * the max-height.  If higher the max-height is used.
     * If the everscroll functionality is initially disabled, the grid shows it's full height.
     */
    function setGridHeight() {
        logger.info('grid::setGridHeight');

        var GRID_OFFSET_BOTTOM = ($window.document.querySelector('#footer-container').clientHeight || 0) + 22;

        var container = $window.document.querySelector(vm.container);
        var windowHeight = 'innerHeight' in window
            ? $window.innerHeight
            : $window.document.documentElement.offsetHeight;
        var gridTop = container.offsetTop;
        var gridHeight = container.offsetHeight;
        var tableHeight = container.querySelector('table').offsetHeight;

        var maxHeight = vm.maxHeight || (windowHeight - gridTop - GRID_OFFSET_BOTTOM);
        logger.debug('Max height: ', maxHeight);

        if (!everscrollInitiallyDisabled) {
            if (gridHeight !== maxHeight || gridHeight > tableHeight) {
                var currentScrollPosition = container.scrollTop;
                container.style.height = 'auto';

                gridHeight = container.offsetHeight;

                if (!vm.disableEverscroll) {
                    var footers = container.querySelectorAll('tfoot');
                    var footersHeight = 0;
                    for (var i = 0; i < footers.length; i++) {
                        footersHeight += footers[i].offsetHeight;
                    }
                }
                else {
                    footersHeight = 0;
                }

                if ((gridHeight - footersHeight) > (maxHeight)) {
                    logger.debug('gridheight should be: ' + (maxHeight));
                    container.style.height = maxHeight + 'px';
                }
                else {
                    logger.debug('footersHeight: ' + footersHeight);
                    logger.debug('gridheight should be: ' + (gridHeight - footersHeight));
                    container.style.height = (gridHeight - footersHeight) + 'px';
                }

                if (gridHeight !== container.offsetHeight) {
                    container.scrollTop = currentScrollPosition;
                }
            }
        }
        else {
            container.style.height = (tableHeight + 50) + 'px';
            // logger.debug('everscroll disabled height is: ' + angular.element(vm.container).height());
        }
    }

    /**
     * This function appends to each header column of the header table and the actual data table 
     * a unique identifier.  This way each column width of the header table can be set equally to the data table.
     * TODO Dat: Do we need an unique identifier, can't we just loop through each column of the data table and
     *           set the width of the header table equally?  Faster in performance? Less html to render?
     */
    function setHeaders() {
        $timeout(function () {
            _(vm.headers)
                .map(function (header) {
                    return header.label;
                })
                .concat(['details', 'edit', 'delete', 'blank'])
                .each(function (type) {
                    var hiddenHeaderId = '#' + vm.escapedEndpoint + '_hiddenHeader_' + type;
                    var actualHeaderId = '#' + vm.escapedEndpoint + '_actualHeader_' + type;
                    var footerId = '#' + vm.escapedEndpoint + '_footer_' + type;
                    var hiddenHeaderWidth = angular.element(hiddenHeaderId).width();
                    angular.element(actualHeaderId).width(hiddenHeaderWidth);
                    angular.element(footerId).width(hiddenHeaderWidth);
                });

            vm.infiniteScrollLoading = false;
            vm.infiniteScrollRequest = false;

        }, 100);
    }

    /**
     * Functionality to perform when grid calls to refresh/append new data.  Occurs when infiniteScroll controll gets pulled.
     * Currently only vm.refreshData() is called.
     */
    function refreshGrid() {
        vm.refreshData();
    }

    /**
     * Cleanup grid resize-eventhandlers because window events does not get clean up when navigating to other page.
     */
    $scope.$on('$destroy', function () {
        //
        angular.element(window).off('resize');
        angular.element('#header-container.' + vm.escapedEndpoint).off('scroll');
        angular.element('div.grid-infiniteScroll-container.' + vm.escapedEndpoint).off('scroll');
        angular.element('#header-container.' + vm.escapedEndpoint).off('scroll');
    });

    /**
     * Function to sync the horizontal scroll position of the data-table with the header-table when scrolling horizontally.
     */
    function syncContainerWithHeader() {
        isHeaderScroll = true;
        if (!isContainerScroll) {
            angular.element('div.grid-infiniteScroll-container.' + vm.escapedEndpoint)
                .scrollLeft(angular.element(this).scrollLeft());
        }
        isHeaderScroll = false;
    }

    /**
     * Function to sync the horizontal scroll position of the header-table with the data-table when scrolling horizontally.
     */
    function syncHeaderWithContainer() {
        isContainerScroll = true;
        if (!isHeaderScroll) {
            angular.element('#header-container.' + vm.escapedEndpoint)
                .scrollLeft(angular.element(this).scrollLeft());
        }
        isContainerScroll = false;

        if (!animating && !vm.infiniteScrollLoading && !vm.infiniteScrollRequest && !vm.disableEverscroll) {
            scrollToLastItem();
        }
    }

    /**
     * Appends functionality to syncronizing scroll position header table and data table when horizontally scrolling on either table.
     * Appends functionality to set the grid height when window is resizing.  This way we always scroll in the grid and not the page itself.
     */
    $timeout(function myfunction() {
        angular.element('#header-container.' + vm.escapedEndpoint).scroll(syncContainerWithHeader);

        angular.element('div.grid-infiniteScroll-container.' + vm.escapedEndpoint).scroll(syncHeaderWithContainer);

        angular.element(window).resize(function () {
            setHeaders();
            $timeout(function () {
                setGridHeight();
            });
        });
    });
}


(module || {}).exports = ivdGrid;
