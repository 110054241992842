/**
 * @file authentication-token-persistence-service.js
 * @copyright (c) 2016 4D vision
 * @author Tom De Smedt
 * @license Proprietary
 */


authenticationTokenPersistenceService.$inject = ['$sessionStorage', '$translate', 'log4d',
    'appLocalization'];
/**
 * An Angular service responsible for storing and retrieving the Bearen token.
 * The token is kept in the browser's session storage.
 * @namespace
 */
function authenticationTokenPersistenceService($sessionStorage, $translate, log4d,
    appLocalization) {
    
    var logger = log4d.logger('authentication');

    var svc = {};

    svc.authenticated = false;
    svc.user = {};
    svc.token = undefined;
    svc.refreshToken = undefined;
    svc.login = login;
    svc.logout = logout;
    svc.loadUser = loadUser;
    svc.setRefreshToken = setRefreshToken;

    return svc;

    /**
     * Stores the token at successful login.
     * 
     * Also sets the current locale/language based on the token's language property/claim.
     * @param {string} token - The Bearer token
     * @param {Object} userObj - The decrypted content/body of the Bearer token: all of the user's claims 
     */    
    function login(token, userObj) {
        $sessionStorage.authorizationData = { token: token, user: userObj };
        logger.debug('Logged in as ' + userObj.unique_name);

        svc.authenticated = true;
        angular.copy(userObj, svc.user);

        svc.token = token;

        // $translate.use(userObj.languageIsoCode || $translate.preferredLanguage() ); 
        appLocalization.currentLocale(userObj.languageIsoCode);
    }

    /**
     * Removes the saved token from the storage.
     * 
     * Also resets the locale/language.
     */
    function logout() {
        delete $sessionStorage.authorizationData;
        logger.debug('Logged out current user.');

        svc.authenticated = false;
        angular.copy(null, svc.user);
        svc.token = null;
        svc.refreshToken = null;

        // $translate.use($translate.preferredLanguage() );
        appLocalization.resetLocale();
    }

    /**
     * Loads the service's token `authenticated`, `token`and `refreshToken`
     * properties
     */
    function loadUser() {
        if ($sessionStorage.authorizationData) {
            svc.authenticated = true;
            angular.copy($sessionStorage.authorizationData.user, svc.user);
            svc.token = $sessionStorage.authorizationData.token;
            svc.refreshToken = $sessionStorage.authorizationData.refreshToken;
        }
    }

    /**
     * Sets the refresh token.
     * @param {string} token - The refresh token
     */    
    function setRefreshToken(token) {
        svc.refreshToken = token;

        if (!$sessionStorage.authorizationData) {
            $sessionStorage.authorizationData = {};
        }

        $sessionStorage.authorizationData.refreshToken = token;
    }
}

(module || {}).exports = authenticationTokenPersistenceService;
