AuthenticationController.$inject = ['$location', 'authenticationService'];

function AuthenticationController($location, authenticationService) {
    var vm = this;

    vm.loggingIn = false;
    vm.allowRegistration = false;

    vm.username = "";
    vm.password = "";


    vm.performAuthentication = performAuthentication;
    vm.logout = logout;

    init();

    function init() {
        if (authenticationService.authenticated()) {
            //$location.path('/home'); // temp uncomment
        }

        authenticationService.allowRegistration().then(function (result) {
            vm.allowRegistration = result;
        })
    }

    function performAuthentication() {
        vm.loggingIn = true;
        authenticationService.login(vm.username, vm.password)
            .then(function () {
                vm.loggingIn = false;
                //$location.path("/home"); // temp uncomment
            })
            .catch(function () {
                vm.loggingIn = false;
            });
    }

    function logout() {
        authenticationService.logout();
    }


}

(module || {}).exports = AuthenticationController;

