'use strict';

var mod = angular.module('ivd.authentication');

mod.config(['$routeProvider', authenticationRoute]);

function authenticationRoute($routeProvider) {
    $routeProvider.when('/passwordRecovery', {
        templateUrl: 'ivd/authentication/password-recovery/passwordRecovery.html',
        controller: 'passwordRecoveryController',
        controllerAs: 'pwr'
    });
}


mod.controller('passwordRecoveryController', require('./password-recovery-controller.js'));
mod.controller('passwordRecoveryModalController', require('./password-recovery-modal-controller.js'));

// Make password recovery controller available everywhere for inheritance
var ivd = require('../../ivd-namespace.js');
ivd.passwordRecoveryController = require('./password-recovery-controller.js');