ivdHtmlBoxField.$inject = [];

function ivdHtmlBoxField() {
    /*
        Usage:
        <ivd-html-box-field></ivd-html-box-field>
    */
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/fields/html-box-field/html-box-field.html',
        controller: HtmlBoxFieldController,
        controllerAs: 'htmlBoxField',
        scope: {
            data: '=',
            metadata: '=',
            edit: '='
        },
        bindToController: true
    };

    return directive;
}

HtmlBoxFieldController.$inject = ['objectAccess'];

function HtmlBoxFieldController(objectAccess) {
    var vm = this;

    //Bindable members
    vm.get = get;
 
    //Init
    activate();

    function activate() {
        vm.edit = !!vm.edit;
        }

    //Implementation
    function get(obj, str) {
        if (obj == undefined) {
            return '';
        }
        return objectAccess.getValueByString(obj, str);
    }
}

module.exports = ivdHtmlBoxField;