ivdQueryGrid.$inject = ['$parse'];

function ivdQueryGrid($parse) {
    var directive = {
        restrict: 'E',
        templateUrl: 'ivd/ui/query-grid/query-grid.html',
        controller: QueryGridController,
        controllerAs: "queryGrid",
        link: link
    };

    function link(scope, el, attrs) {

        passToScope('fixed-filter');
        passToScope('context');

        function passToScope(attr_name){
          if (el.attr(attr_name) !== undefined && el.attr(attr_name) !== '') {
              if (scope.vm) {
                  scope.vm[attrs.$normalize(attr_name)] = $parse(el.attr(attr_name))(scope);
              }
          }
        }
    }

    return directive;
}

function QueryGridController() {
    var vm = this;

    vm.filterActive = false;

    vm.showSearchBox = false;
    vm.showSortBox = false;

    vm.toggleSearchBox = toggleSearchBox;
    vm.toggleSortBox = toggleSortBox;
    // vm.openCreate = openCreate;

    function toggleSearchBox() {
        vm.showSearchBox = !vm.showSearchBox;
        vm.searchBoxIsOpen = vm.showSearchBox;
    }

    function toggleSortBox() {
        vm.showSortBox = !vm.showSortBox;
        vm.sortBoxIsOpen = vm.showSortBox;
    }

    // function openCreate() {
    //
    // }
}

(module || {}).exports = ivdQueryGrid;
