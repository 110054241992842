var ivdReadField = {
    templateUrl: 'ivd/ui/read-field/read-field.html',
    controller: ReadFieldController,
    bindings: {
        data: '<',
        metadata: '<',
        context: '<'
    },
    controllerAs: 'vm'
};

ReadFieldController.$inject = ['$scope', '$window', '$http', 'appConfiguration', 'dateFilter', 'authenticationTokenPersistenceService', 'appLocalization'];

function ReadFieldController($scope, $window, $http, appConfiguration, dateFilter, authenticationTokenPersistenceService, appLocalization) {

    var vm = this;

    vm.get = _.at;
    vm.retrieveFile = retrieveFile;
    vm.getMultiLingualTextForUser = getMultiLingualTextForUser

    vm.multiLingualTextForUser = {};
    vm.multiLingualTextForUser.text = '';
    vm.multiLingualTextForUser.prefix = '';

    var defaultLanguage = '';
    var userLanguage = '';


    activate();

    function activate() {
        defaultLanguage = appLocalization.defaultLocale();

        if (!!authenticationTokenPersistenceService.user) {
            userLanguage = authenticationTokenPersistenceService.user.languageIsoCode || defaultLanguage;
        }
        // From date field
        $scope.$watch(function () {
            // HACK: Will always be 'true' when type is not a date, so Angular
            // doesn't mark this as dirty for non-date fields
            if (!vm.metadata) {
                vm.metadata = {};
            }
            return (!vm.metadata.type === 'date') || vm.data;
        }, function (newValue, oldValue) {
            vm.showData = dateFilter((newValue && !_.endsWith(newValue, 'Z')) ? (newValue + 'Z') : (newValue),
                'dd/MM/yyyy', 'UTC');
            // vm.showData = (newValue && !_.endsWith(newValue, 'Z')) ? (newValue + 'Z') : (newValue);
        });

        getMultiLingualTextForUser();
    }


    // From file field
    function retrieveFile(endpoint, guid) {
        $http.get(appConfiguration.apiUrl + endpoint + '/' + guid + '?download=1', '_blank')
            .then(
                // Success
                function (res) {
                    if (vm.data) {
                        $window.open(appConfiguration.apiUrl + endpoint + '/' + guid + '?download=1', '_blank');
                    }
                },
                // Error
                function (err) {
                    // 404: File not found
                    if (err.status == 404) {
                        alert(err.data);
                    } // Other errors
                    else {

                    }
                })

    }

    function getMultiLingualTextForUser() {
        if (!!vm.data && !!vm.data.Translations) {
            var translation = _.find(vm.data.Translations, function (t) {
                return t.LanguageISO == userLanguage;
            });

            if (!translation || !translation.Text) {
                translation = _.find(vm.data.Translations, function (t) {
                    return t.LanguageISO == defaultLanguage;
                });
                if (!!translation) vm.multiLingualTextForUser.prefix = "[" + translation.LanguageISO + "]";
            }

            if (!!translation) vm.multiLingualTextForUser.text = translation.Text;
        }
    }
}

(module || {}).exports = ivdReadField;
