passwordRecoveryModalController.$inject = ['$uibModalInstance', '$location', '$scope', 'authenticationService'];

function passwordRecoveryModalController($uibModalInstance, $location, $scope, authenticationService) {
    var vm = this;

    vm.submit = submit;
    vm.submitOnEnter = submitOnEnter;

    vm.email = "";
    vm.alert = "";
    vm.alertType = "danger";

    init();


    function init() {
        vm.submitting = false;
        vm.submitted = false;
    }

    function submit() {
        vm.submitting = true;
        vm.alert = "";

        if (vm.email !== "") { // VALIDATION!!!
            authenticationService.resetPassword(vm.email)
            .then(function () {
                vm.alert = "PASSWORD_RECOVERY_MAIL_SUCCES";
                vm.alertType = "success";
                vm.submitting = false;
                vm.submitted = true;
            }, function (err) {
                vm.alert = "PASSWORD_RECOVERY_MAIL_ERROR";
                vm.alertType = "danger";
                vm.submitting = false;
            });
        }
    }

    function submitOnEnter(e) {
        if (e.keyCode === 13) {
            submit();
            e.stopPropagation();
            return false;
        }
    }

    // Prevent the modal from closing when api call in progress
    $scope.$on('modal.closing', function (event, data) {
        if (!!vm.submitting) {
            event.preventDefault();
        }
    });

}

(module || {}).exports = passwordRecoveryModalController;

