angular.module('ivd.ui')
.directive('ivdField', require('./4d-field/4d-field-directive.js'))
.directive('ivdTextField', require('./text-field/text-field-directive.js'))
.directive('ivdEmailField', require('./email-field/email-field-directive.js'))
.directive('ivdUrlField', require('./url-field/url-field-directive.js'))
.directive('ivdBooleanField', require('./boolean-field/boolean-field-directive.js'))
.directive('ivdDateField', require('./date-field/date-field-directive.js'))
.directive('ivdDateTimeField', require('./date-time-field/date-time-field-directive.js'))
.directive('ivdTimeField', require('./time-field/time-field-directive.js'))
.directive('ivdSelectBoxField', require('./select-box-field/select-box-field-directive.js'))
.directive('ivdPeriodField', require('./period-field/period-field-directive.js'))
.directive('ivdHtmlBoxField', require('./html-box-field/html-box-field-directive.js'))
.directive('ivdFileUploadBoxField', require('./file-upload-box-field/file-upload-box-field-directive.js'))
.directive('ivdCurrencyField', require('./currency-field/currency-field-directive.js'))
.directive('ivdIntegerField', require('./integer-field/integer-field-directive.js'))
.directive('ivdDecimalField', require('./decimal-field/decimal-field-directive.js'))
.component('ivdCustomField', require('./custom-field/custom-field-component.js'))
.component('ivdMultiLingualField', require('./multi-lingual-field/multi-lingual-field-component.js'));
