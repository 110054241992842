/**
 * @file data-service.js
 * @copyright (c) 2016 4D vision
 * @author Marcel Samyn
 * @license Proprietary
 */

dataService.$inject = ['$resource', '$q', 'appConfiguration', 'restService', 'log4d'];

/**
 * An Angular service which is a further abstraction
 * over restService. What's new here is that the dataService
 * returns a handle to the output variable, even if the restService's
 * promise has not returned yet.
 * @namespace
 */
function dataService($resource, $q, appConfiguration, restService, log4d) {

    var logger = log4d.logger('dataService');

    var data = {};

    var progress = {};

    var service = {
        refresh: refresh,
        get: getEndpointData,
        add: add,
        save: save,

        setDeleted: setDeleted,
        setSaved: setSaved
    };

    return service;

    ////////////

    /**
     * Refreshes the data from the given endpoint.
     * @param {string} name of the endpoint to refresh data from
     * @returns {Promise} promise for the resulting data
     */
    function refresh(endpoint) {
        data[endpoint] = data[endpoint] || [];

        if (!progress[endpoint]) {
            progress[endpoint] = $q.resolve(undefined);
        }

        progress[endpoint] = progress[endpoint].then(function () {
            return restService.list(endpoint).then(function (refreshedData) {
                extractExpands(refreshedData);
                angular.copy(refreshedData, data[endpoint]);

                return data[endpoint];
            });
        });

        return progress[endpoint];
    }

    /**
     * Returns the data for the given endpoint (by reference).
     * @param {string} endpoint to get data from
     * @returns {Object} data This might not be populated when this function
     * returns, but you can use it in controllers and Angular's data binding
     * will update your views when the data has arrived.
     */
    function getEndpointData(endpoint) {
        return data[endpoint] = data[endpoint] || [];
    }

    /**
      * Executes the given query and adds the resulting data to the data store.
      * (without duplicates).
      * @param {string} endpoint to get data from
      * @param {Object} query query parameters to pass on
      * @returns {Promise} a promise that will resolve to the new data
      * once all actions are completed.
      */
    function add(endpoint, query) {
        if (!progress[endpoint]) {
            progress[endpoint] = $q.resolve(undefined);
        }

        progress[endpoint] = progress[endpoint].then(function () {
            return resource(endpoint).query(query).$promise
                .then(function (newData) {
                    extractExpands(newData);
                    angular.copy(_.unionBy(data[endpoint], newData, 'Id'), data[endpoint]);
                    return newData;
                });
        });

        return progress[endpoint];
    }

    /**
     * Update an entity on the database.
     * @param {string} endpoint
     * @param {Object} entity
     * @returns {Promise} Promise with the updated entity
     */
    function save(endpoint, entity) {
        return restService.update(endpoint, entity)
            .then(function (savedData) {
                setSaved(endpoint, savedData);

                // This is ugly, but required to keep sort & search in check if
                // we don't do that client-side
                refresh(endpoint);

                return savedData;
            });
    }

    /**
     * Mark an entity as deleted. This will delete the entity from the local
     * data store.
     * @param {string} endpoint
     * @param {Object} entity Should have an 'Id' field
     */
    function setDeleted(endpoint, entity) {
        var id = entity.Id || entity.id;

        if (!id || !data[endpoint]) {
            return;
        }

        _.find(data[endpoint], function (thisEntity, key) {
            if (thisEntity.Id === id) {
                angular.copy(undefined, data[endpoint][key]);
                return true;
            } else {
                return false;
            }
        });
    }

    /**
     * Mark an entity as saved. This will add it to the data store or update its
     * value to the new data passed.
     * @param {string} endpoint
     * @param {Object} entity Newly created or updated entity
     * @returns {Object} Reference to the entity in the data store
     */
    function setSaved(endpoint, entity) {
        var id = entity.Id || entity.id;

        if (!id || !data[endpoint]) {
            return null;
        }

        var existingEntity = _.find(data[endpoint], ['Id', entity.Id]);
        if (existingEntity) {
            angular.copy(entity, existingEntity);
            return existingEntity;
        } else {
            data[endpoint].push(entity);
            return entity;
        }
    }

    /**
     * @private
     * Returns an Angular $resource object for a given endpoint
     */
    function resource(endpoint) {
        return $resource(appConfiguration.apiUrl + endpoint + '/:id', {id: '@Id'}, {
            update: {
                method: 'PUT'
            }
        });
    }

    /**
     * @private
     * Finds data from endpoints different from the main endpoint and stores
     * that data.
     */
    function extractExpands(inputData) {
        var refactored = _.reduce(inputData, function (result, entity) {
            _.each(entity, function (value, key) {
                if (angular.isObject(value)) {
                    result[key] = result[key] || [];
                    if (angular.isArray(value)) {
                        result[key] = _.unionBy(result[key], value, 'Id');
                    } else {
                        result[key].push(value);
                    }
                    delete entity[key];
                }
            });
            return result;
        }, {});
        _(refactored).each(function (value, key) {
            angular.copy(_.unionBy(data[endpointFor(key)], value, 'Id'), data[endpointFor(key)]);
        });
    }

    /**
     * @private
     */
    function endpointFor(name) {
        name = name.toLowerCase();
        if (name.endsWith('s')) {
            return name;
        } else {
            return name + 's';
        }
    }

}

(module || {}).exports = dataService;
