/**
 * @file authentication-service.js
 * @copyright (c) 2016 4D vision
 * @author Tom De Smedt
 * @license Proprietary
 */

authenticationService.$inject = ['$http', '$q', '$resource', 'appConfiguration',
    'authenticationTokenPersistenceService', 'generalMetadataService', '$uibModal', '$location', 'storage', 
    'log4d', 'queryStorage'];

/**
 * An Angular service to handle the process of user authenication in the browser/client.
 * @namespace
 */
function authenticationService($http, $q, $resource, appConfiguration,
    authenticationTokenPersistenceService, generalMetadataService, $uibModal, $location, storage,
    log4d, queryStorage) {

    var logger = log4d.logger('authenticationService');

    var service = {
        login: login,
        logout: logout,
        register: register,
        allowRegistration: allowRegistration,
        activate: activate,
        resetPassword: resetPassword,
        changePassword: changePassword,
        promptLogin: promptLogin,
        authenticated: authenticated,
        getUser: getUser,
        goToAuthenticationPage: goToAuthenticationPage,
        goToTermsOfServicePage: goToTermsOfServicePage
    };
    return service;

    /**
     * Perform the login process by calling the PasswordAuthentication
     * endpoint via POST.
     * @param {string} userName - The user's username/login/userID
     * @param {string} password - The user's unencrypted password
     * @return {Promise}
     */
    function login(userName, password) {
        var deferred = $q.defer();

        var loginInfo = { 'username': userName, 'password': password };

        $resource('api/PasswordAuthentication').save(loginInfo).$promise
            .then(function (result) {
                deferred.resolve(result);
            })
            .catch(function (msg) {
                authenticationTokenPersistenceService.logout();
                deferred.reject(msg);
            });

        return deferred.promise;
    }

    /**
     * Perform the logout process by purging the stored token
     * from the local/session storage.
     * Also erases the stored queries (i.e. last filter and sort expressions).
     * @return {??}
     */
    function logout() {
        authenticationTokenPersistenceService.logout();
        queryStorage.clear();
    }

    /**
     * Register a new user.
     * @param {Object} registrationInfo - The user's username/login/userID
     * @param {string} registrationInfo.username - The new user's username/login/userID
     * @param {string} registrationInfo.email - The new user's email address
     * @param {string} registrationInfo.password - The new user's unencrypted password
     * @param {string} registrationInfo.passwordconfirmation - The new user's unencrypted password as a confirmation
     * @return {Promise}
     */
    function register(registrationInfo) {
        var deferred = $q.defer();

        $resource('api/PasswordAuthentication/Register').save(registrationInfo).$promise
            .then(function (result) {
                deferred.resolve(result);
            })
            .catch(function (msg) {
                deferred.reject(msg);
            });

        return deferred.promise;
    }

    /**
     * Activate an account
     * @param {string} activationCode - Activation code
     * @return {Promise}
     */
    function activate(activationCode) {
        return $resource('api/PasswordAuthentication/Activate').save({
            ActivationCode: activationCode
        }).$promise;
    }
 
    /**
     * Request a password reset; an email with details and a link 
     * for resetting the password will be send.
     * @param {string} email - The user's email address
     * @return {Promise}
     */
    function resetPassword(email) {
        var deferred = $q.defer();
        var data = { email: email };
        $resource('api/PasswordAuthentication/Reset').save(data).$promise
            .then(function () {
                deferred.resolve();
            })
            .catch(function (msg) {
                deferred.reject(msg);
            });
        return deferred.promise;
    }
 
    /**
     * Change a user's password
     * @param {string} newPassword - The user's new password
     * @param {string} activationCode - Activation code
     * @return {Promise}
     */
    function changePassword(newPassword, activationCode) {
        var deferred = $q.defer();
        var data = { password: newPassword, activationcode: activationCode };
        $resource('api/PasswordAuthentication/Recover').save(data).$promise
            .then(function () {
                deferred.resolve();
            })
            .catch(function (msg) {
                deferred.reject(msg);
            });
        return deferred.promise;
    }

    /**
     * Opens a modul popup dialog prompting for a username and password.
     */
    function promptLogin() {
        return $uibModal.open({
            animation: true,
            templateUrl: 'app/authentication/authentication.html',
            controller: 'authenticationController',
            backdrop: 'static'
        });
    }

    /**
     * Returns True if the authenticated, False if not 
     * according to the authenticationTokenPersistenceService
     * @returns {Boolean} - Is user authenticated?
     */
    function authenticated() {
        return authenticationTokenPersistenceService.authenticated;
    }

    /**
     * Get the current user from the authenticationTokenPersistenceService
     * @return {??}
     */
    function getUser() {
        return authenticationTokenPersistenceService.user;
    }

    /**
     * Redirects the user to the authenication page/route.
     * In the meanwhile stores the requested path/route in order to be able
     * to redirect to there after a successful login.
     */
    function goToAuthenticationPage(pathToLoadWhenAuthenticated) {
        logger.log('pathToLoadWhenAuthenticated', pathToLoadWhenAuthenticated);
        setLoadPathLocation(pathToLoadWhenAuthenticated)
        $location.path('/authentication');
    }

    /**
     * Redirects the user to the terms of service page
     * After succesful confirmation, the requested route will be loaded
     */
    function goToTermsOfServicePage(pathToLoadWhenAuthenticated) {
        setLoadPathLocation(pathToLoadWhenAuthenticated)
        if(document.querySelector("#tosModal") === null) {
            return $uibModal.open({
                animation: true,
                size:'lg',
                templateUrl: 'app/termsofservice/termsofservice.html',
                controller: 'TermsOfServiceController',
                controllerAs: 'vm',
                keyboard: false,
                backdrop: 'static',
            });
        }
    }

    function setLoadPathLocation(pathToLoadWhenAuthenticated) {
        if (pathToLoadWhenAuthenticated && pathToLoadWhenAuthenticated !== '/authentication' && pathToLoadWhenAuthenticated !== '/termsofservice') {
            storage.setItem('pathToLoadWhenAuthenticated', pathToLoadWhenAuthenticated);
        }
    }

    /**
     * Returns true if registration is allowed in the configuration
     */
    function allowRegistration() {
        var deferred = $q.defer();
        generalMetadataService.get().then(
            function (data) {
                deferred.resolve(data.AllowRegistration === "True");
            }, function (error) {
                deferred.resolve(false);
            }
        );
        return deferred.promise;
    }
}

(module || {}).exports = authenticationService;
