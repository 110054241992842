// Based on the TextAngular WYSIWYG-text editor
// Found on: http://www.textAngular.com

//var mod = angular.module('ivd.ui.htmlBox', ['textAngular']);
ivdHtmlBox.$inject = ['$window'];

function ivdHtmlBox($window) {
    //  Usage: 
    //      <ivd-html-box></ivd-html-box>
    //  Creates:
    //
    var directive = {
        //link = link, //Directives that modify the DOM typically use the link option. E.g. updating a DIV to display current time...
        restrict: 'E',
        scope: {
            ngModel: '='
        },
        templateUrl: 'ivd/ui/html-box/html-box.html',
        controllerAs: 'htmlBox',
        controller: HtmlBoxController,
        bindToController: true // binds directive's scope to the controller's scope
    };
    return directive;
}

HtmlBoxController.$inject = []

function HtmlBoxController( ) {
    var vm = this;
    
    //public api
    vm.toggleReadOnly = toggleReadOnly;
    vm.getMode = getMode;
    vm.isReadOnly = isReadOnly;

    //init:
    activate();

    function activate() {
        vm.readOnly = false;
        vm.disabled = false;
    }

    // Implementation of public functionality

    function toggleReadOnly() {
        vm.readOnly = !vm.readOnly;
    }

    function getMode() {
        if (vm.readOnly) {
            return "View";
        } else {
            return "Edit";
        }
    }

    function isReadOnly() {
        return vm.readOnly;
    }
};

(module || {}).exports = ivdHtmlBox;
